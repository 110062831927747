import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { get } from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import Swal from 'sweetalert2';

import config from '../../config';
import Container from '../../layouts/Container';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';

import CustomTextArea from './components/textArea';
import CustomDatePicker from './components/datePicker';
import CustomSelect from './components/select';
import CustomToggle from './components/toggle';
import { isAllow } from '../../utils/permission';


import successIcon from '../../assets/img/icn_check.svg';
import warningIcon from '../../assets/img/icn_warning.svg';
import errorIcon from '../../assets/img/icn_error.svg';
import infoIcon from '../../assets/img/icn_info.svg';

import './style.css';

// Styled components
const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

// Define a styled button component
const OutlinedButton = styled.button`
  background-color: transparent; // Transparent background for outline style
  color: #007bff; // Blue text color
  box-shadow: 0 0 0 1px #007bff !important; // Darker blue outline effect on hover
  border-radius: 10px; // Rounded corners
  padding: 7px 32px; // Padding around text

  // event effect
  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    background-color: #007bff !important; // Blue background color on hover
    color: white !important; // White text color on hover
    box-shadow: 0 0 0 2px #007bff !important; // Darker blue outline effect on hover
  }
`;

export class BenefitEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      isEdit: !!this.props.match.params.id,
      viewMode: true,
      validationErrors: {
        name: false,
        code: false,
        startDate: false,
        endDate: false,
        benefitDetails: false,
      },
      duplicateErrors: {
        name: false,
        nameMessage: '',
        code: false,
        codeMessage: '',
      },
      isDirty: false,
    };
  }

  async componentDidMount() {
    this.props.benefit.clearQuery();
    this.props.benefit.clearState();

    if (this.state.isEdit) {
      try {
        await this.props.benefit.getBenefitById(this.state.id);
      } catch (error) {
        // this.noti.error('เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง');
        Swal.fire({
          imageUrl: errorIcon,
          toast: true,
          title: 'เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง',
          position: 'top',
          timer: 2000,
          customClass: 'swal2-error',
          showConfirmButton: false,
        });
      }
    }

    // บล็อกการเปลี่ยนหน้า
    this.unblock = this.props.history.block((nextLocation) => {
      if (this.state.isDirty) {
        this.setState({ nextLocation });
        this.showConfirmationDialog();
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    this.props.benefit.clearState();
    this.props.benefit.clearQuery();

    if (this.unblock) {
      this.unblock();
    }

    this.setState({ isDirty: false });
  }

  // Function to validate required fields
  validateFields = (specificField) => {
    const { benefit } = this.props.benefit.toJS();
    const errors = { ...this.state.validationErrors };

    if (specificField) {
      // Check specific field and add to errors if empty
      if (specificField === 'name') errors.name = !benefit.name;
      if (specificField === 'code') errors.code = !benefit.code;
      if (specificField === 'startDate') errors.startDate = !benefit.startDate;
      if (specificField === 'endDate') errors.endDate = !benefit.endDate;
      if (specificField === 'benefitDetails') errors.benefitDetails = !benefit.benefitDetails;
    }

    if (!specificField) {
      // Check each required field and add to errors if empty
      errors.name = !benefit.name;
      errors.code = !benefit.code;
      errors.startDate = !benefit.startDate;
      errors.endDate = !benefit.endDate;
      errors.benefitDetails = !benefit.benefitDetails;
    }

    this.setState({ validationErrors: errors });

    return Object.keys(errors).some((key) => errors[key]);
  };

  validateDuplicate = async (code, name) => {
    try {
      const validateCodeAndName = await this.props.benefit.validateBenefit(code, name);
      if (validateCodeAndName && validateCodeAndName.error && validateCodeAndName.error.isDuplicate) {
        if (validateCodeAndName.error.errorField === 'code') {
          this.setState({
            duplicateErrors: {
              ...this.state.duplicateErrors,
              code: true,
              codeMessage: 'รหัสสิทธิประโยชน์นี้ซ้ำ กรุณาระบุใหม่',
            },
          });
          return true;
        } else if (validateCodeAndName.error.errorField === 'name') {
          this.setState({
            duplicateErrors: {
              ...this.state.duplicateErrors,
              name: true,
              nameMessage: 'ชื่อสิทธิประโยชน์นี้ซ้ำ กรุณาระบุใหม่',
            },
          });
          return true;
        } else {
          // this.noti.error(validateCodeAndName.error.message);
          Swal.fire({
            imageUrl: errorIcon,
            toast: true,
            title: 'เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง',
            position: 'top',
            timer: 2000,
            customClass: 'swal2-error',
            showConfirmButton: false,
          });
          return true;
        }
      }
  
      return false;
    } catch (error) {
      Swal.fire({
        imageUrl: errorIcon,
        toast: true,
        title: 'เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง',
        position: 'top',
        timer: 2000,
        customClass: 'swal2-error',
        showConfirmButton: false,
      });
      return true;
    }
  };

  validateMaxLength = (value, maxLength) => {
    return value.length > maxLength
  }

  onChange = (key, value, isError) => {
    this.setState({ isDirty: true });

    this.props.benefit.onChangeBenefit(key, value);
    this.validateFields(key);

    if (key && isError) {
      this.setState({
        validationErrors: {
          ...this.state.validationErrors,
          [key]: isError,
        }
      })
    }

    if (key === 'code' && this.state.duplicateErrors.code) {
      this.setState({
        duplicateErrors: {
          ...this.state.duplicateErrors,
          code: false,
          codeMessage: '',
        },
      });
    }

    if (key === 'name' && this.state.duplicateErrors.name) {
      this.setState({
        duplicateErrors: {
          ...this.state.duplicateErrors,
          name: false,
          nameMessage: '',
        },
      });
    }
  };

  onClick = async (event) => {
    try {
      if (event === 'back') {
        this.props.history.push(`${config.publicUrl}/superadmin/benefit`);
      }

      if (event === 'cancel') {
        this.props.history.push(`${config.publicUrl}/superadmin/benefit`);
      }

      if (event === 'save') {
        const isError = this.validateFields();
        if (isError) return;

        const { benefit, tempBenefit } = this.props.benefit.toJS();
        const isCodeChanged = benefit.code !== tempBenefit.code;
        const isNameChanged = benefit.name !== tempBenefit.name;

        if (this.state.isEdit) {
          if (isCodeChanged) {
            const isDuplicate = await this.validateDuplicate(benefit.code, '');
            if (isDuplicate) return;
          }

          if (isNameChanged) {
            const isDuplicate = await this.validateDuplicate('', benefit.name);
            if (isDuplicate) return;
          }
        }

        // validate duplicate code and name on create mode
        if (!this.state.isEdit) {
          const isDuplicate = await this.validateDuplicate(benefit.code, benefit.name);
          if (isDuplicate) return;
        }

        Swal.fire({
          imageUrl: infoIcon,
          title: 'ยืนยันการบันทึกข้อมูล',
          text: 'คุณต้องการบันทึกข้อมูลหรือไม่?',
          reverseButtons: true,
          showCancelButton: true,

          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          customClass: {
            icon: 'swal-icon-save-benefit',
            container: 'swal-custom-container-benefit',
            popup: 'swal-custom-popup-benefit',
            title: 'swal-custom-title-benefit',
            text: 'swal-custom-text-benefit',
            actions: 'swal-buttons-right-benefit',
            confirmButton: 'swal-confirm-button-benefit',
            cancelButton: 'swal-cancel-button-benefit',
          },
        }).then(async (result) => {
          if (result.isConfirmed && this.state.isEdit) {
            try {
              this.setState({ isDirty: false });
              const user = this.props.auth.getUserInfo();
              const response = await this.props.benefit.updateBenefit(this.state.id, { ...benefit, user: get(user, 'email', '') });
              if (response && response.error) {
                // this.noti.error(response.error);
                Swal.fire({
                  imageUrl: errorIcon,
                  toast: true,
                  title: response.error.message || response.error,
                  position: 'top',
                  timer: 2000,
                  customClass: 'swal2-error',
                  showConfirmButton: false
                });
                return;
              }

              // await this.noti.success('แก้ไขข้อมูลสำเร็จแล้ว');
              Swal.fire({
                imageUrl: successIcon,
                toast: true,
                title: 'แก้ไขข้อมูลสำเร็จแล้ว',
                position: 'top',
                timer: 2000,
                customClass: 'swal2-success',
                showConfirmButton: false
              });
              this.props.history.push(`${config.publicUrl}/superadmin/benefit`);
            } catch (error) {
              // this.noti.error(error.message || error);
              Swal.fire({
                imageUrl: errorIcon,
                toast: true,
                title: 'เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง',
                position: 'top',
                timer: 2000,
                customClass: 'swal2-error',
                showConfirmButton: false
              });
              return;
            }
          }

          if (result.isConfirmed && !this.state.isEdit) {
            try {
              this.setState({ isDirty: false });
              const user = this.props.auth.getUserInfo();
              const response = await this.props.benefit.createBenefit({ ...benefit, user: get(user, 'email', '') });
              if (response && response.error) {
                // this.noti.error(response.error);
                Swal.fire({
                  imageUrl: errorIcon,
                  toast: true,
                  title: response.error.message || response.error,
                  position: 'top',
                  timer: 2000,
                  customClass: 'swal2-error',
                  showConfirmButton: false
                });
                return;
              }

              // await this.noti.success('บันทึกข้อมูลสำเร็จแล้ว');
              Swal.fire({
                imageUrl: successIcon,
                toast: true,  
                title: 'บันทึกข้อมูลสำเร็จแล้ว',
                position: 'top',
                timer: 2000,
                customClass: 'swal2-success',
                showConfirmButton: false
              });
              this.props.history.push(`${config.publicUrl}/superadmin/benefit`);
            } catch (error) {
              // this.noti.error(error.message || error);
              Swal.fire({
                imageUrl: errorIcon,
                toast: true,
                title: 'เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง',
                position: 'top',
                timer: 2000,
                customClass: 'swal2-error',
                showConfirmButton: false
              });
              return;
            }
          }
        });
      }

      if (event === 'delete') {
        Swal.fire({
          imageUrl: infoIcon,
          title: 'ยืนยันการลบข้อมูล',
          text: 'คุณต้องการลบข้อมูลหรือไม่?',
          reverseButtons: true,
          showCancelButton: true,

          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          customClass: {
            icon: 'swal-icon-delete-benefit',
            container: 'swal-custom-container-benefit',
            popup: 'swal-custom-popup-benefit',
            title: 'swal-custom-title-benefit',
            htmlContainer: 'swal-custom-text-benefit',
            actions: 'swal-buttons-right-benefit',
            confirmButton: 'swal-confirm-button-benefit',
            cancelButton: 'swal-cancel-button-benefit',
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const user = this.props.auth.getUserInfo();
              await this.props.benefit.deleteBenefit(this.state.id, get(user, 'email', ''));
              // this.noti.success('ลบข้อมูลสำเร็จ');
              Swal.fire({
                imageUrl: successIcon,
                toast: true,
                title: 'ลบข้อมูลสำเร็จ',
                position: 'top',
                timer: 2000,
                customClass: 'swal2-success',
                showConfirmButton: false
              });
              this.props.history.push(`${config.publicUrl}/superadmin/benefit`);
            } catch (error) {
              // this.noti.error('เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง');
              Swal.fire({
                imageUrl: errorIcon,
                toast: true,
                title: 'เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง',
                position: 'top',
                timer: 2000,
                customClass: 'swal2-error',
                showConfirmButton: false,
              });
            }
          }
        });
      }
    } catch (error) {
      // this.noti.error('เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง');
      Swal.fire({
        imageUrl: errorIcon,
        toast: true,
        title: 'เกิดปัญหาในการเข้าถึงเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง',
        position: 'top',
        timer: 2000,
        customClass: 'swal2-error',
        showConfirmButton: false,
      });
    }
  };

  onSearchCoupon = (text) => {
    this.props.benefit.getCouponList(text);
  };

  onClose = () => {
    this.props.benefit.clearCouponList();
  }

  renderButtonFooter = (disabledButton) => {
    const { isEdit, viewMode } = this.state;

    // create mode
    if (!isEdit) {
      return (
        <Box className="col-md-12" style={{ justifyContent: 'flex-end', gap: 0 }}>
          <OutlinedButton className="btn btn-primary" onClick={() => this.onClick('back')}>
            <span>กลับ</span>
          </OutlinedButton>

          <button
            className="btn btn-info"
            style={{ borderRadius: '10px', display: !isAllow('CREATE_BENEFITS') ? 'none' : 'block' }}
            onClick={() => this.onClick('save')}
            disabled={!isAllow('CREATE_BENEFITS') || disabledButton}
          >
            <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
            <span>บันทึก</span>
          </button>
        </Box>
      );
    }

    // edit page + view mode
    if (isEdit && viewMode) {
      return (
        <Box className="col-md-12">
          <Box className="col-md-2" style={{ justifyContent: 'flex-start', gap: 0, padding: 0 }}>
            <button
              className="btn btn-danger"
              style={{ borderRadius: '10px', display: isAllow('DELETE_BENEFITS') ? 'block' : 'none' }}
              disabled={!isAllow('DELETE_BENEFITS')}
              onClick={() => this.onClick('delete')}
            >
              <i className="fa fa-times btn-icon" aria-hidden="true" />
              <span>ลบ</span>
            </button>
          </Box>

          <Box className="col-md-10" style={{ justifyContent: 'flex-end', gap: 0, padding: 0 }}>
            <OutlinedButton className="btn btn-primary" onClick={() => this.onClick('back')}>
              <span>กลับ</span>
            </OutlinedButton>

            <button
              className="btn btn-primary"
              style={{ borderRadius: '10px', display: isAllow('EDIT_BENEFIT_DETAILS') ? 'block' : 'none' }}
              onClick={() => this.setState({ viewMode: false })}
              disabled={!isAllow('EDIT_BENEFIT_DETAILS')}
            >
              <i className="fa fa-pencil" aria-hidden="true" style={{ paddingRight: '5px' }} />
              <span>แก้ไข</span>
            </button>
          </Box>
        </Box>
      );
    }

    // edit page + edit mode
    if (isEdit && !viewMode) {
      return (
        <Box className="col-md-12">
          <Box className="col-md-2" style={{ justifyContent: 'flex-start', gap: 0, padding: 0 }}>
            <button
              className="btn btn-danger"
              style={{ borderRadius: '10px', display: isAllow('DELETE_BENEFITS') ? 'block' : 'none' }}
              disabled={!isAllow('DELETE_BENEFITS')}
              onClick={() => this.onClick('delete')}
            >
              <i className="fa fa-times btn-icon" aria-hidden="true" />
              <span>ลบ</span>
            </button>
          </Box>

          <Box className="col-md-10" style={{ justifyContent: 'flex-end', gap: 0, padding: 0 }}>
            <OutlinedButton className="btn btn-primary" onClick={() => this.onClick('cancel')}>
              <span>กลับ</span>
            </OutlinedButton>

            <button
              className="btn btn-info"
              style={{ borderRadius: '10px', display: isAllow('EDIT_BENEFIT_DETAILS') ? 'block' : 'none' }}
              onClick={() => this.onClick('save')}
              disabled={!isAllow('EDIT_BENEFIT_DETAILS') || disabledButton}
            >
              <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
              <span>บันทึก</span>
            </button>
          </Box>
        </Box>
      );
    }
  };

  allowTransition = () => {
    const { nextLocation } = this.state;
    // ปลดบล็อกการเปลี่ยนหน้า
    if (this.unblock) {
      this.unblock();
    }
    // ล้างสถานะ isDirty
    this.setState({ isDirty: false }, () => {
      // เปลี่ยนหน้าไปยังตำแหน่งที่ต้องการ
      this.props.history.push(nextLocation.pathname);
    });
  };
  
  blockTransition = () => {
    // ไม่ต้องทำอะไร เนื่องจากการเปลี่ยนหน้าถูกบล็อกอยู่แล้ว
    this.setState({ nextLocation: null });
  };

  showConfirmationDialog = () => {
    Swal.fire({
      imageUrl: warningIcon,
      title: 'ออกจากหน้านี้',
      html: `<div>หากคุณออกจากหน้านี้</div><div>การเปลี่ยนแปลงข้อมูลของคุณจะถูกยกเลิก</div>`,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'ออกและไม่บันทึก',
      cancelButtonText: 'อยู่ต่อ',
      customClass: {
        icon: 'swal-icon-back-benefit',
        container: 'swal-custom-container-benefit',
        popup: 'swal-custom-popup-benefit',
        title: 'swal-custom-title-benefit',
        htmlContainer: 'swal-custom-text-benefit',
        actions: 'swal-buttons-right-benefit',
        confirmButton: 'swal-confirm-button-benefit',
        cancelButton: 'swal-cancel-button-benefit',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // อนุญาตการเปลี่ยนหน้า
        this.allowTransition();
      } else {
        // ยกเลิกการเปลี่ยนหน้า
        this.blockTransition();
      }
    });
  };

  render() {
    const defaultBenefit = this.props.benefit.defaultBenefit();

    const { benefit, coupons, isLoading } = this.props.benefit.toJS();
    const { validationErrors, duplicateErrors } = this.state;

    const prepareCouponOptions =
      coupons &&
      coupons.map((coupon) => ({
        label: `${coupon.code} - ${coupon.name}`,
        code: coupon.code,
        name: coupon.name,
        value: coupon._id,
      }));

    const viewMode = this.state.isEdit && this.state.viewMode;
    const maxLengthName = 150;
    const maxLengthCode = 50;
    const maxLengthBenefitDetails = 500;

    const hasValidationErrors = Object.values(validationErrors).some((error) => error);

    const disabledButton =
    JSON.stringify(defaultBenefit) === JSON.stringify(benefit) || // ไม่มีการเปลี่ยนแปลงข้อมูล
    !benefit.code || // รหัสสิทธิประโยชน์ว่าง
    !benefit.name || // ชื่อสิทธิประโยชน์ว่าง
    !benefit.startDate || // วันที่เริ่มต้นว่าง
    !benefit.endDate || // วันที่สิ้นสุดว่าง
    !benefit.benefitDetails || // เนื้อหาสิทธิประโยชน์ว่าง
    duplicateErrors.code || // มีข้อผิดพลาดรหัสซ้ำ
    duplicateErrors.name || // มีข้อผิดพลาดชื่อซ้ำ
    this.validateMaxLength(benefit.name || '', maxLengthName) || // ชื่อยาวเกินไป
    this.validateMaxLength(benefit.code || '', maxLengthCode) || // รหัสยาวเกินไป
    this.validateMaxLength(benefit.benefitDetails || '', maxLengthBenefitDetails) || // เนื้อหายาวเกินไป
    hasValidationErrors; // เพิ่มเงื่อนไขตรวจสอบ validationErrors

    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <Notification ref={(ref) => (this.noti = ref)} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">
                  {this.state.isEdit ? 'แก้ไขสิทธิประโยชน์' : 'สร้างสิทธิประโยชน์ใหม่'}
                </h4>
              </div>
              <div className="card-content">
                <div className="form-group col-md-12">
                  <Box className="col-md-12">
                    <CustomTextArea
                      field="name"
                      label="ชื่อสิทธิประโยชน์"
                      placeholder="กรุณาระบุ"
                      disabled={viewMode}
                      maxLength={maxLengthName}
                      required
                      rows={1}
                      maxRows={2}
                      value={benefit.name}
                      onChange={(event, isError) => this.onChange('name', event.target.value, isError)}
                      error={validationErrors.name || duplicateErrors.name}
                      errorMessageProps={duplicateErrors.nameMessage || ''} // Add error message for duplicate code
                    />

                    <CustomTextArea
                      field="code"
                      label="รหัสสิทธิประโยชน์"
                      placeholder="กรุณาระบุ"
                      disabled={viewMode}
                      maxLength={maxLengthCode}
                      required
                      rows={1}
                      maxRows={2}
                      value={benefit.code}
                      onChange={(event, isError) => this.onChange('code', event.target.value, isError)}
                      error={validationErrors.code || duplicateErrors.code}
                      errorMessageProps={duplicateErrors.codeMessage || ''} // Add error message for duplicate code
                    />
                  </Box>
                </div>

                <div className="form-group col-md-12">
                  <Box className="col-md-12">
                    <CustomDatePicker
                      isPlaceholder="วันที่"
                      label="วันที่เริ่มต้น"
                      disabled={viewMode}
                      required
                      initialDate={benefit.startDate ? moment(benefit.startDate) : null}
                      isOutsideRange={(day) => {
                        const toDay = moment().startOf('day');
                        return benefit.endDate && moment(benefit.endDate).isBefore(day) || moment(toDay).isSameOrAfter(day)
                      }}
                      onDateChange={(date) => {
                        if (moment.isMoment(date) && date.isValid()) {
                          this.onChange('startDate', date.startOf('day').toISOString())
                        }
                      }}
                      error={validationErrors.startDate}
                    />
                    <CustomDatePicker
                      isPlaceholder="วันที่"
                      label="วันที่สิ้นสุด"
                      disabled={viewMode}
                      required
                      initialDate={benefit.endDate ? moment(benefit.endDate) : null}
                      isOutsideRange={(day) => {
                        const toDay = moment().startOf('day');
                        return benefit.startDate && moment(benefit.startDate).isAfter(day) || moment(toDay).isSameOrAfter(day)
                      }}
                      onDateChange={(date) => {
                        if (moment.isMoment(date) && date.isValid()) {
                          this.onChange('endDate', date.endOf('day').toISOString())
                        }
                      }}
                      error={validationErrors.endDate}
                    />
                  </Box>
                </div>

                <div className="form-group col-md-12">
                  <Box className="col-md-12">
                    <CustomTextArea
                      label="เนื้ัอหาของสิทธิประโยชน์"
                      placeholder="กรุณาระบุ"
                      disabled={viewMode}
                      maxLength={maxLengthBenefitDetails}
                      required
                      rows={1}
                      maxRows={3}
                      value={benefit.benefitDetails}
                      onChange={(event, isError) => this.onChange('benefitDetails', event.target.value, isError)}
                      error={validationErrors.benefitDetails}
                    />
                  </Box>
                </div>

                <div className="form-group col-md-12">
                  <Box className="col-md-12">
                    <CustomSelect
                      disabled={viewMode}
                      label="Discount Code"
                      options={prepareCouponOptions || []}
                      value={benefit.couponId}
                      onChange={(value) => this.onChange('couponId', value)}
                      onInputChange={(value) => this.onSearchCoupon(value)}
                      onClose={() => this.onClose()}
                    />
                  </Box>
                </div>

                <div className="form-group col-md-12">
                  <Box className="col-md-12" style={{ display: 'block' }}>
                    <label>สถานะการใช้งาน</label>
                    <CustomToggle
                      disabled={viewMode}
                      checked={benefit.isActive}
                      onChange={(value) => this.onChange('isActive', value)}
                    />
                  </Box>
                </div>

                <div className="form-group col-md-12">
                  {this.renderButtonFooter(disabledButton)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(inject('auth', 'benefit')(observer(BenefitEditPage)));
