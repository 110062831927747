import React from "react";
import Select from 'react-select';
import FormGroup from "../../../../components/form/FormGroup";
import { get } from "lodash";
import CouponStore from "../../../../stores/qchang/CouponStore";

export class BenefitSearchFromGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        queryBenefit: '',
        optionsBenefit: [],
        isLoading: false,
        }

        this.debounceTimeout = null;
    }

    debounce = (func, delay) => {
        return (...args) => {
          if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
    
          this.debounceTimeout = setTimeout(() => {
            func(...args);
          }, delay);
        };
    }

    fetchOptions = async (query) => {
        if (query.length < 3) return;
        this.setState({ isLoading: true }); 

        let data
        try {
            data = await CouponStore.searchBenefit(query);
        } catch (error) {
            this.props.notiRef.error(`${error.message}`, 'ล้มเหลว');
        }
    
        const options = (get(data, 'benefit_list', [])).map((item) => ({
          label: `${item.code} - ${item.name}`,
          value: item._id,
        }));
        
        this.setState((prevState) => ({
          ...prevState,
          optionsBenefit: options, 
          isLoading: false
        }));
    };
    
    handleSearch = this.debounce((query) => {
        this.fetchOptions(query);
    }, 300);

    handleBenefitInputChange = async (inputValue) => {
        this.setState({ queryBenefit: inputValue });
        this.handleSearch(inputValue); 
    }

    render() {
        const { selectedBenefits, onChange } = this.props;
        const { queryBenefit, optionsBenefit, isLoading } = this.state;

        return (
            <div className='row' style={{ marginBottom: '10px' }}>
                <div className="col-md-12" style={{ padding: '0px' }}>
                <FormGroup label="สิทธิประโยชน์" containerClassName="col-md-12" containerStyle={{ paddingRight: '5px' }}>
                    <Select
                        className="p4-select pt-0"
                        clearable={false}
                        multi                       
                        options={optionsBenefit}
                        placeholder="กรุณาเลือก"
                        isLoading={isLoading}
                        searchable={true}
                        value={selectedBenefits}
                        onChange={onChange}
                        onInputChange={this.handleBenefitInputChange}
                        noResultsText={isLoading ? 'กำลังโหลดข้อมูล' : 'หาข้อมูลไม่พบ กรุณาลองใหม่อีกครั้ง'}
                        inputValue={queryBenefit}
                    />
                </FormGroup>
                </div>
            </div>
        )
    }
}

