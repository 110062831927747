import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

// Styled components
const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 20px 0 0 0;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;

  .required-asterisk {
    color: red;
    margin-left: 2px;
  }
`;

const TextAreaWrapper = styled.div`
  position: relative;
`;

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  overflow-y: auto;
  border: 1px solid ${(props) => (props.isError ? 'red' : '#ccc')};
  line-height: normal;
  padding: 8px;
  padding-left: 10px;
  padding-right: 60px; /* Add padding to avoid overlap with counter */
  border-radius: 5px;
  min-height: ${(props) => props.rows * 1.5}em;
  max-height: ${(props) => props.maxRows * 1.5}em;

  &:focus {
    border-color: ${(props) => (props.isError ? 'red' : '#265ED6')};
    outline: none;
  }

  &:disabled {
    background-color: #fffaee;
  }
`;

const Counter = styled.div`
  position: absolute;
  right: 25px;
  bottom: 15px;
  color: ${(props) => (props.isError ? 'red' : '#6C757D')};
`;

const ErrorMessage = styled.div`
  color: red;
  min-height: 26px; /* เพิ่มความสูงคงที่สำหรับ Error Message */
  font-size: 18px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const CustomTextArea = ({
  field = '',
  value = '',
  label,
  disabled = false,
  placeholder,
  required,
  onChange,
  maxLength = 150,
  rows = 1,
  maxRows = 3,
  error = false,
  errorMessageProps = ''
}) => {
  const [text, setText] = useState(value);
  const [errorMessage, setErrorMessage] = useState('');
  const textAreaRef = useRef(null);

  useEffect(() => {
    setText(value);
  }, [value]);
  
  useEffect(() => {
    adjustHeight();
  }, [text]);

  const handleInputChange = (event) => {
    let value = event.target.value;

    // Prevent leading spaces
    if (value.startsWith(' ')) {
      return;
    }

    // not allow spacebar
    if (field === 'code' && value.includes(' ')) {
      return;
    }

    // not allow enter
    if (field === 'name' || field === 'code') {
      if (value.includes('\n')) {
        return;
      }
    }

    // Regular expression to allow Thai, English letters, numbers, special characters, spaces, and newline characters, but disallow emojis
    const allowedCharsRegex = /^[\u0E00-\u0E7F\u0020-\u007E\u00A1-\u00FF\u2000-\u206F\r\n]*$/;

    const regExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi
    if (regExp.test(value)) {
      return;
    }

    // Remove emojis
    value = value.replace(
      /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{1FB00}-\u{1FBFF}]/gu,
      '',
    );

    let isError = false;
    // Check for unsupported characters and remove them
    if (!allowedCharsRegex.test(value)) {
      setErrorMessage('รองรับเฉพาะ (ก-ฮ), (a-z), (A-Z), (0-9), และอักขระพิเศษเท่านั้น');
      isError = true;
      value = value.replace(/[^(\u0E00-\u0E7F|\u0020-\u007E|\u00A1-\u00FF|\u2000-\u206F)]/g, '');
    } else {
      setErrorMessage('');
      isError = false;
    }

    // Update state
    setText(value);

    // Call external onChange handler if provided
    if (onChange) {
      onChange(event, isError);
    }
  };

  // Optional: Remove emojis if pasted
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData.getData('text');
    const cleanedData = clipboardData.replace(
      /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{1FB00}-\u{1FBFF}]/gu,
      '',
    );

    if (clipboardData !== cleanedData) {
      event.preventDefault();
      setText(cleanedData);
      if (onChange) {
        onChange({ target: { value: cleanedData } });
      }
    }
  };

  const adjustHeight = () => {
    const textArea = textAreaRef.current;

    if (textArea) {
      textArea.style.height = 'auto'; // Reset height to calculate scrollHeight
      textArea.style.height = `${Math.min(
        textArea.scrollHeight,
        maxRows * 1.5 * 18 // Assuming 16px as base font size for line-height calculation
      )}px`;
    }
  };

  const errorPropsMessage = errorMessageProps 
    ? errorMessageProps 
    : errorMessage
      ? errorMessage
      : 'กรุณากรอกข้อมูลให้ครบถ้วน';

  return (
    <InputContainer>
      {label && (
        <Label htmlFor="customInput">
          {label}
          {required && <span className="required-asterisk">*</span>}
        </Label>
      )}
      <TextAreaWrapper>
        <TextArea
          ref={textAreaRef}
          id="customInput"
          value={text}
          onChange={handleInputChange}
          onPaste={handlePaste}
          rows={rows}
          maxRows={maxRows}
          placeholder={placeholder || 'กรุณากรอกข้อความที่นี่'}
          required={required}
          isError={text.length > maxLength || error}
          disabled={disabled}
        />
        <Counter isError={text.length > maxLength}>
          {text.length}
        </Counter>
      </TextAreaWrapper>
      <ErrorMessage visible={required & (!!errorMessage || text.length > maxLength || error)}>
        {(text.length > maxLength && `เกินจำนวนตัวอักษรที่กำหนด (${maxLength} ตัวอักษร)`)}
        {error && errorPropsMessage }
      </ErrorMessage>
    </InputContainer>
  );
};

export default CustomTextArea;
