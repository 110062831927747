import React, { Component } from 'react'
import moment from 'moment';
import EnToggle from '../../../components/form/EnToggle';
import http from '../../../utils/http';
import config from '../../../config';
import swal from 'sweetalert2';
import { isAllow } from '../../../utils/permission';

class BroadcastRowTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      work_of_areas: [],
      type_of_works: [],
      announcement_date: '',
      announcement_time: '',
      create_at: '',
      release_status: false,
      is_published: false,
      _id: ""
    };
  }

  componentDidMount() {
    this.setState(this.props.broadcast)
  }

  getHasFileIcon(hasFile) {
    return hasFile
      ? <i className="fa fa-check" aria-hidden="true" style={{ color: '#28a745' }} />
      : <i className="fa fa-times" aria-hidden="true" style={{ color: '#dc3545' }} />
  }

  convertAnnouncementDate(date, time) {
    return (
      <span>
        <span>{date && moment(date).add(543, 'year').format('L')}</span><br />
        {time && <span>{time} น.</span>}
      </span>
    )
  }

  convertCodeProvinces(codes) {
    if (!codes.length) {
      return <p>ทุกจังหวัด</p>
    }
    const filterProvince = this.props.provinces.filter(province => codes.find(code => province.value === code.province_id))
    return filterProvince.map(province => <span key={JSON.stringify(province)}>{province.label}<br /></span>)

  }

  convertCodeTypeofwork(codes) {
    if (!codes.length) {
      return <p>ทุกประเภทงาน</p>
    }
    const result = this.props.typeofworks.filter(typeofwork => codes.find(code => typeofwork.value === code))
    return result.map(item => <span key={JSON.stringify(item)}>{item.label}<br /> </span>)
  }

  async handleChangeToggle(id, value) {
    if (this.overTime()) {
      return swal.fire({
        title: 'ประกาศนี้เลยกำหนด',
        icon: 'error',
      })
    }
    const body = value ? { release_status: "published" } : { release_status: "draft" }
    const { data } = await http.patch(`${config.api.sims}/v1/broadcast/${id}/change-release_status`, body)
    this.setState(data)
  }

  overTime() {
    const { announcement_date, announcement_time } = this.state
    const currentTime = new Date()
    const announcementDate = new Date(announcement_date)
    const overDate = moment(currentTime, "DD/MM/YYYY") > moment(announcementDate, "DD/MM/YYYY")
    const overHour = moment(currentTime.getHours(), "HH:mm") >= moment(announcement_time, "HH:mm")
    return overDate && overHour
  }

  render() {
    const { title, work_of_areas, type_of_works, announcement_date, announcement_time, create_at, release_status, is_published, _id } = this.state
    return (
      <tr key={_id}>
        <td width="15%" >{title}</td>
        <td width="15%" >{this.convertCodeProvinces(work_of_areas)}</td>
        <td width="30%" >{this.convertCodeTypeofwork(type_of_works)}</td>
        <td width="10%" className="text-center">{this.convertAnnouncementDate(create_at)}</td>
        <td width="10%" className="text-center">
          {this.convertAnnouncementDate(announcement_date, announcement_time)}<br />
          {(!is_published && this.overTime()) && <span style={{ color: 'red' }}>ประกาศนี้เลยกำหนด</span>}
        </td>
        <td width="5%" className="text-center">
          <EnToggle
            style={{ display: 'block' }}
            disabled={isAllow("CHANGE_BROADCAST_STATUS") ? is_published || this.overTime() : true}
            checked={release_status !== 'draft'}
            onChange={(value) => this.handleChangeToggle(_id, value)}
          />
          <span>{release_status === "draft" ? 'ฉบับร่าง' : 'พร้อมเผยแพร่'}</span>
        </td>
        <td width="5%" className="text-center"> {this.getHasFileIcon(is_published)}</td>
        <td width="10%" className="text-center">
          {is_published
            ? (<button
              className="btn btn-xs"
              title="ดู"
              data-tip="ดูรายละเอียด"
              onClick={() => this.props.history.push(`${config.publicUrl}/superadmin/broadcasts/${_id}/edit`)}
            >
              <span className="fa fa-eye" aria-hidden="true" />
            </button>)
            : (<button
              disabled={isAllow("EDIT_BROADCAST") ? false : true}
              className="btn btn-xs"
              title="แก้ไข"
              data-tip="แก้ไข"
              onClick={() => this.props.history.push(`${config.publicUrl}/superadmin/broadcasts/${_id}/edit`)}
            >
              <span className="fa fa-pencil" aria-hidden="true" />
            </button>)
          }
          <button
            disabled={isAllow("DELETE_BROADCAST") ? is_published : true}
            className="btn btn-xs"
            title="ลบ"
            data-tip="ลบ"
            onClick={() => this.props.deleteBroadcast(_id)}
          >
            <span className="fa fa-trash btn-icon" aria-hidden="true" />
          </button>
        </td>
      </tr>
    )

  }
}

export default BroadcastRowTable;
