/* eslint-disable no- */
/* eslint-disable no-unused-vars */
/* global FormData */

import * as _ from 'lodash';
import qs from 'query-string';
import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';
import datetime from '../utils/datetime';
import common from '../utils/common';
import AuthStore from './AuthStore';
import ReactDom from 'react-dom';
import { isContractorEmailValid } from '../components/form/FormValidation';
import Axios from 'axios';
import DownloadStore from './DownloadStore';

export class ContractorStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      isVacationLoading: false,
      isRegistering: false,
      isSumitting: false,
      list: [],
      listContractor: [],
      listId: [],
      pendingList: [],
      info: this.InitInfo(),
      rejectReason: '',
      rejectHistory: [],
      jobPerDay: { morning: 1, afternoon: 1 },
      workCalendarInfo: [],
      pagination: {},
      query: {
        status: 'registered',
        q: '',
        type_of_work: undefined,
      },
      options: {
        contractor_team: [],
        status: [
          { value: '', label: 'ทั้งหมด' },
          { value: 'registered', label: 'รออนุมัติ' },
          { value: 'approved', label: 'อนุมัติ' },
          { value: 'rejected', label: 'ไม่อนุมัติ' },
          { value: '', label: 'ขอแก้ไขข้อมูล' },
          { value: '', label: 'รออนุมัติการแก้ไข' },
        ],
        criminal_status: [
          { value: '', label: 'ทั้งหมด' },
          { value: '0', label: 'มีผลตรวจแล้ว' },
          { value: '1', label: 'กำลังรอผลตรวจ' },
          { value: '2', label: 'ยังไม่มีผลตรวจ' },
        ],
        province: [],
        nationality: [
          { value: 'TH', label: 'ไทย' },
          { value: 'Other', label: 'อื่่นๆ' },
        ],
        prefix: [],
      },
      fileTypes: {
        profile_pic: 'profile_pic',
        id_card: 'id_card',
        criminal_record_check: 'criminal_record_check',
        electricity_certificate: 'electricity_certificate',
        other_certificate: 'other_certificate',
        company_cert: 'company_cert',
        vat: 'vat',
        book_bank: 'book_bank',
      },
    });
  }

  InitInfo() {
    return {
      ctrCode: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      teamName: '',
      typeOfWork: [],
      typeOfWorkApproved: [],
      otherWork: '',
      provinceId: '',
      districtId: '',
      subDistrictId: '',
      zipCode: '',
      address: '',
      timesOfWork: '',
      installationPerDay: { morning: 1, afternoon: 1 },
      surveyPerDay: { morning: 1, afternoon: 1 },
      areaOfWork: [],
      areaOfWorkApproved: [],
      pin: '',
      profilePic: '',
      permissions: [],
      blockedPayment: false,
      isPermitted: true,
      isPermittedRestartDate: null,
      // tax: {
      //   companyName: '',
      //   taxNo: '',
      //   branchNo: '',
      //   address: {
      //     provinceId: '',
      //     districtId: '',
      //     subDistrictId: '',
      //     zipCode: '',
      //   },
      // },
      taxCompanyName: '',
      taxNo: '',
      taxPhone: '',
      taxBranchNo: '',
      taxProvinceCode: '',
      taxProvinceName: '',
      taxDistrictCode: '',
      taxDistrictName: '',
      taxSubDistrictCode: '',
      taxSubDistrictName: '',
      taxZipCode: '',
      taxAddress: '',
      cost: [],
      oldCost: [],
      isJuristic: true,
      bankCode: '',
      bankAccountNo: '',
      bankAccountName: '',
      calendarType: 'S',
      grade: [],
      // grade:[],
      signature: '',
      file: [],
      netsuiteCustomerCode: '',
      netsuiteInternalId: '',
      vaccine: {},
      status: '',
      dateOfBirth: new Date(2000, 0, 1),
      dateOfBirthFlag: 1,
      // add new field
      idCardNo: '',
      prefix: '',
      nationality: '',
      contactPhoneNo: '',
      carId: '',
      carIdProvince: { code: '', name: '' },
      criminalRecordCheckStatus: '',
      isShippingSameAddress: false,
      shippingAddress: {
        province: {
          code: '',
          name: '',
        },
        district: {
          code: '',
          name: '',
        },
        subDistrict: {
          code: '',
          name: '',
        },
        zipCode: '',
        address: '',
      },
    };
  }

  clearInfo() {
    this.info = this.InitInfo();
    this.jobPerDay = { morning: 1, afternoon: 1 };
    this.workCalendarInfo = [];
  }

  saveContractorJobPerDay(time, value) {
    if (value) {
      if (time === 'morning') {
        this.jobPerDay.morning = value;
      } else if (time === 'afternoon') {
        this.jobPerDay.afternoon = value;
      }
    }
  }

  setInfo(key, value) {
    this.info[key] = value;
  }

  saveInfo(key, value) {
    const info = this.toJS().info;
    if (key === 'typeOfWork') {
      if (!Array.isArray(info.typeOfWork)) info.typeOfWork = [];

      if (value >= 0) {
        if (_.indexOf(info.typeOfWork, value) >= 0) {
          if (value === 0) {
            info.otherWork = '';
          }

          _.remove(info.typeOfWork, (t) => t === value);
        } else {
          info.typeOfWork.push(value);
        }
      }
    } else if (key === 'areaOfWork') {
      if (Array.isArray(value) && value.length) {
        info[key] = value.map((a) => {
          return {
            province_id: a.provinceCode,
            district_id: a.districtCode,
          };
        });
      }
    } else if (key === 'areaOfWorkApproved') {
      info.areaOfWorkApproved = value;
    } else if (key === 'morningSurveyPerDay') {
      info.surveyPerDay.morning = value;
    } else if (key === 'afternoonSurveyPerDay') {
      info.surveyPerDay.afternoon = value;
    } else if (key === 'morningInstallationPerDay') {
      info.installationPerDay.morning = value;
    } else if (key === 'afternoonInstallationPerDay') {
      info.installationPerDay.afternoon = value;
    } else {
      info[key] = value;
    }
    this.info = info;
  }

  async uploadFile(file, type) {
    try {
      this.isLoading = true;
      const fileUpload = await this.uploadImageOnly(file, type);
      if (type === this.fileTypes.profile_pic) {
        this.info.profilePic = fileUpload.url;
        this.info.profilePicInfo = {
          path: fileUpload.path,
          file: fileUpload.file,
        };
      } else if (type === this.fileTypes.signature) {
        this.info.signature = fileUpload.url;
      } else {
        this.info.file.push({
          date: fileUpload.date,
          name: fileUpload.name,
          path: fileUpload.pathFile,
          type: type,
          url: fileUpload.path,
        });
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  removeFile(files) {
    this.info.file = files;
  }

  async updateRejectJob(jobId, contractorId, reason, user, source) {
    try {
      const contractorInfo = this.info;
      const rejectHistory = {
        job_id: jobId,
        reason: reason,
        date: datetime.SendToApiWithTime(new Date()),
        create_from: 'sims-web',
        user,
      };
      await http.put(`${config.api.sims}/v1/contractors/${contractorId}/rejectJob`, {
        source,
        rejectHistory: rejectHistory,
        rejectBy: contractorInfo && contractorInfo.teamName ? contractorInfo.teamName : 'Admin',
      });
    } catch (err) {
      throw err;
    }
  }

  async updateAcceptJob(jobId, contractorId, user, source) {
    try {
      // let contractor = this.toJS();
      const acceptHistory = {
        job_id: jobId,
        create_from: 'sims-web',
        user,
      };

      await http.put(`${config.api.sims}/v1/contractors/${contractorId}/acceptJob`, {
        acceptHistory: acceptHistory,
        source,
      });
    } catch (err) {
      throw err;
    }
  }

  saveMultiInfo(data) {
    let keys = Object.keys(data);
    const info = this.toJS().info;
    _.forEach(keys, (k) => {
      info[k] = data[k];
    });
    this.info = info;
  }

  isValidRegisterInfo(step) {
    const registerInfo = this.toJS().info;
    let isValid = false;
    if (step >= 1) {
      let regexPhone = new RegExp('(^$|[0-9]{10})');
      let taxNo = new RegExp('(^$|[0-9]{13}$)');
      let bankAccountNo = new RegExp('(^$|[0-9]{10,12})');
      let isValidTaxNo = taxNo.test(registerInfo.taxNo);
      const bankNo = registerInfo.bankAccountNo.replace(/-/g, '');
      let isValidBankAccountNo = bankAccountNo.test(bankNo);
      // let registerInfo.taxNo
      let isValidPhone = registerInfo.phone && registerInfo.phone.length === 10 && regexPhone.test(registerInfo.phone);
      let isValidEmail = true;
      if (registerInfo.email) {
        isValidEmail = isContractorEmailValid(registerInfo.email);
      }
      isValid =
        registerInfo.lastName &&
        registerInfo.firstName &&
        isValidPhone &&
        isValidEmail &&
        registerInfo.taxAddress &&
        registerInfo.taxProvinceCode &&
        registerInfo.taxDistrictCode &&
        registerInfo.taxSubDistrictCode &&
        registerInfo.taxZipCode &&
        registerInfo.taxNo &&
        isValidTaxNo &&
        isValidBankAccountNo &&
        registerInfo.bankAccountNo &&
        registerInfo.bankAccountName &&
        registerInfo.bankCode &&
        registerInfo.email.trim().length > 0;

      if (!registerInfo.isJuristic) {
        isValid = isValid && registerInfo.signature;
      }

      if (registerInfo.isJuristic) {
        let regex = new RegExp('(^$|[0-9]{5}$)');
        const isValidBranchNo = regex.test(registerInfo.taxBranchNo);
        isValid = isValid && registerInfo.taxBranchNo && isValidBranchNo && registerInfo.teamName;
      }

      if (isValid && step >= 2) {
        let otherWork = registerInfo.typeOfWork.find((i) => i === 0);
        let typeOfWorkSelected = Array.isArray(registerInfo.typeOfWork) ? registerInfo.typeOfWork.length : 0;
        isValid = typeOfWorkSelected;
        if (otherWork) {
          isValid = typeOfWorkSelected && registerInfo.otherWork;
        }
        if (isValid && step >= 3) {
          isValid = registerInfo.timesOfWork > 0 && registerInfo.installationPerDay && registerInfo.surveyPerDay;

          if (isValid && step >= 4) {
            let regex = new RegExp('^[0-9]{6,6}$');
            let isValidPin = regex.test(registerInfo.pin);
            isValid = isValidPin;
          }
        }
      }
    }
    return isValid;
  }

  async getAll(options) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let url = `${config.api.sims}/v1/contractors`;
      if (options) {
        let query = _.pick(options, ['workTypeId', 'jobType', 'needVacation']);
        let storeInfo = AuthStore.getStore();
        if (storeInfo && storeInfo._id) {
          query.storeId = storeInfo._id;
        }
        if (_.isEmpty(options)) {
          query.filterByArea = true;
        }

        if (options.contractorId && !options.filterContractorsOnly) {
          query.contractor_id = options.contractorId;
        }

        if (options.startDate) {
          query.startDate = datetime.SendToApiWithTime(options.startDate);
        }

        if (options.endDate) {
          query.endDate = datetime.SendToApiWithTime(options.endDate);
        }

        if (options.aCode) {
          query.aCode = options.aCode;
        }

        if (options.jobList) {
          query.jobList = options.jobList;
        }

        url += `?${common.urlBuilder(query)}`;
      }
      let response = await http.get(url);
      this.isLoading = false;

      if (response && response.data && response.data.data) {
        let result = response.data.data;
        // เพิ่มการกรองช่างโดยดูจากงานที่ admin approved
        result = result.filter((e) => {
          if (options && options.workTypeId && e.type_of_work_approved && e.type_of_work_approved.length > 0) {
            if (e.type_of_work_approved.indexOf(options.workTypeId) < 0) {
              return false;
            }
            return true;
          }
          return true;
        });

        this.list = result;
        return result;
      } else {
        return null;
      }
    } catch (error) {
      this.isLoading = false;
      return null;
    } finally {
      this.isLoading = false;
    }
  }

  async getContractorAdminCalendar(options) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let url = `${config.api.sims}/v1/contractors/admincalendar`;
      if (options) {
        let query = _.pick(options, ['workTypeId', 'jobType', 'needVacation']);
        let storeInfo = AuthStore.getStore();
        if (storeInfo && storeInfo._id) {
          query.storeId = storeInfo._id;
        }
        if (_.isEmpty(options)) {
          query.filterByArea = true;
        }

        if (options.contractorId && !options.filterContractorsOnly) {
          query.contractor_id = options.contractorId;
        }

        if (options.startDate) {
          query.startDate = datetime.SendToApiWithTime(options.startDate);
        }

        if (options.endDate) {
          query.endDate = datetime.SendToApiWithTime(options.endDate);
        }

        if (options.aCode) {
          query.aCode = options.aCode;
        }

        if (options.isAdminCalendar) {
          query.isAdminCalendar = options.isAdminCalendar;
        }

        url += `?${common.urlBuilder(query)}`;
      }
      let response = await http.get(url);
      this.isLoading = false;

      if (response && response.data && response.data.data) {
        let result = response.data.data;
        // เพิ่มการกรองช่างโดยดูจากงานที่ admin approved
        result = result.filter((e) => {
          if (options && options.workTypeId && e.type_of_work_approved && e.type_of_work_approved.length > 0) {
            if (e.type_of_work_approved.indexOf(options.workTypeId) < 0) {
              return false;
            }
            return true;
          }
          return true;
        });
        this.list = result;
        return result;
      } else {
        return null;
      }
    } catch (error) {
      this.isLoading = false;
      return null;
    } finally {
      this.isLoading = false;
    }
  }

  async getAllStoreContractor(storeId) {
    try {
      const response = await http.get(`${config.api.sims}/v1/contractors/store/${storeId}`);
      if (response.status === 200 && response.data) {
        return Promise.resolve(response.data.items);
      }
    } catch (error) {
      throw error;
    }
  }

  async getPaginatedContractors(query) {
    query = _.pickBy(query, (value) => !!value);
    const qstring = qs.stringify(_.pick(query, ['limit', 'page', 'search', 'filterByArea']));

    try {
      let url = `${config.api.sims}/v1/contractors/store/${query.storeId}/pagination?${qstring}&sort=team_name&order=asc`;
      const response = await http.get(url);

      if (response.status === 200 && response.data) {
        const data = response.data;

        return Promise.resolve(data);
      }
    } catch (error) {
      throw error;
    }
  }

  getContractors = async (query) => {
    const { status, data } = await http.get(`${config.api.sims}/v1/contractors/v2`, {
      params: {
        store_id: _.get(query, 'storeId'),
      },
    });
    if (status === 200 && data) {
      return data.contractors;
    }
  };

  async updateStoreContractors(storeId, contractorIds = []) {
    try {
      const response = await http.put(`${config.api.sims}/v1/contractors/store/${storeId}`, { contractorIds });

      if (response.status === 200) {
        return Promise.resolve();
      }
    } catch (e) {
      throw e;
    }
  }

  async removeStoreContractorById(storeId, contractorId) {
    try {
      const response = await http.delete(`${config.api.sims}/v1/contractors/store/${storeId}`, { contractorId });

      if (response.status === 200) {
        return Promise.resolve();
      }
    } catch (e) {
      throw e;
    }
  }

  async getAllWithFields(queryProps) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const query = { fields: '_id,team_name', ...queryProps };
      const res = await http.get(`${config.api.sims}/v1/contractors/all`, { params: query });
      if (res && res.data && res.data.data) {
        this.list = res.data.data;
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  validateInput(self, selectFocus) {
    // let bthis = this;
    let time = 100;
    let classInvalid = 'is-invalid-input';
    return new Promise(function (resolve, reject) {
      if (!self.validation()) {
        self.refs.FormValidation.isValid();
        setTimeout(() => {
          let ele = ReactDom.findDOMNode(self.refs.FormValidation).getElementsByClassName(classInvalid);
          if (ele && ele.length) {
            if (ele[0].tagName === 'INPUT') {
              ele[0].focus();
            } else if (ele[0].tagName === 'DIV') {
              return selectFocus;
            } else {
              let offsetTop = ele[0].offsetTop;
              document.body.scrollTo(0, offsetTop);
            }
          }
          reject(new Error('กรุณากรอกข้อมูลให้ครบ'));
        }, time);
      } else {
        resolve();
      }
    });
  }

  async getContractorByStatus(status, query) {
    if (this.isLoading) return;
    this.isLoading = true;
    this.query = {
      status: status,
      q: query.q,
      type_of_work: query.type_of_work,
    };

    try {
      let url = `${config.api.sims}/v1/contractors/status/${status}`;
      let response = await http.get(url, { params: query });
      this.isLoading = false;
      if (response && response.data && response.data.data) {
        const result = response.data.data;
        this.pendingList = result;
        this.pagination = response.data.pagination;
        return result;
      } else {
        return null;
      }
    } catch (error) {
      this.isLoading = false;
      return null;
    }
  }

  dateOfBirthText(contractorData) {
    if (typeof contractorData.date_of_birth == 'string') {
      contractorData.date_of_birth = new Date(contractorData.date_of_birth);
    }
    if (contractorData.date_of_birth != 'Invalid Date') {
      const dateOfBirth = contractorData.date_of_birth;
      const year = parseInt(dateOfBirth.getFullYear());
      let month = parseInt(dateOfBirth.getMonth() + 1);
      let day = parseInt(dateOfBirth.getDate());
      if (month <= 9) {
        month = '0' + month;
      }
      if (day <= 9) {
        day = '0' + day;
      }
      if (contractorData.date_of_birth_flag === 1) {
        day = '-';
      } else if (contractorData.date_of_birth_flag === 2) {
        day = '-';
        month = '-';
      } else if (contractorData.date_of_birth_flag === 3) {
        month = '-';
      }
      return `${day}/${month}/${year}`;
    }
    return '';
  }

  async findDraftContractorById(id) {
    const res = await http.get(`${config.api.sims}/v1/contractors/${id}/draft`);
    const draft = res.data.data.draft_data;

    let taxInfo 
    if(draft.tax){
      taxInfo = {
        taxCompanyName: draft.tax ? draft.tax.company_name : '',
        taxNo: draft.tax ? draft.tax.tax_no : '',
        taxPhone: draft.tax ? draft.tax.phone : draft.phone,
        taxBranchNo: draft.tax ? draft.tax.branch_no : '',
        taxProvinceCode: draft.tax && draft.tax.address && draft.tax.address.province ? draft.tax.address.province.code : '',
        taxProvinceName: draft.tax && draft.tax.address && draft.tax.address.province ? draft.tax.address.province.name : '',
        taxDistrictCode: draft.tax && draft.tax.address && draft.tax.address.district ? draft.tax.address.district.code : '',
        taxDistrictName: draft.tax && draft.tax.address && draft.tax.address.district ? draft.tax.address.district.name : '',
        taxSubDistrictCode: draft.tax && draft.tax.address && draft.tax.address.sub_district ? draft.tax.address.sub_district.code : '',
        taxSubDistrictName: draft.tax && draft.tax.address && draft.tax.address.sub_district ? draft.tax.address.sub_district.name : '',
        taxZipCode: draft.tax && draft.tax.address ? draft.tax.address.zip_code : '',
        taxAddress: draft.tax && draft.tax.address ? draft.tax.address.address : '',
      }
    }

    this.draft = _.mapKeys({ ...draft, ...taxInfo}, (value, key) => _.camelCase(key))
  }

  async getContractor(options) {
    // if (this.isLoading) return;
    this.isLoading = true;
    try {
      let url = `${config.api.sims}/v1/contractors`;
      if (options && options.id) {
        url += `/${options.id}`;
      } else {
        throw new Error('ไม่สามารถดึงข้อมูลช่างได้');
      }

      if (options && options.isAdmin) {
        url += `?isAdmin=${options.isAdmin}`;
      }

      let response = await http.get(url);
      if (response && response.data && response.data.data) {
        let historyGrade = [];
        if (options && options.id) {
          historyGrade = await this.getContractorHistoryGrade(options.id);
        }

        const info = response.data.data;
        const signUrl = info.profile_pic_info && (await DownloadStore.getSignUrl(info.profile_pic_info.file, info.profile_pic_info.path));
        const shippingAddress = this.defaultShippingAddress(info);

        if(info.has_draft){
          await this.findDraftContractorById(options.id)
        }

        this.info = {
          ctrCode: info.ctr_code,
          firstName: info.first_name,
          lastName: info.last_name,
          phone: info.phone,
          email: info.email,
          teamName: info.team_name,
          typeOfWork: info.type_of_work,
          typeOfWorkApproved: info.type_of_work_approved,
          otherWork: info.other_work,
          provinceId: info.province_id,
          districtId: info.district_id,
          subDistrictId: info.sub_district_id,
          zipCode: info.zip_code,
          address: info.address,
          timesOfWork: info.times_of_work,
          installationPerDay: info.installation_per_day || {
            morning: 1,
            afternoon: 1,
          },
          surveyPerDay: info.survey_per_day || { morning: 1, afternoon: 1 },
          areaOfWork: info.area_of_work,
          areaOfWorkApproved: info.area_of_work_approved,
          profilePic: signUrl && signUrl.fileUrl ? signUrl.fileUrl : info.profile_pic,
          permissions: info.permissions,
          blockedPayment: info.blocked_payment,
          isPermitted: info.is_permitted,
          isPermittedRestartDate: info.is_permitted_restart_date,
          pin: '',
          taxCompanyName: info.tax ? info.tax.company_name : '',
          taxNo: info.tax ? info.tax.tax_no : '',
          taxPhone: info.tax ? info.tax.phone : info.phone,
          taxBranchNo: info.tax ? info.tax.branch_no : '',
          taxProvinceCode: info.tax && info.tax.address && info.tax.address.province ? info.tax.address.province.code : '',
          taxProvinceName: info.tax && info.tax.address && info.tax.address.province ? info.tax.address.province.name : '',
          taxDistrictCode: info.tax && info.tax.address && info.tax.address.district ? info.tax.address.district.code : '',
          taxDistrictName: info.tax && info.tax.address && info.tax.address.district ? info.tax.address.district.name : '',
          taxSubDistrictCode: info.tax && info.tax.address && info.tax.address.sub_district ? info.tax.address.sub_district.code : '',
          taxSubDistrictName: info.tax && info.tax.address && info.tax.address.sub_district ? info.tax.address.sub_district.name : '',
          taxZipCode: info.tax && info.tax.address ? info.tax.address.zip_code : '',
          taxAddress: info.tax && info.tax.address ? info.tax.address.address : '',
          cost: info.cost,
          isJuristic: info.is_juristic,
          isAvailable: info.is_available,
          isAvailableUpdateDate: info.is_available_update_date,
          bankCode: info.bank_code,
          bankAccountName: info.bank_account_name,
          bankAccountNo: info.bank_account_no,
          calendarType: info.calendar_type,
          grade: info.grade || [],
          // grade : info.grade || [],
          signature: info.signature || '',
          file: info.file,
          netsuiteCustomerCode: info.netsuite_customer_code || '',
          netsuiteInternalId: info.netsuite_internal_id || '',
          vaccine: info.vaccine || {},
          status: info.status || '',
          stores: info.stores || [],
          dateOfBirth: info.date_of_birth,
          dateOfBirthFlag: info.date_of_birth_flag,
          dateOfBirthText: !!(info.date_of_birth && Number.isInteger(info.date_of_birth_flag)) ? this.dateOfBirthText(info) : '',
          historyGrade: historyGrade || [],

          // add new field
          idCardNo: _.get(info, 'id_card_no', ''),
          prefix: _.get(info, 'prefix', ''),
          nationality: _.get(info, 'nationality', ''),
          contactPhoneNo: _.get(info, 'contact_phone_no', ''),
          carId: _.get(info, 'car_id', ''),
          carIdProvince: _.get(info, 'car_id_province', { code: '', name: '' }),
          criminalRecordCheckStatus: _.get(info, 'criminal_record_check_status', ''),
          isShippingSameAddress: _.get(shippingAddress, 'is_shipping_same_address', false),
          shippingAddress: {
            province: {
              code: _.get(shippingAddress, 'shipping_address.province.code', ''),
              name: _.get(shippingAddress, 'shipping_address.province.name', ''),
            },
            district: {
              code: _.get(shippingAddress, 'shipping_address.district.code', ''),
              name: _.get(shippingAddress, 'shipping_address.district.name', ''),
            },
            subDistrict: {
              code: _.get(shippingAddress, 'shipping_address.sub_district.code', ''),
              name: _.get(shippingAddress, 'shipping_address.sub_district.name', ''),
            },
            zipCode: _.get(shippingAddress, 'shipping_address.zip_code', ''),
            address: _.get(shippingAddress, 'shipping_address.address', ''),
          },
          firstSelectShirt: _.get(info, 'shirt_size', null) ? _.get(info, 'shirt_size', '').length === 0 : true,
          shirtSize: _.get(info, 'shirt_size', null) ? _.get(info, 'shirt_size', '').split(' ')[0] : '',
          isApproveProfilePic: _.get(info, 'is_profile_pic_approved', false),
          hasDraft: _.get(info, 'has_draft', false),
          oldFiles: _.get(info, 'old_files', []), //for compare draft file
        };
        return info;
      } else {
        return null;
      }
    } catch (error) {
      console.log('error >>>>>>>>>>>>>>>>', error);
      // this.isLoading = false;
      return null;
    } finally {
      this.isLoading = false;
    }
  }

  async getMobileAvailable(phone) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      let response = await http.get(`${config.api.sims}/v1/contractors/phone/${phone}`);
      this.isLoading = false;

      if (response && response.data && response.data.data) {
        return response.data.data;
      } else {
        return null;
      }
    } catch (error) {
      this.isLoading = false;
      return null;
    }
  }

  async checkMobileAvailable() {
    try {
      let self = this.toJS();
      let info = self.info;
      let obj = await this.getMobileAvailable(info.phone);
      if (obj) {
        throw new Error('มีเบอร์โทรศัพท์นี้อยู่ในระบบแล้ว');
      }
    } catch (err) {
      throw err;
    }
  }

  async uploadImage(imageFile, options) {
    if (this.isRegistering) return;

    let imageFormData = new FormData();
    imageFormData.append('profile_pic', imageFile);

    this.isRegistering = true;

    try {
      const profilePic = await http.postMultipartFormData(`${config.api.sims}/v1/contractors/upload/picture-profile`, imageFormData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
        },
      });

      // individual contractor and has signature
      const signature =
        !this.info.isJuristic && this.info.signature && options && !options.isUpdate
          ? await http.post(`${config.api.sims}/v1/contractors/upload/signature`, {
            signature: this.info.signature,
            first_name: this.info.firstName,
            last_name: this.info.lastName,
          })
          : '';

      const response = await Promise.all([profilePic, signature]);
      this.isRegistering = false;
      const resultProfile = response[0] && response[0].data && response[0].data.result;
      this.info.profilePic = resultProfile ? resultProfile.url : '';
      this.info.profilePicInfo = resultProfile ? { path: resultProfile.path, file: resultProfile.file } : {};
      this.info.signature = response[1] && response[1].data && response[1].data.result ? response[1].data.result.url : this.info.signature || '';

      let contractorId;
      if (options && options.isUpdate) {
        let contractor = AuthStore.getContractor();
        if (contractor) {
          contractorId = contractor._id;
          await this.updateContractorInfo(contractorId, 'image');
        } else {
          throw new Error('ไม่พบข้อมูล');
        }
      } else {
        contractorId = await this.submitRegisterContractor();
      }

      return contractorId;
    } catch (err) {
      this.isRegistering = false;
      throw err;
    }
  }

  async uploadImageOnly(file, type) {
    const imageFormData = new FormData();
    let path = 'upload/file';

    if (type === 'profile_pic') {
      path = 'upload/picture-profile';
      imageFormData.append('profile_pic', file);
    } else if (type === 'signature') {
      path = 'upload/signature';
      imageFormData.append('signature', file);
    } else {
      imageFormData.append('files', file);
    }

    try {
      const imageUpload = await Axios.post(`${config.api.sims}/v1/contractors/${path}`, imageFormData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
        },
      });

      if (type === 'profile_pic') {
        return imageUpload.data.result;
      } else if (type === 'signature') {
        return imageUpload.data.result;
      } else {
        return imageUpload.data.data[0];
      }
    } catch (err) {
      throw err;
    }
  }

  async submitRegisterContractor() {
    if (this.isRegistering) return;
    this.isRegistering = true;

    try {
      let contractor = this.toJS().info;
      const bankAccountNo = contractor.bankAccountNo.replace(/-/g, '');
      let options = {
        first_name: contractor.firstName,
        last_name: contractor.lastName,
        team_name: contractor.isJuristic ? contractor.teamName : `${contractor.firstName} ${contractor.lastName}`,
        phone: contractor.phone,
        email: contractor.email,
        type_of_work: contractor.typeOfWork,
        type_of_work_approved: contractor.typeOfWorkApproved,
        area_of_work: contractor.areaOfWork,
        area_of_work_approved: contractor.areaOfWorkApproved,
        // profile_pic_info: contractor.profilePicInfo,
        times_of_work: contractor.timesOfWork,
        installation_per_day: contractor.installationPerDay,
        survey_per_day: contractor.surveyPerDay,
        other_work: contractor.otherWork,
        permissions: [],
        pin: contractor.pin,
        tax: {
          company_name: contractor.isJuristic ? contractor.teamName : `${contractor.firstName} ${contractor.lastName}`,
          tax_no: contractor.taxNo,
          phone: contractor.phone,
          branch_no: contractor.taxBranchNo,
          address: {
            province: {
              code: contractor.taxProvinceCode,
              name: contractor.taxProvinceName,
            },
            district: {
              code: contractor.taxDistrictCode,
              name: contractor.taxDistrictName,
            },
            sub_district: {
              code: contractor.taxSubDistrictCode,
              name: contractor.taxSubDistrictName,
            },
            zip_code: contractor.taxZipCode,
            address: contractor.taxAddress,
          },
        },
        is_juristic: contractor.isJuristic,
        bank_code: contractor.bankCode,
        bank_account_no: bankAccountNo,
        bank_account_name: contractor.bankAccountName,
        calendar_type: contractor.calendarType,
        signature: contractor.signature,
        file: contractor.file,
        netsuite_customer_code: contractor.netsuiteCustomerCode,
        netsuite_internal_id: contractor.netsuiteInternalId,
        car_id: contractor.carId,
        status: contractor.status || '',
      };

      let response = await http.post(`${config.api.sims}/v1/contractors`, options);

      if (!http.isValidStatus(response.status)) {
        throw new Error(response.data.message);
      }

      if (response && response.data && response.data.data) {
        AuthStore.setContractor(response.data.data);
      }

      this.isRegistering = false;

      return response.data.data._id;
    } catch (err) {
      this.isRegistering = false;
      throw err;
    }
  }

  async updateNetsuiteData({ contractorId, code, internalId }) {
    if (this.isSumitting) return;
    this.isSumitting = true;

    try {
      let response = await http.put(`${config.api.sims}/v1/contractors/${contractorId}/netsuiteCustomerCode`, { code, internal_id: internalId });

      if (!http.isValidStatus(response.status)) {
        throw new Error(response.data.message);
      }

      this.isSumitting = false;
    } catch (err) {
      this.isSumitting = false;
      throw err;
    }
  }

  async updateContractorInfo(id, updateType) {
    if (this.isSumitting) return;
    this.isSumitting = true;

    try {
      let contractor = this.toJS().info;
      const bankAccountNo = contractor.bankAccountNo.replace(/-/g, '');

      const hasUpdated = updateType !== 'areaOfWork';
      let response;
      if (updateType === 'typeOfWork') {
        response = await http.put(`${config.api.sims}/v1/contractors/${id}`, {
          has_updated: hasUpdated,
          type_of_work: contractor.typeOfWork,
          type_of_work_approved: contractor.typeOfWorkApproved,
          update_type: _.snakeCase(updateType),
          create_from: 'sims-web',
          user: AuthStore.getContractor().team_name || AuthStore.getUserEmail(),
        });
      } else if (updateType === 'typeOfWorkApproved') {
        response = await http.put(`${config.api.sims}/v1/contractors/${id}`, {
          has_updated: hasUpdated,
          type_of_work: contractor.typeOfWork,
          type_of_work_approved: contractor.typeOfWorkApproved,
          update_type: _.snakeCase(updateType),
          create_from: 'sims-web',
          user: AuthStore.getUserEmail(),
        });
      } else if (updateType === 'areaOfWork') {
        response = await http.put(`${config.api.sims}/v1/contractors/${id}`, {
          has_updated: hasUpdated,
          area_of_work: contractor.areaOfWork,
          area_of_work_approved: contractor.areaOfWorkApproved,
          update_type: _.snakeCase(updateType),
          create_from: 'sims-web',
          user: AuthStore.getContractor().team_name || AuthStore.getUserEmail(),
        });
      } else if (updateType === 'areaOfWorkApproved') {
        response = await http.put(`${config.api.sims}/v1/contractors/${id}`, {
          has_updated: hasUpdated,
          area_of_work: contractor.areaOfWork,
          area_of_work_approved: contractor.areaOfWorkApproved,
          update_type: _.snakeCase(updateType),
          create_from: 'sims-web',
          user: AuthStore.getUserEmail(),
        });
      } else {
        response = await http.put(`${config.api.sims}/v1/contractors/${id}`, {
          // ctr_code: contractor.ctrCode,
          first_name: contractor.firstName,
          last_name: contractor.lastName,
          team_name: contractor.teamName,
          phone: contractor.phone,
          email: contractor.email,
          blocked_payment: contractor.blockedPayment,
          is_permitted: contractor.isPermitted,
          is_permitted_restart_date: contractor.isPermittedRestartDate,
          type_of_work: contractor.typeOfWork,
          type_of_work_approved: contractor.typeOfWorkApproved,
          area_of_work: contractor.areaOfWork,
          area_of_work_approved: contractor.areaOfWorkApproved,
          profile_pic: contractor.profilePic,
          // profile_pic_info: contractor.profilePicInfo,
          times_of_work: contractor.timesOfWork,
          installation_per_day: contractor.installationPerDay,
          survey_per_day: contractor.surveyPerDay,
          other_work: contractor.otherWork,
          permissions: contractor.permissions,
          tax: {
            company_name: contractor.teamName,
            tax_no: contractor.isJuristic ? contractor.taxNo : contractor.idCardNo,
            phone: contractor.taxPhone || contractor.phone,
            branch_no: contractor.taxBranchNo,
            address: {
              province: {
                code: contractor.taxProvinceCode,
                name: contractor.taxProvinceName,
              },
              district: {
                code: contractor.taxDistrictCode,
                name: contractor.taxDistrictName,
              },
              sub_district: {
                code: contractor.taxSubDistrictCode,
                name: contractor.taxSubDistrictName,
              },
              zip_code: contractor.taxZipCode,
              address: contractor.taxAddress,
            },
          },
          is_juristic: contractor.isJuristic,
          bank_code: contractor.bankCode,
          bank_account_no: bankAccountNo,
          bank_account_name: contractor.bankAccountName,
          calendar_type: contractor.calendarType,
          grade: contractor.grade,
          // grade: contractor.grade,
          signature: contractor.signature,
          file: contractor.file,
          netsuite_customer_code: contractor.netsuiteCustomerCode,
          netsuite_internal_id: contractor.netsuiteInternalId,
          update_type: updateType,
          create_from: 'sims-web',
          has_updated: hasUpdated,
          status: contractor.status || '',
          date_of_birth: contractor.dateOfBirth,
          date_of_birth_flag: contractor.dateOfBirthFlag,

          // add new field
          id_card_no: _.get(contractor, 'idCardNo', ''),
          prefix: _.get(contractor, 'prefix', ''),
          nationality: _.get(contractor, 'nationality', ''),
          contact_phone_no: _.get(contractor, 'contactPhoneNo', ''),
          car_id: _.get(contractor, 'carId', ''),
          car_id_province: _.get(contractor, 'carIdProvince', { code: '', name: '' }),
          criminal_record_check_status: _.get(contractor, 'criminalRecordCheckStatus', ''),
          is_shipping_same_address: _.get(contractor, 'isShippingSameAddress', false),
          shipping_address: {
            province: {
              code: _.get(contractor, 'shippingAddress.province.code', ''),
              name: _.get(contractor, 'shippingAddress.province.name', ''),
            },
            district: {
              code: _.get(contractor, 'shippingAddress.district.code', ''),
              name: _.get(contractor, 'shippingAddress.district.name', ''),
            },
            sub_district: {
              code: _.get(contractor, 'shippingAddress.subDistrict.code', ''),
              name: _.get(contractor, 'shippingAddress.subDistrict.name', ''),
            },
            zip_code: _.get(contractor, 'shippingAddress.zipCode', ''),
            address: _.get(contractor, 'shippingAddress.address', ''),
          },
          shirt_size: contractor.shirtSize,
          is_profile_pic_approved: _.get(contractor, 'isApproveProfilePic', false),
          user: AuthStore.getUserEmail(),
        });
      }

      if (!http.isValidStatus(response.status)) {
        throw new Error(response.data.message);
      }

      if (response && response.data && response.data.data) {
        const contractorData = response.data.data;
        AuthStore.setContractor(contractorData);
      }

      this.isSumitting = false;

      return response.data.data._id;
    } catch (err) {
      this.isSumitting = false;
      if (err.message === 'PHONE_NUMBER_IS_EXSIST') {
        throw new Error('มีเบอร์โทรศัพท์นี้อยู่ในระบบแล้ว');
      } else {
        throw err;
      }
    }
  }

  async approveProfilePic(id, data) {
    try {
      const response = await http.put(`${config.api.sims}/v1/contractors/${id}`, {
        ...data,
        user: AuthStore.getUsernameOrEmail(),
        create_from: 'sims-web'
      });

      if (!http.isValidStatus(response.status)) {
        throw new Error(response.data.message);
      }

      if (response && response.data && response.data.data) {
        const contractorData = response.data.data;
        AuthStore.setContractor(contractorData);
      }
    } catch (error) {
      throw new Error('เกิดข้อผิดพลาด');
    }
  }

  async updateDraft(id, status, reason){
    try {
      const response = await http.put(`${config.api.sims}/v1/contractors/${id}/draft`, {
        "updated_by": AuthStore.getUsernameOrEmail(),
        "draft_status": status,
        "reason": reason 
      });

      if (!http.isValidStatus(response.status)) {
        throw new Error(response.data.message);
      }

    } catch (error) {
      throw new Error('เกิดข้อผิดพลาด');
    }
  }

  async changeGrade(item, grade, reason, user) {
    const id = item._id;
    if (this.isSumitting) return;
    this.isSumitting = true;

    try {
      const data = {
        old_grade: item.grade,
        new_grade: grade,
        change_grade_reason: reason,
        type_of_work: item.type_of_work,
        created_by: user,
      };

      let response = await http.put(`${config.api.sims}/v1/contractors/${id}/grade`, data);
      if (!http.isValidStatus(response.status)) {
        throw new Error(response.data.message);
      }
      this.isSumitting = false;
    } catch (err) {
      this.isSumitting = false;
      throw err;
    }
  }

  async changePin(id, pin, newPin) {
    if (this.isSumitting) return;
    this.isSumitting = true;

    try {
      let response = await http.put(`${config.api.sims}/v1/contractors/${id}/pin`, {
        pin: pin,
        new_pin: newPin,
      });

      if (!http.isValidStatus(response.status)) {
        throw new Error(response.data.message);
      }

      this.isSumitting = false;
    } catch (err) {
      this.isSumitting = false;
      if (err.message === 'INVALID_PIN_FORMAT') {
        throw new Error('รูปแบบรหัสผ่านไม่ถูกต้อง');
      } else if (err.message === 'INVALID_PIN') {
        throw new Error('รหัสผ่านเก่าไม่ถูกต้อง');
      } else {
        throw err;
      }
    }
  }

  async approveOrReject(id, isApproved, remark) {
    if (this.isSumitting) return;
    this.isSumitting = true;
    try {
      let response = await http.put(`${config.api.sims}/v1/contractors/${id}/approve`, {
        is_approved: isApproved,
        remark: isApproved ? null : remark,
      });

      if (!http.isValidStatus(response.status)) {
        throw new Error(response.data.message);
      }

      this.isSumitting = false;
      return response.data;
    } catch (err) {
      this.isSumitting = false;
      throw err;
    }
  }

  async delete(id) {
    if (this.isSumitting) return;
    this.isSumitting = true;
    try {
      let response = await http.delete(`${config.api.sims}/v1/contractors/${id}`);

      if (!http.isValidStatus(response.status)) {
        throw new Error(response.data.message);
      }

      this.isSumitting = false;
      return response.data;
    } catch (err) {
      this.isSumitting = false;
      throw err;
    }
  }

  async getVacation(options) {
    if (this.isVacationLoading) return;

    if (options && options.contractorId) {
      try {
        this.isVacationLoading = true;

        let { contractorId, vacationId, jobType, startDate, endDate } = options;
        let url = `${config.api.sims}/v1/contractors/${contractorId}/vacations${vacationId ? `/${vacationId}` : ''}`;

        let checkParam = () => {
          if (url.indexOf('?') >= 0) {
            return `&`;
          } else {
            return `?`;
          }
        };

        if (jobType) {
          url += `${checkParam()}type_of_job=${jobType}`;
        }
        if (startDate) {
          url += `${checkParam()}start_date=${datetime.SendToApiWithTime(startDate)}`;
        }
        if (endDate) {
          url += `${checkParam()}end_date=${datetime.SendToApiWithTime(endDate)}`;
        }

        let response = await http.get(url);
        this.isVacationLoading = false;
        if (response && response.data) {
          return response.data.data;
        } else {
          return [];
        }
      } catch (error) {
        this.isVacationLoading = false;
        throw error;
      }
    }
  }

  async getJob(options) {
    if (options && options.contractorId) {
      try {
        let { contractorId, jobType, startDate, endDate } = options;
        let url = `${config.api.sims}/v1/contractors/${contractorId}/jobs?`;

        if (jobType) {
          url += `job_type=${jobType}`;
        }
        if (startDate) {
          url += `&start_date=${datetime.SendToApiWithTime(startDate)}`;
        }
        if (endDate) {
          url += `&end_date=${datetime.SendToApiWithTime(endDate)}`;
        }

        let response = await http.get(url);
        if (response && response.data && Array.isArray(response.data.data)) {
          return response.data.data;
        } else {
          return [];
        }
      } catch (error) {
        this.isVacationLoading = false;
        throw error;
      }
    }
  }

  async validateTeamName(id) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const info = this.toJS().info;
      let url = `${config.api.sims}/v1/contractors/validate`;
      if (id) {
        url += `?id=${id}`;
      }

      const response = await http.post(url, {
        fields: {
          team_name: info.teamName,
        },
      });

      if (!(response && response.data) || response.status !== 200) {
        throw new Error('ชื่อทีมนี้มีในระบบแล้ว กรุณาเปลี่ยนชื่อทีม');
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async setContractorCost(value, id, storeId) {
    try {
      const cost = value.map((c) => {
        return {
          _id: c._id,
          code: c.code,
          name: c.name,
          type_of_work: c.type_of_work,
          cost_type: c.cost_type,
          wage_with_price: c.wage_with_price,
          wage: c.wage,
          survey_cost: c.survey_cost,
        };
      });
      if (cost && cost.length) {
        let response = await http.put(`${config.api.sims}/v1/contractors/${id}/cost`, {
          cost,
          storeId,
        });
        if (response.status !== 200) {
          return { message: 'error' };
        }
        return { message: 'success' };
      }
    } catch (error) {
      throw error;
    }
  }

  async saveContractorWorkCalendar(options) {
    try {
      let body = {
        ...options,
        endDate: datetime.SendToApiWithTime(options.endDate),
        startDate: datetime.SendToApiWithTime(options.startDate),
        updateDate: datetime.SendToApiWithTime(new Date()),
        create_from: 'sims-web',
      };
      const response = await http.post(`${config.api.sims}/v1/workcalendars`, {
        ...body,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateContractorWorkCalendar(options) {
    try {
      let body = {
        ...options,
        endDate: datetime.SendToApiWithTime(options.endDate),
        startDate: datetime.SendToApiWithTime(options.startDate),
        updateDate: datetime.SendToApiWithTime(new Date()),
        info: this.workCalendarInfo.toJS(),
        create_from: 'sims-web',
      };
      const response = await http.put(`${config.api.sims}/v1/workcalendars`, {
        ...body,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateContractorWorkCalendarByDate(options) {
    try {
      const body = options;
      const response = await http.put(`${config.api.sims}/v1/workcalendars/updateDate`, {
        ...body,
        create_from: 'sims-web',
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getContractorWorkCalendar(options) {
    try {
      const workCalendar = await http.get(`${config.api.sims}/v1/workcalendars?contractor_id=${options.contractorId}`);
      let surveyPerDay = options.contractorInfo.survey_per_day || {
        morning: 1,
        afternoon: 1,
      };
      let installationPerDay = options.contractorInfo.installation_per_day || {
        morning: 1,
        afternoon: 1,
      };
      if (workCalendar && workCalendar.data && workCalendar.data.data) {
        let startDate = options.startDate.startOf('days');
        let endDate = options.endDate.endOf('days');
        const date = workCalendar.data.data.filter((w) => {
          const validateStartDate = datetime.isBetween(w.start_date, startDate, endDate);
          const validateEndDate = datetime.isBetween(w.end_date, startDate, endDate);
          return validateStartDate || validateEndDate;
        });

        if (date && date.length) {
          surveyPerDay = date[0].survey_per_day || { morning: 1, afternoon: 1 };
          installationPerDay = date[0].installation_per_day || {
            morning: 1,
            afternoon: 1,
          };
          this.workCalendarInfo = date;
        }
        this.jobPerDay = options.jobType === 'S' ? surveyPerDay : installationPerDay;
      }
      return {
        workCalendar,
        jobPerDay: this.jobPerDay,
        surveyPerDay,
        installationPerDay,
      };
    } catch (error) {
      throw error;
    }
  }

  async getContractorWorkCalendarByDate(options) {
    try {
      const workCalendar = await http.get(
        `${config.api.sims}/v1/workcalendars/date?contractor_id=${options.contractorId}&start_date=${options.startDate}&end_date=${options.endDate}`,
      );
      return workCalendar;
    } catch (error) {
      throw error;
    }
  }

  validateWorkCalendarEvent(options) {
    let startDate = options.startDate;
    let endDate = options.endDate;
    const workCalendarLength = this.workCalendarInfo.toJS().length;
    const dateLength = datetime.GetBetweenDates(startDate, endDate).length;
    if (workCalendarLength === dateLength || workCalendarLength === 0) {
      return true;
    }
    return false;
  }

  async uploadContractorFile(file, contractorId) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const formData = new FormData();
      for (let i = 0; i < file.length; i++) {
        formData.append(file[i].name, file[i]);
      }
      const idPath = contractorId ? `/${contractorId}` : '';
      const response = await http.postMultipartFormData(`${config.api.sims}/v1/contractors${idPath}/upload/file`, formData);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }

      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async toggleSkuCost(index, id, storeId, adjustable) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.put(`${config.api.sims}/v1/contractors/${id}/adjustcost`, { adjustable, storeId });
      if (res.status === 200 && res.data) {
        this.info[index] = res.data.data;
      } else {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async vaccineApproove(id, isApproove) {
    try {
      const body = { id, is_approved: isApproove };
      const url = `${config.api.sims}/v1/contractors/vaccine-approove`;
      const res = await Axios.post(url, body);
      if (res.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
      // Swal.fire(
      //   'บันทึกสำเร็จ!',
      //   '',
      //   'success'
      // )
    } catch (error) {
      console.log('vaccineApproove error =====>', error.message);
      throw new Error('การบันทึกล้มเหลว');
    }
  }

  async vaccineHistory(body) {
    try {
      const url = `${config.api.sims}/v1/vaccines/insertVaccineHistory`;
      const res = await Axios.post(url, body);
      if (res.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      console.log('vaccineApproove error =====>', error.message);
      throw new Error('การบันทึกล้มเหลว');
    }
  }

  /**
   * @deprecated This function is deprecated and should not be used anymore.
   * Please use `updateGrades()` instead.
   */
  async updateContractorGradeByFile(body) {
    this.isLoading = true;

    try {
      const url = `${config.api.sims}/v1/contractors/update-grade`;
      const res = await Axios.post(url, body);

      if (res.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }

      return res;
    } catch (error) {
      throw new Error('การบันทึกล้มเหลว');
    } finally {
      this.isLoading = false;
    }
  }

  updateGrades = async ({ updateMethod, rows, updatedBy, filePath, fileName, fileDescription }) => {
    try {
      this.isLoading = true;

      const url = `${config.api.sims}/v1/contractors/update-grades`;
      const { status, data } = await Axios.post(url, { updateMethod, rows, updatedBy, filePath, fileName, fileDescription });

      if (status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }

      return data;
    } catch {
      throw new Error('การบันทึกล้มเหลว');
    } finally {
      this.isLoading = false;
    }
  };

  async getContractorHistoryGrade(contractorId) {
    try {
      const url = `${config.api.sims}/v1/contractors/${contractorId}/getContractorHistoryGrade`;
      const response = await Axios.get(url);
      if (response.status !== 200 && !response.data.data) {
        throw new Error('เกิดข้อผิดพลาด');
      }
      return response.data.data;
    } catch (error) {
      throw new Error('เกิดข้อผิดพลาด');
    }
  }

  async getDataFromBuddyApp(contractorId, provinces) {
    try {
      const getPrefix = await Axios.get(`${config.api.training}/prefix`);
      if (getPrefix.status !== 200 && !getPrefix.data) {
        throw new Error('เกิดข้อผิดพลาด');
      }
      this.options.prefix = getPrefix.data.map((item) => {
        return { value: item.prefix_name, label: item.prefix_name };
      });

      // const getContractor = await Axios.get(`${config.api.training}/detail?ctr_mapping_uuid=${contractorId}`);
      // if (getContractor && getContractor.data && getContractor.data.car_license) {
      //   const carLicense = _.get(getContractor.data, 'car_license', null);
      //   if (carLicense) {
      //     this.info.carId = carLicense.car_no;

      //     // map province code to province name from buddy app
      //     const province = provinces.find((province) => province.code === carLicense.province_code);
      //     if (province) {
      //       this.info.carIdProvince = { code: province.code, name: province.name };
      //     } else {
      //       this.info.carIdProvince = { code: carLicense.province_code, name: '' };
      //     }
      //   }
      // }
    } catch (error) {
      throw error;
    }
  }

  defaultShippingAddress(contractor) {
    // default shipping address from contractor
    if (contractor.shipping_address) {
      return {
        is_shipping_same_address: _.get(contractor, 'is_shipping_same_address', false),
        shipping_address: {
          province: {
            code: _.get(contractor, 'shipping_address.province.code', ''),
            name: _.get(contractor, 'shipping_address.province.name', ''),
          },
          district: {
            code: _.get(contractor, 'shipping_address.district.code', ''),
            name: _.get(contractor, 'shipping_address.district.name', ''),
          },
          sub_district: {
            code: _.get(contractor, 'shipping_address.sub_district.code', ''),
            name: _.get(contractor, 'shipping_address.sub_district.name', ''),
          },
          zip_code: _.get(contractor, 'shipping_address.zip_code', ''),
          address: _.get(contractor, 'shipping_address.address', ''),
        },
      }
    }

    // default shipping address from tax address
    return {
      is_shipping_same_address: _.get(contractor, 'is_shipping_same_address', true),
      shipping_address: {
        province: {
          code: _.get(contractor, 'tax.address.province.code', ''),
          name: _.get(contractor, 'tax.address.province.name', ''),
        },
        district: {
          code: _.get(contractor, 'tax.address.district.code', ''),
          name: _.get(contractor, 'tax.address.district.name', ''),
        },
        sub_district: {
          code: _.get(contractor, 'tax.address.sub_district.code', ''),
          name: _.get(contractor, 'tax.address.sub_district.name', ''),
        },
        zip_code: _.get(contractor, 'tax.address.zip_code', ''),
        address: _.get(contractor, 'tax.address.address', ''),
      },
    }

  }
}

export default new ContractorStore();
