import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import 'react-toggle/style.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-select/dist/react-select.css';
import 'react-quill/dist/quill.snow.css';
import 'react-tagsinput/react-tagsinput.css';
import './assets/css/material-dashboard.css';
import './assets/css/app.css';
import './assets/css/dropzone.css';
import './assets/css/month-picker.css';
import './assets/css/coupon.css';
import './assets/css/input.css';
import './assets/css/set-banner.css';
import './assets/css/autocomplete.css';
import './assets/css/tags-input-custom.css';
import 'react-device-frameset/lib/css/marvel-devices.min.css';
import './assets/css/search-bar.css';
import './assets/css/checkbox.css';
import './pages/benefit/style.css';

import App from './App';
import mobxStore from './stores/store.js';
import TagManager from 'react-gtm-module';
import config from './config';

const tagManagerArgs = {
  gtmId: config.gtm.id,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render((
  <Provider {...mobxStore}>
    <App />
  </Provider>
), document.getElementById('root'));
