import humps from 'humps';
import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';
import { datetime } from '../utils/datetime';
import moment from 'moment';
import qs from 'query-string';
import { set, get } from 'lodash';

export class BroadcastStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      broadcasts: [],
      query: {},
      pagination: {},
    });
  }

  initialBroadcastsValue() {
    return {};
  }

  initialQueryValue() {
    return {};
  }

  async getAllBroadcasts(filter) {}

  async getOneBroadcast(id) {}

  async createBroadcast(body) {}

  async updateOneBroadcast(id, body) {}

  async deleteOneBroadcast(id) {}

  async broadcastSearch(query) {
    try {
      const response = await http.get(`${config.api.sims}/v1/broadcast/search`, {
        params: query,
      });

      if (response && response.data && response.data.list) {
        return response.data.list
      }

      return [];
    } catch (error) {
      throw error
    }
  }
}

export default new BroadcastStore();
