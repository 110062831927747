import React from 'react';
import Toggle from 'react-toggle';

const CustomToggle = ({ checked, label, disabled, noPadding, onClick, onChange }) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <div
      className={`en-toggle ${noPadding ? '' : 'p4-toggle'}`}
      style={{ display: 'flex', gap: 10 }}
    >
      {label && <span>{label}</span>}
      <Toggle
        checked={checked}
        disabled={disabled}
        onClick={onClick}
        onChange={handleChange}
      />
    </div>
  );
};

export default CustomToggle;
