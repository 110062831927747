import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import EnButton from '../form/EnButton';
import EnTextFormGroup from '../form/EnTextFormGroup';
import styled from 'styled-components';
import Loader from '../common/Loader';
import QuotationStore from '../../stores/QuotationStore';
import { uniqBy, uniq } from 'lodash';

const CustomModal = styled(Modal)`
  .modal-dialog {
    /* width: 60%; */
    width: 50vw;
    height: calc(100vh - 70px);
  }

  .modal-content {
    background-color: #f9f9f9;
    padding: 20px;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
`;

const ScrollableContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 7px;
  max-height: 300px; /* Set the desired height */
  overflow-y: auto; /* Enable vertical scroll */
`;

const StyledTable = styled(Table)`
  thead th {
    position: sticky;
    top: 0;
    background-color: white; /* Set background color to match the table */
    z-index: 1;
  }
`;

const StyledHr = styled.hr`
    margin: 0;
    padding: 0;
`

const eachFetch = 20;

const BenefitQuotation = ({ 
  show, 
  onClose, 
  onSelected, 
  selectedBenefit, 
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const observerRef = useRef();

  const [total, setTotal] = useState(eachFetch);
  const [limit, setLimit] = useState(eachFetch);
  const [offset, setOffset] = useState(0);

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
  const [selectedRows, setSelectedRows] = useState([]);
  
  const handleCheckboxChange = (id) => {
    setSelectedRows((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]));
  };

  const handleClose = () => {
    setSearchValue('');
    onClose();
  }

  const onConfirmBenefits = () => {
    const selectedData = data.filter((item) => selectedRows.includes(item.id));
    onSelected(selectedData);
    handleClose();
  }

  const fetchBenefits = async (search = '', newOffset = 0) => {
    try {
      setLoading(true);
      const result = await QuotationStore.getBenefitQuotation(search, newOffset, limit);
      const total = result.total;
      if(result && result.benefit_list && result.benefit_list.length >= 0){
        const newData = result.benefit_list.map((item) => ({
          id: item._id,
          code: item.code,
          name: item.name,
          description: item.benefit_details,
        }))
        // console.log(newData);
        
        if(selectedBenefit.length > 0){
          setData((prevData) => uniqBy([...selectedBenefit, ...prevData, ...newData], 'id'));
        } else {
          setData((prevData) => uniqBy([...prevData, ...newData], 'id'));
        }

        setTotal(total);
        setOffset((prevOffset) => prevOffset + eachFetch);
      }

    } catch (error) {
      // this.noti.error('โหลดข้อมูลไม่สําเร็จ');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && data.length < total) {
          fetchBenefits(debouncedSearchValue, offset);
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [loading, data.length, total, debouncedSearchValue, offset],
  );

  const isDisableConfirm = useMemo(() => {
    return selectedRows.length === 0;
  }, [selectedRows]);

  const isNotFound = useMemo(() => {

    const selectedData = data.filter((item) => selectedRows.includes(item.id)) || [];
    const findSelectedSearched = selectedData.find((item) => item.code.includes(debouncedSearchValue)  || item.name.includes(debouncedSearchValue));
    if(findSelectedSearched){
      return false;
    }

    return data.length === 0 || data.length === selectedRows.length;
  }, [data]);

  // Debounce the search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 1000); 
    return () => clearTimeout(handler);
  }, [searchValue]);

  useEffect(() => {

    if(selectedRows.length > 0){
      const selectedData = data.filter((item) => selectedRows.includes(item.id)) || [];
      setTotal(0);
      setData(selectedData);
    } else {
      setData([]);
    }
    
    setOffset(0);
    fetchBenefits(debouncedSearchValue, 0);
  }, [debouncedSearchValue]);

  useEffect(() => {
    //set initial selected rows
    if(selectedBenefit.length > 0){
      const selectedIds = selectedBenefit.map(item => item.id);
      setSelectedRows(uniq([...selectedIds]));
      setData((prevData) => uniqBy([...selectedBenefit, ...prevData], 'id'));
    }
  }, [selectedBenefit]);

  // useEffect(() => {
  //   fetchBenefits();
  // }, []);
  
  return (
    <>
      <Loader show={loading} />
      <CustomModal
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
        allowFullScreen
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 style={{ margin: 0 }}>สิทธิประโยชน์</h5>
            <p style={{ color: '#ccc', margin: 0, fontSize: '2rem' }}>กรุณาเลือกสิทธิประโยชน์ที่ต้องการ</p>
          </Modal.Title>
        </Modal.Header>
        <StyledHr/>
        {/* <h3>{data.length}</h3> */}
        <Modal.Body>
          <div>
            <div>
              <EnTextFormGroup
                // style={{ minWidth: '250px' }}
                style={{ width: '40%', marginLeft: 'auto' }}
                id="address"
                // containerClassName="col-md-6"
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="ค้นหาด้วยรหัส หรือ สิทธิประโยชน์"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ScrollableContainer>
                <StyledTable>
                  <thead>
                    <tr>
                      <th width="5%">
                        {/* <input
                          type="checkbox"
                          onChange={(e) => setSelectedRows(e.target.checked ? data.map((item) => item.id) : [])}
                          checked={selectedRows.length === data.length}
                        /> */}
                      </th>
                      <th width="5%">รหัส</th>
                      <th width="5%">ชื่อสิทธิประโยชน์</th>
                      <th width="20%">เนื้อหาสิทธิประโยชน์</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      isNotFound ?
                      (
                        <tr>
                          <td colSpan="4">
                            <div className="d-flex w-100 justify-content-center justify-items-center" style={{ height: '100%' }}>
                              <div className="text-center">
                                <img className="additional-products-page__empty-img" src={require('../../assets/img/empty-search.svg')} />
                                <div style={{ fontSize: '24px', marginTop: '16px', color: '#014672' }}>ไม่พบข้อมูลที่ค้นหา</div>
                                <div style={{ color: '#838799' }}>กรุณาลองใหม่อีกครั้ง</div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                      :
                      (
                        <>
                          {data.map((item, index) => (
                              <tr key={item.id} ref={index === data.length - 1 ? lastElementRef : null}>
                                <td>
                                  <input style={{ marginLeft: '2rem'}} type="checkbox" checked={selectedRows.includes(item.id)} onChange={() => handleCheckboxChange(item.id)} />
                                </td>
                                <td>{item.code}</td>
                                <td>{item.name}</td>
                                <td>{item.description}</td>
                              </tr>
                            ))
                          }
                        </>
                      )
                    }
                  </tbody>
                </StyledTable>
              </ScrollableContainer>
            </div>
          </div>
        </Modal.Body>
        <StyledHr/>
        <Modal.Footer>
          <EnButton className="btn-danger" onClick={handleClose}>
            ยกเลิก
          </EnButton>
          <EnButton className="btn-success" onClick={onConfirmBenefits} disabled={isDisableConfirm}>ยืนยัน</EnButton>
        </Modal.Footer>
      </CustomModal>
    </>
  );
};

export default BenefitQuotation;
