// เฉพาะงาน asa
import * as _ from 'lodash';
import { toJS } from 'mobx';
import moment from 'moment';
import momentTz from 'moment-timezone';
import validator from 'validator';
import BaseStore from './BaseStore';
import AuthStore from './AuthStore';
import config from '../config';
import datetime from '../utils/datetime';
import http from '../utils/http';
import { imageExtension } from '../utils/data';
import common from '../utils/common';
import swal from 'sweetalert2';

export class JobStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      isSubmitting: false,
      info: this.initJobInfo(),
      onsitePicture: this.initOnsitePicture(),
      orderId: undefined,
      trackingSpecialStatus: ['quotation_management', 'installation_finished'],
      filter: {
        minDate: undefined,
        maxDate: undefined,
        minInstDate: undefined,
        maxInstDate: undefined,
      },
      calendarAddress: {
        subDistrict: undefined,
        district: undefined,
        province: undefined,
        postCode: undefined,
        subDistrictCode: undefined,
        districtCode: undefined,
        provinceCode: undefined,
        landmark: undefined,
      },
      data: this.initData(),
    });
  }

  initData() {
    return {
      product: {
        point: '',
        name: '',
        area: '',
        code: '',
      },
    };
  }

  clearData() {
    this.data = this.initData();
  }

  initOnsitePicture() {
    return [
      {
        type: "image",
        file: []
      },
      {
        type: "video",
        file: []
      }
    ];
  }

  initJobInfo() {
    return {
      id: '',
      jobCode: '',
      storeId: '',
      storeName: '',
      contractorId: '',
      contractorTeamName: '',
      startDate: undefined,
      endDate: undefined,
      activity_logs: [],
      firstName: '',
      lastName: '',
      address: '',
      phone: '',
      phone2: '',
      email: '',
      available: 0,
      timeLabel: 'ทั้งวัน',
      typeOfWork: [],
      jobType: '',
      otherWork: '',
      productId: 0,
      productQty: 1,
      description: '',
      contactName: '',
      contactPhone: '',
      deliveryDate: undefined,
      files: [],
      refCode: '',
      saleOrderCode: '',
      startedAt: undefined,
      submitedAt: undefined,
      workingAt: undefined,
      assignedAt: undefined,
      provinceCode: undefined,
      districtCode: undefined,
      subDistrictCode: undefined,
      productSameType: undefined,
      status: '',
      statusHistory: [],
      surveyFiles: [],
      contractorQuotationFiles: [],
      surveyRemark: '',
      installationStartDate: undefined,
      installationEndDate: undefined,
      installationBeforeFiles: [],
      installationBeforeRemark: '',
      installationAfterFiles: [],
      installationAfterRemark: '',
      addressInfo: {
        subDistrict: undefined,
        district: undefined,
        province: undefined,
        postCode: undefined,
        subDistrictCode: undefined,
        districtCode: undefined,
        provinceCode: undefined,
        landmark: undefined,
        requestLocation: undefined,
        location: undefined,
      },
      is_paid: undefined,
      paid_at: undefined,
      remark: '',
      installationPrice: undefined,
      quotationFiles: [],
      receiptFiles: [],
      shareStore: [],
      jobRemark: '',
      adminRemark: '',
      sku: '',
      cost: undefined,
      po_survey: undefined,
      po_installation: undefined,
      contractorReceipt: undefined,
      creditSale: undefined,
      requestTax: undefined,
      customerTax: undefined,
      invoice: undefined,
      quotation: [],
      auto_assign_contractor: undefined,
      read_history: [],
      assigned_at: undefined,
      emp_id: '',
      requestLocation: false,
      asa_data: {
        contractor_team_name: undefined,
        emp_id: undefined,
        sku_price: undefined,
        request_location: undefined,
        location: {
          site_latitude: undefined,
          site_longitude: undefined,
        },
      },
      customer_approved: undefined,
      customer_approved_date: undefined,
      review_noti_count: undefined,
      guarantee: undefined,
      surveyCheck: {
        submit: undefined,
        remark: undefined,
        installationDays: undefined,
        products: [],
      },
      invoice_commision: undefined,
      contractorAccept: undefined,
      channel: undefined,
      admin_approve_paid: undefined,
      subsidizePromotion: undefined,
      payments: undefined,
      saleModel: undefined,
      additionalCharge: [],
      claim: undefined,
      refJob: undefined,
      cartOrderNo: undefined,
      creditProvider: undefined,
      reasonNoQuotation: '',
      contactEmails: [{ key: 1, value: '' }],
      installationFromSurvey: undefined,
      surveyContractor: undefined,
      changeContractor: {},
      submitedBy: 'admin',
      changeCustomerRemark: {},
      otherExpense: {},
      nps: {},
      contractorTeam: {
        mainContractor: '',
        subContractor: []
      }
    };
  }

  deleteProduct(index) {
    this.info.surveyCheck.products.splice(index, 1);
  }

  addProduct() {
    let result = {};
    if (
      this.data.product.point &&
      this.data.product.name &&
      this.data.product.area
    ) {
      // console.log(this.data.surveyText);
      // console.log(this.info.surveys);
      this.info.surveyCheck.products.push(this.data.product);
      result.status = true;
    } else {
      result.message = 'รายละเอียดสินค้า';
      result.status = false;
    }
    return result;
  }

  setOnsite(key, value) {
    if (key === 'image') {
      this.onsitePicture[0].file = value;
    } else {
      this.onsitePicture[1].file = value;
    }
  }

  setInfo(key, value) {
    if (key === 'typeOfWork') {
      const val = +value;
      if (val) {
        this.info.otherWork = '';
      }
      this.info[key] = [val];
    } else {
      this.info[key] = value;
    }
  }

  addContractEmails() {
    let newKey = this.info.contactEmails.length + 1;
    this.info.contactEmails.push({ key: newKey, value: '' });
  }

  deleteContractEmails(key) {
    let mails = this.info.contactEmails;
    const index = _.findIndex(mails, function (m) {
      return m.key == key;
    });
    mails.splice(index, 1);
    this.info.contactEmails = mails;
  }

  setContractEmail(index, value) {
    this.info.contactEmails[index].value = value;
  }

  setSurveyCheck(key, value) {
    this.info.surveyCheck[key] = value;
  }

  setProductData(key, value) {
    this.data.product[key] = value;
  }

  addProducts() {
    let result = {};
    if (this.data.surveyText) {
      // console.log(this.data.surveyText);
      // console.log(this.info.surveys);
      this.info.surveys.push(this.data.surveyText);
      result.status = true;
    } else {
      result.message = this.data.surveyText ? '' : 'หัวข้อที่ต้องการสำรวจ';
      result.status = false;
    }
    return result;
  }

  saveMultiInfo(data) {
    let keys = Object.keys(data);
    const info = this.toJS().info;
    _.forEach(keys, (k) => {
      info[k] = data[k];
    });
    this.info = info;
  }

  saveAddressData({ aname, dname, pname, zname, dcode, acode, pcode }) {
    this.info.addressInfo.subDistrict = dname;
    this.info.addressInfo.district = aname;
    this.info.addressInfo.province = pname;
    this.info.addressInfo.postCode = zname;
    this.info.addressInfo.subDistrictCode = dcode;
    this.info.addressInfo.districtCode = acode;
    this.info.addressInfo.provinceCode = pcode;
  }

  saveCalendarAddressData({ aname, dname, pname, zname, dcode, acode, pcode }) {
    this.calendarAddress.subDistrict = dname;
    this.calendarAddress.district = aname;
    this.calendarAddress.province = pname;
    this.calendarAddress.postCode = zname;
    this.calendarAddress.subDistrictCode = dcode;
    this.calendarAddress.districtCode = acode;
    this.calendarAddress.provinceCode = pcode;
  }

  saveReasonNoQuotation(value) {
    this.info.reasonNoQuotation = value;
  }

  saveCustomerApprovedDate(value) {
    this.info.customer_approved_date = value;
  }

  saveContractorTeamName(value) {
    this.info.asa_data.contractor_team_name = value;
  }

  saveLandmark(value) {
    this.info.addressInfo.landmark = value;
  }

  setAddressLocation(location) {
    if (location) {
      this.info.asa_data.location = {
        site_latitude: location.lat,
        site_longitude: location.lng,
      };
    }
  }

  setRequestLocation(value) {
    this.info.asa_data.request_location = value;
  }

  clearAddressData() {
    this.info.addressInfo.subDistrict = undefined;
    this.info.addressInfo.district = undefined;
    this.info.addressInfo.province = undefined;
    this.info.addressInfo.postCode = undefined;
    this.info.addressInfo.subDistrictCode = undefined;
    this.info.addressInfo.districtCode = undefined;
    this.info.addressInfo.provinceCode = undefined;
  }

  clearCalendarAddressData() {
    this.calendarAddress.subDistrict = undefined;
    this.calendarAddress.district = undefined;
    this.calendarAddress.province = undefined;
    this.calendarAddress.postCode = undefined;
    this.calendarAddress.subDistrictCode = undefined;
    this.calendarAddress.districtCode = undefined;
    this.calendarAddress.provinceCode = undefined;
  }

  clearInfo() {
    this.info = this.initJobInfo();
  }

  getJobStatus() {
    const info = this.toJS().info;
    if (info) {
      return info.status;
    }
    return '';
  }

  setSubmitLoading(value) {
    this.isSubmitting = value;
  }

  setShareStore = (store) => {
    this.info.shareStore = store;
  };

  validateJobInfo(options) {
    let reqInfo = this.toJS().info;
    let {
      subDistrict,
      subDistrictCode,
      district,
      districtCode,
      province,
      provinceCode,
      postCode,
    } = reqInfo.addressInfo;
    let typeOfWorkSelected = Array.isArray(reqInfo.typeOfWork)
      ? reqInfo.typeOfWork.length
      : 0;
    let isValid =
      reqInfo.startDate &&
      reqInfo.endDate &&
      reqInfo.firstName &&
      reqInfo.lastName &&
      reqInfo.address &&
      reqInfo.phone &&
      reqInfo.phone.length >= 9 &&
      reqInfo.phone.length <= 10 && // /^[0-9]+$/.test(reqInfo.phone)) &&
      typeOfWorkSelected &&
      reqInfo.contactName &&
      reqInfo.contactPhone;
    if (reqInfo.phone2) {
      isValid = reqInfo.phone2.length >= 9 && reqInfo.phone2.length <= 10
    }
    const storeId = AuthStore.getStoreId();
    const isAsa = storeId === config.asa.storeId;
    if (isValid && isAsa) {
      isValid = reqInfo.contractorId && reqInfo.sku;
    }

    if (isValid && options && options.createMode) {
      isValid =
        subDistrict &&
        subDistrictCode &&
        district &&
        districtCode &&
        province &&
        provinceCode &&
        postCode;
    }

    if (isValid && options && options.updateMode) {
      if (
        reqInfo.addressInfo &&
        reqInfo.addressInfo.province &&
        reqInfo.addressInfo.district &&
        reqInfo.addressInfo.subDistrict
      ) {
        isValid =
          subDistrict &&
          subDistrictCode &&
          district &&
          districtCode &&
          province &&
          provinceCode &&
          postCode;
      }
    }

    let otherWork = reqInfo.typeOfWork.find((i) => i === 0);
    if (otherWork === 0 && !reqInfo.otherWork) {
      isValid = false;
    }
    if (reqInfo.productId === 1 && !(reqInfo.productQty >= 0)) {
      isValid = false;
    }
    if (reqInfo.email) {
      const emails = reqInfo.email.split(',');
      if (emails.length) {
        for (let i = 0; i < emails.length; i++) {
          if (!validator.isEmail(emails[i])) {
            isValid = false;
            break;
          }
        }
      }
    }
    return isValid;
  }

  async getWorkCalendar(contractors) {
    let workPerDays = [];
    if (contractors && contractors.length > 0) {
      workPerDays = await Promise.all(
        contractors.map(async (contractor) => {
          const workPerDay = await http.get(
            `${config.api.sims}/v1/workcalendars?contractor_id=${contractor._id}`
          );
          return workPerDay;
        })
      );
    }
    let workCalendar = [];
    if (
      workPerDays &&
      workPerDays.length > 0 &&
      workPerDays[0].data &&
      workPerDays[0].data.data
    ) {
      workPerDays.forEach((w) => {
        workCalendar.push(...w.data.data);
      });
    }
    return workCalendar;
  }

  mappingAvailableContractorByDate(contractors, unavailableDate, jobType) {
    if (!contractors) return [];
    const info = this.toJS().info;
    const startDate = momentTz(info.startDate).utcOffset(7).startOf('day');
    const endDate = momentTz(info.endDate).utcOffset(7).endOf('day');
    const availableContractors = [];
    if (Array.isArray(contractors) && contractors.length) {
      _.forEach(contractors, (contractor) => {
        let vacationDateRange = _.orderBy(contractor.vacation_date || []);
        vacationDateRange = _.groupBy(vacationDateRange);
        const vacationDateRangeKeys = Object.keys(vacationDateRange);
        vacationDateRange = vacationDateRangeKeys.map(
          (key) => vacationDateRange[key]
        );
        const hasVacation = vacationDateRange.find((d) => {
          const cUnavailableDate = d[0];
          if (datetime.isBetween(cUnavailableDate, startDate, endDate)) {
            if (info.original && info.original.contractorId === contractor._id &&
              (datetime.isEqualWithTime(info.original.startDate, cUnavailableDate) ||
                datetime.isEqualWithTime(info.original.endDate, cUnavailableDate) ||
                datetime.isBetween(cUnavailableDate, info.original.startDate, info.original.endDate))
            ) {
              return false;
            }
            return true;
          } else {
            return false;
          }
        });
        let jobDateRange = (contractor.job_date || []).map((e) => {
          return momentTz(e).utcOffset(7).toISOString();
        });
        jobDateRange = _.orderBy(jobDateRange);
        jobDateRange = _.groupBy(jobDateRange);
        const jobDateRangeKeys = Object.keys(jobDateRange);
        jobDateRange = jobDateRangeKeys.map((key) => jobDateRange[key]);
        const hasJob = jobDateRange.filter((d) => {
          const cUnavailableDate = momentTz(d[0]).utcOffset(7).startOf('day');
          if (datetime.isBetween(cUnavailableDate, startDate, endDate)) {
            if (
              info.original &&
              info.original.contractorId === contractor._id &&
              (datetime.isEqualWithTime(
                info.original.startDate,
                cUnavailableDate
              ) ||
                datetime.isEqualWithTime(
                  info.original.endDate,
                  cUnavailableDate
                ) ||
                datetime.isBetween(
                  cUnavailableDate,
                  info.original.startDate,
                  info.original.endDate
                ))
            ) {
              return false;
            }
            return true;
          } else {
            return false;
          }
        });
        if (!hasVacation) {
          if (hasJob && hasJob.length > 0) {
            let validateJobPerDay = true;
            hasJob.map((jobDate) => {
              if (validateJobPerDay) {
                // ดึงข้อมูลเวลางานจาก contractor.job_date_format
                let jobTime = contractor.job_date_format.find(j => j.includes(datetime.formatWithOffset(jobDate[0], 'YYYYMMDD', 7)))
                if (jobTime) {
                  jobTime = +jobTime.slice(-1);
                } else {
                  jobTime = info.available
                }
                // ดึงข้อมูลจาก work calendar
                const workPerDays = _.cloneDeep(contractor.work_calendar || []);
                const availablePerDay = {};
                availablePerDay.survey_per_day = contractor.survey_per_day ? contractor.survey_per_day : { morning: 1, afternoon: 1 };
                availablePerDay.installation_per_day = contractor.installation_per_day ? contractor.installation_per_day : { morning: 1, afternoon: 1 };

                const jobPerDay = jobType === 'S' ? availablePerDay.survey_per_day : availablePerDay.installation_per_day;
                const workDate = _.cloneDeep(workPerDays).filter(
                  (w) =>
                    momentTz(w.start_date).utcOffset(7).startOf('day').toISOString() ===
                    momentTz(jobDate[0]).utcOffset(7).startOf('day').toISOString()
                );
                let jobAvailableMorning = jobPerDay.morning;
                let jobAvailableAfternoon = jobPerDay.afternoon;
                if (workDate && workDate.length > 0) {
                  jobAvailableMorning = jobType === 'I' ? workDate[0].installation_per_day.morning : workDate[0].survey_per_day.morning;
                  jobAvailableAfternoon = jobType === 'I' ? workDate[0].installation_per_day.afternoon : workDate[0].survey_per_day.afternoon;
                  // -----------------------
                  // code ส่วนนี้ใช้เพื่อ set ค่ากลับไปใน workPerDays เพราะหากเป็นงานวันเดียวกันแต่คนละเวลา
                  // จะดึงได้ค่าเก่ามาเสมอ จึงต้อง set ค่ากลับไปด้วย
                  if (jobDate.length > 0) {
                    workPerDays.map((w) => {
                      if (
                        momentTz(w.start_date)
                          .utcOffset(7)
                          .startOf('day')
                          .toISOString() ===
                        momentTz(jobDate[0])
                          .utcOffset(7)
                          .startOf('day')
                          .toISOString()
                      ) {
                        if (jobType === 'I') {
                          if (info.available === 1) {
                            w.installation_per_day.morning -= jobDate.length;
                          } else if (info.available === 2) {
                            w.installation_per_day.afternoon -= jobDate.length;
                          } else if (info.available === 0) {
                            w.installation_per_day.morning -= jobDate.length;
                            w.installation_per_day.afternoon -= jobDate.length;
                          }
                        } else if (jobType === 'S') {
                          if (info.available === 1) {
                            w.survey_per_day.morning -= jobDate.length;
                          } else if (info.available === 2) {
                            w.survey_per_day.afternoon -= jobDate.length;
                          } else if (info.available === 0) {
                            w.survey_per_day.morning -= jobDate.length;
                            w.survey_per_day.afternoon -= jobDate.length;
                          }
                        }
                      }
                    });
                  }
                  // --------------------------
                }
                if (jobDate.length > 0) {
                  // เช็ค เช้าหรือบ่าย เอาไปลบกับงานที่รับทำในแต่ละช่วง
                  if (jobTime === 1) {
                    jobAvailableMorning -= jobDate.length;
                  } else if (jobTime === 2) {
                    jobAvailableAfternoon -= jobDate.length;
                  } else if (jobTime === 0) {
                    jobAvailableMorning -= jobDate.length;
                    jobAvailableAfternoon -= jobDate.length;
                  }
                }
                if (info.available === 1 && jobAvailableMorning <= 0) {
                  validateJobPerDay = false;
                } else if (info.available === 2 && jobAvailableAfternoon <= 0) {
                  validateJobPerDay = false;
                } else if (info.available === 0 && (jobAvailableAfternoon <= 0 || jobAvailableMorning <= 0)) {
                  validateJobPerDay = false;
                }
              }
            });

            if (validateJobPerDay) {
              availableContractors.push(contractor);
            }
          } else {
            availableContractors.push(contractor);
          }
        }
      });
      return availableContractors;
    }
  }

  getMessage(contractors) {
    const info = this.toJS().info;
    const startDate = momentTz(info.startDate).utcOffset(7).startOf('day');
    const endDate = momentTz(
      momentTz(info.endDate).utcOffset(7).format('YYYY-MM-DDT23:59:59.000')
    );
    let requireDate = [];
    const dateRange = datetime.GetBetweenDates(startDate, endDate);
    requireDate = _.concat(
      requireDate,
      dateRange.map((date) => ({
        start_date: momentTz(date).utcOffset(7).startOf('day').toISOString(),
        end_date: momentTz(date).utcOffset(7).endOf('day').toISOString(),
      }))
    );
    const available = [];
    let message = '';
    if (Array.isArray(contractors) && contractors.length) {
      _.forEach(contractors, (contractor) => {
        // ต้องเอา job_date ไปรวมกับ unavailable_date
        contractor.unavailable_date = (
          contractor.unavailable_date || []
        ).concat(contractor.job_date);
        if (Array.isArray(contractor.unavailable_date)) {
          requireDate.map((rq) => {
            const cStartDate = momentTz(rq.start_date).utcOffset(7);
            const cEndDate = momentTz(rq.end_date).utcOffset(7);
            const checkVacation = contractor.unavailable_date.filter((d) => {
              const cUnavailableDate = momentTz(d).utcOffset(7).startOf('day');
              if (datetime.isBetween(cUnavailableDate, cStartDate, cEndDate)) {
                if (
                  info.original &&
                  info.original.contractorId === contractor._id &&
                  (datetime.isEqualWithTime(info.original.startDate, d) ||
                    datetime.isEqualWithTime(info.original.endDate, d) ||
                    datetime.isBetween(
                      d,
                      info.original.startDate,
                      info.original.endDate
                    ))
                ) {
                  return false;
                }
                return true;
              } else {
                return false;
              }
            });
            if (info.available === 0) {
              const myArr = _.uniq(checkVacation);
              available.push(...myArr);
            }
          });
        }
      });
    }
    if (available.length) {
      let morning = 0;
      let day = 0;
      let afternoon = 0;
      const totalContractor = contractors.length;
      const convertData = available.map((a) =>
        momentTz(a).utcOffset(7).format('HH')
      );
      for (let i = 0; i < convertData.length; i++) {
        if (convertData[i] === '00') {
          day++;
        } else if (convertData[i] === '09') {
          morning++;
        } else {
          afternoon++;
        }
      }
      if (
        dateRange.length > 1 ||
        day === totalContractor ||
        (morning === totalContractor && afternoon === totalContractor)
      ) {
        message = 'ไม่มีช่างพร้อมให้บริการ';
      } else if (morning === totalContractor && afternoon !== totalContractor) {
        message = 'บ่าย';
      } else if (afternoon === totalContractor && morning !== totalContractor) {
        message = 'เช้า';
      } else if (morning > afternoon && morning !== totalContractor) {
        message = 'บ่าย';
      } else if (afternoon > morning && afternoon !== totalContractor) {
        message = 'เช้า';
      } else {
        message =
          afternoon === morning ? 'เช้า และ บ่าย' : 'ไม่มีช่างพร้อมให้บริการ';
      }
    }
    return message;
  }

  mappingObjectToDb(info) {
    const store = AuthStore.getStore();
    const storeId = info.storeId
      ? info.storeId
      : store
        ? store._id || null
        : null;
    // QBC-782 Status quotation_management and installation_finished should be changed to survey_finished
    let status = info.status;
    if (info.jobType === 'S' && status && this.trackingSpecialStatus.includes(status)) {
      status = 'survey_finished'
    }

    let objectResult = {
      job_code: info.jobCode,
      store_id: storeId,
      contractor_id: info.contractorId || null,
      start_date: datetime.SendToApi(info.startDate),
      end_date: datetime.SendToApi(info.endDate),
      customer_firstname: info.firstName,
      customer_lastname: info.lastName,
      customer_phone: info.phone,
      customer_phone2: info.phone2,
      customer_address: info.address,
      customer_email: info.email,
      available: info.available,
      time_label: info.timeLabel,
      type_of_work: info.typeOfWork,
      type_of_job: info.jobType,
      other_work: info.otherWork,
      product_id: info.typeOfWork.indexOf(1) >= 0 ? +info.productId : '',
      product_qty: info.jobType === 'I' ? info.productQty : '',
      description: info.description,
      contact_name: info.contactName,
      contact_phone: info.contactPhone,
      delivery_date: info.deliveryDate
        ? datetime.SendToApi(info.deliveryDate)
        : null,
      files: info.files,
      ref_code: info.refCode,
      sale_order_code: info.saleOrderCode,
      seller: info.seller,
      province_code: info.provinceCode,
      district_code: info.districtCode,
      subdistrict_code: info.subDistrictCode,
      product_same_type: info.productSameType,
      survey_files: info.surveyFiles,
      contractor_quotation_files: info.contractorQuotationFiles || [],
      survey_remark: info.surveyRemark,
      installation_start_date: info.installationStartDate
        ? datetime.SendToApi(info.installationStartDate)
        : undefined,
      installation_end_date: info.installationEndDate
        ? datetime.SendToApi(info.installationEndDate)
        : undefined,
      installation_before_files: info.installationBeforeFiles,
      installation_before_remark: info.installationBeforeRemark,
      installation_after_files: info.installationAfterFiles,
      installation_after_remark: info.installationAfterRemark,
      address_info: {
        province: {
          code: info.addressInfo.provinceCode,
          name: info.addressInfo.province,
        },
        district: {
          code: info.addressInfo.districtCode,
          name: info.addressInfo.district,
        },
        sub_district: {
          code: info.addressInfo.subDistrictCode,
          name: info.addressInfo.subDistrict,
        },
        post_code: info.addressInfo.postCode,
        landmark: info.addressInfo.landmark,
        request_location: info.addressInfo.requestLocation ? info.addressInfo.requestLocation : false,
        location: {
          lat: info.addressInfo.location && info.addressInfo.location.lat ? info.addressInfo.location.lat : '',
          lng: info.addressInfo.location && info.addressInfo.location.lng ? info.addressInfo.location.lng : '',
        },
      },
      is_paid: info.is_paid,
      paid_at: info.paid_at,
      remark: info.remark,
      old_remark: info.old_remark,
      installation_price: info.installationPrice,
      quotation_files: info.quotationFiles,
      receipt_files: info.receiptFiles,
      share_store: info.shareStore,
      job_remark: info.jobRemark,
      admin_remark: info.adminRemark,
      sku: storeId === config.partner.scgxp ? 'SCGXP' : info.sku,
      cost: info.cost,
      po_survey: info.po_survey,
      po_installation: info.po_installation,
      contractor_receipt: info.contractorReceipt,
      credit_sale: info.creditSale,
      request_tax: info.requestTax,
      customer_tax: info.customerTax,
      invoice: info.invoice,
      quotation: info.quotation,
      auto_assign_contractor: info.auto_assign_contractor,
      read_history: info.read_history,
      assigned_at: info.assigned_at,
      emp_id: info.emp_id,
      request_location: info.requestLocation,
      asa_data: {
        contractor_team_name: info.asa_data
          ? info.asa_data.contractor_team_name
          : undefined,
        emp_id: info.asa_data ? info.asa_data.emp_id : undefined,
        sku_price: info.asa_data ? info.asa_data.sku_price : undefined,
        request_location: info.asa_data
          ? info.asa_data.request_location
          : undefined,
        location: {
          site_latitude:
            info.asa_data && info.asa_data.location
              ? info.asa_data.location.site_latitude
              : undefined,
          site_longitude:
            info.asa_data && info.asa_data.location
              ? info.asa_data.location.site_longitude
              : undefined,
        },
      },
      customer_approved: info.customer_approved,
      customer_approved_date: info.customer_approved_date
        ? moment(info.customer_approved_date).toISOString()
        : null,
      review_noti_count: info.review_noti_count,
      guarantee: info.guarantee,
      survey_check: {
        submit: info.surveyCheck ? info.surveyCheck.submit : undefined, // default true
        remark: info.surveyCheck ? info.surveyCheck.remark : undefined,
        installation_days: info.surveyCheck
          ? info.surveyCheck.installationDays
          : undefined,
        products: info.surveyCheck ? info.surveyCheck.products : [],
      },
      invoice_commision: info.invoice_commision || undefined,
      contractor_accept: info.contractorAccept || undefined,
      channel: info.channel || undefined,
      adminApprovePaid: info.admin_approve_paid,
      subsidize_promotion: info.subsidizePromotion || undefined,
      payments: info.payments,
      sale_model: info.saleModel,
      additional_charge: info.additionalCharge,
      credit_provider: info.creditProvider,
      claim: info.claim,
      ref_job: info.refJob,
      reason_no_quotation: info.reasonNoQuotation,
      contact_emails: _.map(info.contactEmails, (mail) => {
        return mail.value;
      }),
      installation_from_survey: info.installationFromSurvey,
      change_contractor: info.changeContractor || undefined,
      store_payment_info: info.storePaymentInfo,
      change_customer_remark: info.changeCustomerRemark || undefined,
      status,
      nps: info.nps,
      contractor_team: {
        main_contractor: _.get(info, 'contractorTeam.mainContractor', ''),
        sub_contractor: _.get(info, 'contractorTeam.subContractor', [])
      }
    };

    if (objectResult.quotation.length > 0 && info.installment) {
      objectResult.quotation[objectResult.quotation.length - 1].job_start_date = datetime.SendToApi(info.startDate) || null
      objectResult.quotation[objectResult.quotation.length - 1].job_end_date = datetime.SendToApi(info.endDate) || null
    }
    return objectResult;
  }

  async createJob() {
    if (this.isSubmitting) return;
    this.isSubmitting = true;
    try {
      const info = this.toJS().info;
      const startedAt = datetime.SendToApiWithTime(
        info.startedAt ? info.startedAt : new Date()
      );
      const submitedAt = datetime.SendToApiWithTime(new Date());
      const workingAt = info.contractorId
        ? datetime.SendToApiWithTime(new Date())
        : undefined;
      const assignedAt = info.contractorId
        ? datetime.SendToApiWithTime(new Date())
        : undefined;

      const data = {
        ...this.mappingObjectToDb(info),
        started_at: startedAt,
        submited_at: submitedAt,
        working_at: workingAt,
        assigned_at: assignedAt,
      };

      const result = await http.post(`${config.api.sims}/v1/jobs`, data);
      this.isSubmitting = false;
      if (result && result.status === 400) {
        const msg = result.data.message;
        if (msg === 'CONTRACTOR_NOT_MATCH') {
          throw new Error('ไม่พบข้อมูลช่าง กรุณาตรวจสอบข้อมูล');
        } else {
          throw new Error('พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ\n' + msg);
        }
      }
    } catch (error) {
      this.isSubmitting = false;
      throw error;
    }
  }

  async createContractorJob(id) {
    if (this.isSubmitting) return;
    this.isSubmitting = true;
    try {
      const info = this.toJS().info;
      const startedAt = datetime.SendToApiWithTime(
        info.startedAt ? info.startedAt : new Date()
      );
      const submitedAt = datetime.SendToApiWithTime(new Date());
      const workingAt = datetime.SendToApiWithTime(new Date());
      const assignedAt = datetime.SendToApiWithTime(new Date());
      const data = {
        ...this.mappingObjectToDb(info),
        started_at: startedAt,
        submited_at: submitedAt,
        working_at: workingAt,
        assigned_at: assignedAt,
        contractor_id: id,
      };
      data.create_from = 'sims-web';
      const result = await http.post(
        `${config.api.sims}/v1/jobs/contractor`,
        data
      );
      this.isSubmitting = false;
      if (result && result.status === 400) {
        const msg = result.data.message;
        if (msg === 'CONTRACTOR_NOT_MATCH') {
          throw new Error('ไม่พบข้อมูลช่าง กรุณาตรวจสอบข้อมูล');
        } else {
          throw new Error('พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ');
        }
      }
      return { status: 'success' };
    } catch (error) {
      this.isSubmitting = false;
      throw error;
    }
  }

  async updateContractorJob(contractorId, jobId) {
    if (this.isSubmitting) return;
    this.isSubmitting = true;
    try {
      if (!jobId) {
        throw new Error('ไม่สามารถแก้ไขตารางนัดหมายช่างได้');
      }
      const info = this.toJS().info;
      const workingAt = info.contractorId
        ? datetime.SendToApiWithTime(
          info.workingAt ? info.workingAt : new Date()
        )
        : undefined;
      const assignedAt = info.contractorId
        ? datetime.SendToApiWithTime(new Date())
        : undefined;
      const data = {
        ...this.mappingObjectToDb(info),
        working_at: workingAt,
        assigned_at: assignedAt,
        refCode: info.refCode || '',
      };
      data.create_from = 'sims-web';

      // force delete contractor_team
      delete data.contractor_team

      let url = `${config.api.sims}/v1/jobs/contractor/${jobId}`;
      let result = await http.put(url, data);
      this.isSubmitting = false;
      if (result && result.status === 400) {
        const msg = result.data.message;
        if (msg === 'CONTRACTOR_NOT_MATCH') {
          throw new Error('ไม่พบข้อมูลช่าง กรุณาตรวจสอบข้อมูล');
        } else if (msg) {
          throw new Error(msg);
        } else {
          throw new Error('พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ');
        }
      }
      return { status: 'success' };
    } catch (error) {
      this.isSubmitting = false;
      throw error;
    }
  }

  async validateNewVersion(info) {
    try {
      const id = info.id ? info.id : info._id;
      const submitedAt = info.submitedAt ? info.submitedAt : info.submited_at;
      const workingAt = info.workingAt ? info.workingAt : info.working_at;
      const jobValidate = await this.getJobsById(id, { saveInfo: false });
      if (
        (jobValidate.submited_at &&
          moment(submitedAt).toISOString() !==
          moment(jobValidate.submited_at).toISOString()) ||
        (jobValidate.working_at &&
          moment(workingAt).toISOString() !==
          moment(jobValidate.working_at).toISOString()) ||
        (jobValidate.status !== info.status)
      ) {
        return false;
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async showJobUpdatedDialog(info, callback = function () { }) {
    let jobInfo = info.jobInfo ? info.jobInfo : info;
    if (_.isEmpty(jobInfo.id) && _.isEmpty(jobInfo._id)) {
      return false;
    }
    let hasJobBeenUpdated = !(await this.validateNewVersion(jobInfo));
    if (hasJobBeenUpdated) {
      let jobDataUpdatedDialogOptions = {
        title: "ข้อมูลที่เปิดใช้งานอยู่มีการเปลี่ยนแปลง <br /> คุณต้องการโหลดเป็นข้อมูลล่าสุดหรือไม่?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085D6',
        cancelButtonColor: '#AAAAAA',
        confirmButtonText: 'รีเฟรช',
        cancelButtonText: 'ยกเลิก',
        customClass: 'font-size-200',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !swal.isLoading(),
      };
      let result = await swal.fire(jobDataUpdatedDialogOptions);
      if (result.value) {
        window.location.reload();
      }
      return true;
    }
    else {
      callback();
      return false;
    }
  }

  async updateJob(id, options = {}) {
    if (this.isSubmitting) return;
    const user = AuthStore.getUserInfo();
    this.isSubmitting = true;
    try {
      if (!id) {
        throw new Error('ไม่สามารถแก้ไขตารางนัดหมายช่างได้');
      }
      const info = this.toJS().info;

      // validate new data version
      const dataIsValid = await this.validateNewVersion(info);
      if (!dataIsValid) {
        throw new Error('ข้อมูลมีการเปลี่ยนแปลง กรุณารีเฟรชก่อนทำการแก้ไข');
      }
      const workingAt = info.contractorId
        ? datetime.SendToApiWithTime(
          info.workingAt ? info.workingAt : new Date()
        )
        : undefined;
      const assignedAt = info.contractorId ? new Date() : undefined;
      const data = {
        ...this.mappingObjectToDb(info),
        sku: info.storeId === config.partner.scgxp ? 'SCGXP' : info.sku,
        working_at: workingAt,
        assigned_at: assignedAt,
        refCode: info.refCode || '',
        // submited_by: 'admin',
        submited_by: info.submitedBy,
        noti_additional_charge: options.notiAdditionalCharge,
        created_by: user.email && user.email.includes('@q-chang.com') ? user.email : `Admin(${info.submitedBy})`,
      };

      let url;
      if (options && options.isContractor) {
        url = `${config.api.sims}/v1/contractors/${options.contractorId}/jobs/${id}`;
      } else {
        url = `${config.api.sims}/v1/jobs/${id}`;
      }

      const cloneJob = _.cloneDeep(data);
      // force delete contractor_team
      delete data.contractor_team
      let result = await http.put(url, data);

      await this.assignContractorToJob(id, cloneJob, user.email);

      this.isSubmitting = false;
      if (result && result.status === 400) {
        const msg = result.data.message;
        if (msg === 'CONTRACTOR_NOT_MATCH') {
          throw new Error('ไม่พบข้อมูลช่าง กรุณาตรวจสอบข้อมูล');
        } else if (msg) {
          throw new Error(msg);
        } else {
          throw new Error('พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ');
        }
      }

      if (result && result.data.data._id !== id) {
        if (options && options.isAdmin) {
          url = `${config.publicUrl}/admin/calendar/jobs/${result.data.data._id}`;
        } else if (options && options.isSuperadmin) {
          url = `${config.publicUrl}/superadmin/jobs/${result.data.data._id}`;
        }
        // window.open(url, "_self");
      }
    } catch (error) {
      this.isSubmitting = false;
      throw error;
    }
  }

  async getJobs(options) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      let checkParam = () => {
        if (url.indexOf('?') >= 0) {
          return `&`;
        } else {
          return `?`;
        }
      };

      this.jobs = [];
      let storeInfo = AuthStore.getStore();

      let url = `${config.api.sims}/v1/jobs/calendar`;
      if (options && options.contractorId) {
        if (!options.isAdmin) {
          url = `${config.api.sims}/v1/contractors/${options.contractorId}/jobs`;
        } else {
          url += `${checkParam()}contractor_id=${options.contractorId}`;
        }
      }
      if (options && options.channel) {
        url += `${checkParam()}channel=${options.channel}`;
      }


      if (storeInfo && storeInfo._id) {
        url += `${checkParam()}store_id=${storeInfo._id}`;
      }

      if (options && options.startDate) {
        url += `${checkParam()}start_date=${datetime.SendToApiWithTime(
          options.startDate
        )}`;
      }

      if (options && options.endDate) {
        url += `${checkParam()}end_date=${datetime.SendToApiWithTime(
          options.endDate
        )}`;
      }

      if (options && options.workTypeId >= 0) {
        url += `${checkParam()}work_type_id=${options.workTypeId}`;
      }

      if (options && options.jobType) {
        url += `${checkParam()}job_type=${options.jobType}`;
      }

      if (options && options.isAdminCalendar) {
        url += `${checkParam()}is_admin_calendar=${options.isAdminCalendar}`
      }

      if (options && options.aCode) {
        url += `${checkParam()}a_code=${options.aCode}`;
      }

      let response = await http.get(url);
      this.isLoading = false;
      if (response && response.data && Array.isArray(response.data.data)) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  async extendQuotationTime(jobId) {
    try {
      this.isLoading = true;
      const response = await http.put(`${config.api.sims}/v1/jobs/${jobId}/extendQuotationTime`);
      if (response.status === 200) {
        this.info = this.convertToJobInfoObject(response.data.data);
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  convertContactEmailsDbToObj(contact_emails = []) {
    let contactEmails = [{ key: 1, value: '' }];
    if (contact_emails && contact_emails.length) {
      contactEmails = _.map(contact_emails, (mail, index) => {
        return { key: index + 1, value: mail };
      });
    }
    return contactEmails;
  }

  convertToJobInfoObject(data) {
    let jobInfo;
    if (data) {
      jobInfo = {
        id: data._id,
        jobCode: data.job_code,
        installment: data.installment,
        storeId: data.store_id,
        storeName: data.store_name,
        activity_logs: data.activity_logs ? data.activity_logs : [],
        contractorId: data.contractor_id || '',
        startDate: data.start_date ? moment(data.start_date) : undefined,
        endDate: data.end_date ? moment(data.end_date) : undefined,
        firstName: data.customer_firstname || '',
        lastName: data.customer_lastname || '',
        address: data.customer_address || '',
        phone: data.customer_phone || '',
        phone2: data.customer_phone2 || '',
        email: data.customer_email || '',
        available: data.available,
        timeLabel: data.time_label || undefined,
        typeOfWork: data.type_of_work,
        jobType: data.type_of_job,
        otherWork: data.other_work || '',
        productId: +(data.product_id || 0),
        productQty: +(data.product_qty || 1),
        productUnit: data.product_unit || '',
        description: data.description,
        contactName: data.contact_name,
        contactPhone: data.contact_phone,
        deliveryDate: data.delivery_date
          ? moment(data.delivery_date)
          : undefined,
        files: data.files,
        refCode: data.ref_code,
        saleOrderCode: data.sale_order_code,
        provinceCode: data.province_code,
        districtCode: data.district_code,
        subDistrictCode: data.subdistrict_code,
        productSameType: data.product_same_type,
        status: data.status,
        statusHistory: data.status_history,
        surveyFiles: data.survey_files,
        contractorQuotationFiles: data.contractor_quotation_files || [],
        surveyRemark: data.survey_remark,
        installationStartDate: data.installation_start_date
          ? moment(data.installation_start_date)
          : undefined,
        installationEndDate: data.installation_end_date
          ? moment(data.installation_end_date)
          : undefined,
        installationBeforeFiles: data.installation_before_files,
        installationBeforeRemark: data.installation_before_remark,
        installationAfterFiles: data.installation_after_files,
        installationAfterRemark: data.installation_after_remark,
        addressInfo: {
          subDistrict: data.address_info
            ? data.address_info.sub_district.name
            : undefined,
          district: data.address_info
            ? data.address_info.district.name
            : undefined,
          province: data.address_info
            ? data.address_info.province.name
            : undefined,
          postCode: data.address_info ? data.address_info.post_code : undefined,
          subDistrictCode: data.address_info
            ? data.address_info.sub_district.code
            : undefined,
          districtCode: data.address_info
            ? data.address_info.district.code
            : undefined,
          provinceCode: data.address_info
            ? data.address_info.province.code
            : undefined,
          landmark: data.address_info ? data.address_info.landmark : undefined,
          requestLocation: data.address_info
            ? data.address_info.request_location
            : undefined,
          location: {
            lat:
              data.address_info && data.address_info.location
                ? data.address_info.location.lat
                : undefined,
            lng:
              data.address_info && data.address_info.location
                ? data.address_info.location.lng
                : undefined,
          },
        },
        is_paid: data.is_paid,
        paid_at: data.paid_at || undefined,
        remark: data.remark,
        old_remark: data.old_remark,
        change_date_count: data.change_date_count,
        current_start_date: data.start_date,
        installationPrice: data.installation_price,
        quotationFiles: data.quotation_files,
        receiptFiles: data.receipt_files,
        shareStore: data.share_store || [],
        jobRemark: data.job_remark,
        adminRemark: data.admin_remark,
        sku: data.sku,
        cost: data.cost,
        po_survey: data.po_survey || undefined,
        po_installation: data.po_installation || undefined,
        contractorReceipt: data.contractor_receipt || undefined,
        creditSale: data.credit_sale || undefined,
        requestTax: data.request_tax || false,
        customerTax: data.customer_tax || undefined,
        invoice: data.invoice || [],
        quotation: data.quotation || [],
        auto_assign_contractor: data.auto_assign_contractor || false,
        read_history: data.read_history || [],
        assigned_at: data.assigned_at || undefined,
        submitedAt: data.submited_at || undefined,
        workingAt: data.working_at || undefined,
        emp_id: data.emp_id,
        requestLocation: data.request_location || undefined,
        asa_data: {
          contractor_team_name: data.asa_data
            ? data.asa_data.contractor_team_name
            : undefined,
          emp_id: data.asa_data ? data.asa_data.emp_id : undefined,
          sku_price: data.asa_data ? data.asa_data.sku_price : undefined,
          request_location: data.asa_data
            ? data.asa_data.request_location
            : undefined,
          location: {
            site_latitude:
              data.asa_data && data.asa_data.location
                ? data.asa_data.location.site_latitude
                : undefined,
            site_longitude:
              data.asa_data && data.asa_data.location
                ? data.asa_data.location.site_longitude
                : undefined,
          },
        },
        customer_approved: data.customer_approved,
        customer_approved_date: data.customer_approved_date
          ? moment(data.customer_approved_date)
          : undefined,
        review_noti_count: data.review_noti_count || undefined,
        guarantee: data.guarantee || undefined,
        partnerGuarantee: data.partner_guarantee || undefined,
        surveyCheck: {
          submit: data.survey_check ? data.survey_check.submit : undefined, // default true
          remark: data.survey_check ? data.survey_check.remark : undefined,
          installationDays: data.survey_check
            ? data.survey_check.installation_days
            : undefined,
          products: data.survey_check ? data.survey_check.products : [],
        },
        invoice_commision: data.invoice_commision || undefined,
        contractorAccept: data.contractor_accept || undefined,
        channel: data.channel || undefined,
        adminApprovePaid: data.admin_approve_paid,
        subsidizePromotion: data.subsidize_promotion || undefined,
        payments: data.payments,
        disableEditSeller: data.disableEditSeller,
        seller: data.seller,
        bu_store_id: data.bu_store_id,
        saleModel: data.sale_model || undefined,
        additionalCharge: data.additional_charge || undefined,
        creditProvider: data.credit_provider || undefined,
        claim: data.claim,
        refJob: data.ref_job,
        cartOrderNo: data.cart_order_no,
        reasonNoQuotation: data.reason_no_quotation,
        contactEmails: this.convertContactEmailsDbToObj(data.contact_emails),
        installationFromSurvey: data.installation_from_survey || undefined,
        surveyContractor: data.survey_contractor || undefined,
        storePaymentInfo: data.store_payment_info,
        physicalDetail: data.physical_detail,
        otherExpense: data.other_expense,
        nps: data.nps,
        contractorTeam: {
          mainContractor: _.get(data, 'contractor_team.main_contractor', ''),
          subContractor: _.get(data, 'contractor_team.sub_contractor', [])
        },
        warranty: data.warranty || undefined,
      };
    }
    return jobInfo;
  }

  async getJobsById(id, options) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      if (!id) {
        throw new Error('ไม่สามารถโหลดข้อมูลได้');
      }
      let url = `${config.api.sims}/v1/jobs/${id}`;
      if (options && options.isContractor) {
        url = `${config.api.sims}/v1/contractors/${options.contractorId}/jobs/${id}`;
      }
      let response = await http.get(url, options);
      this.isLoading = false;
      if (response && response.data && response.data.data) {
        const { data } = response.data;
        const infoData = this.convertToJobInfoObject(data);

        if (options && options.saveInfo === false) {
          return data;
        }
        this.saveMultiInfo({
          ...infoData,
          isAssigned: !!data.contractor_id && data.is_paid,
          original: {
            ..._.cloneDeep(infoData),
          },
        });

        return data;
      } else {
        throw new Error('โหลดข้อมูลล้มเหลว');
      }
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  async getJobsByIdAndCurrentActive(id, options) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      if (!id) {
        throw new Error('โหลดข้อมูลล้มเหลว');
      }

      let url;
      if (options && options.isContractor) {
        url = `${config.api.sims}/v1/contractors/current-active?job_id=${id}&contractor_id=${options.contractorId}`;
      } else {
        url = `${config.api.sims}/v1/jobs/current-active?id=${id}`;
      }

      let res = await http.get(url);
      if (res && res.status === 200 && res.data) {
        const body = res.data;
        if (body.data) {
          const { data } = body;
          const infoData = this.convertToJobInfoObject(data);
          this.saveMultiInfo({
            ...infoData,
            isAssigned: !!data.contractor_id && data.is_paid,
            original: {
              ..._.cloneDeep(infoData),
            },
          });
        }
        return body;
      } else {
        throw new Error('โหลดข้อมูลล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getDataForAdminJobRequest(criteria) {
    // if (this.isLoading) return;
    this.isLoading = true;
    try {
      if (!criteria.startDate || !criteria.endDate) {
        return {};
      }
      const storeInfo = AuthStore.getStore();
      const jobInfo = this.toJS().info;
      let _id;
      if (storeInfo) {
        _id = storeInfo._id;
      } else {
        _id = jobInfo.storeId;
      }
      this.filter.minDate = moment(criteria.startDate)
        .subtract(2, 'months')
        .startOf('day');
      this.filter.maxDate = moment(criteria.endDate)
        .add(2, 'months')
        .startOf('day');

      let qs = {
        storeId: _id,
        workTypeId: criteria.workTypeId,
        startDate: datetime.SendToApi(this.filter.minDate),
        endDate: datetime.SendToApi(this.filter.maxDate),
        jobType: criteria.jobType,
        availableTime: criteria.available,
        isAdmin: true,
      };

      if (jobInfo.districtCode) {
        qs.districts = [jobInfo.districtCode];
      }

      if (jobInfo.isAssigned && criteria.contractorId) {
        qs.contractorId = criteria.contractorId;
      }
      // else if (jobInfo.surveyContractor) {
      // bt-2105_Admin can change contractors at any time.
      // criteria.contractorId = jobInfo.surveyContractor;
      // qs.contractorId = jobInfo.surveyContractor;
      // }

      if (jobInfo.addressInfo.districtCode) {
        qs.districts = jobInfo.addressInfo.districtCode;
      }

      let response;
      if (criteria.contractorId) {
        qs = {
          storeId: _id,
          workTypeId: criteria.workTypeId,
          startDate: datetime.SendToApi(this.filter.minDate),
          endDate: datetime.SendToApi(this.filter.maxDate),
          jobType: criteria.jobType,
          availableTime: criteria.available,
          contractorId: criteria.contractorId,
          isAdmin: true,
        };

        if (jobInfo.districtCode) {
          qs.districts = [jobInfo.districtCode];
        }
        if (jobInfo.addressInfo.districtCode) {
          qs.districts = jobInfo.addressInfo.districtCode;
        }
      }

      response = await http.get(
        `${config.api.sims}/v1/calendar/unavailable?${common.urlBuilder(qs)}`
      );
      if (response && response.data && response.data.data) {
        const { contractors, data } = response.data;
        return {
          unavailableDate: common.unavailableDate(contractors, data),
          contractors,
        };
      } else {
        throw new Error('ไม่สามารถโหลดข้อมูลตั้งต้นได้ กรุณาติดต่อผู้ดูแลระบบ');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getUnavailableDateForInstallation(criteria) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const jobInfo = this.toJS().info;
      const storeInfo = AuthStore.getStore();

      let _id;
      if (storeInfo) {
        _id = storeInfo._id;
      } else {
        _id = jobInfo.storeId;
      }

      this.filter.minInstDate = moment(criteria.startInstDate)
        .subtract(2, 'months')
        .startOf('day');
      this.filter.maxInstDate = moment(criteria.endInstDate)
        .add(2, 'months')
        .startOf('day');

      let qs = {
        storeId: _id,
        workTypeId: criteria.workTypeId,
        startDate: datetime.SendToApi(this.filter.minInstDate),
        endDate: datetime.SendToApi(this.filter.maxInstDate),
        jobType: criteria.jobType,
        availableTime: criteria.available,
        isAdmin: true,
      };

      if (jobInfo.districtCode) {
        qs.districts = [jobInfo.districtCode];
      }

      if (jobInfo.isAssigned && criteria.contractorId) {
        qs.contractorId = criteria.contractorId;
      }

      let response;
      if (criteria.contractorId) {
        qs = {
          storeId: _id,
          workTypeId: criteria.workTypeId,
          startDate: datetime.SendToApi(this.filter.minInstDate),
          endDate: datetime.SendToApi(this.filter.maxInstDate),
          jobType: criteria.jobType,
          availableTime: criteria.available.toString(),
          contractorId: criteria.contractorId,
          isAdmin: true,
        };
        if (jobInfo.districtCode) {
          qs.districts = [jobInfo.districtCode];
        }
        response = await http.get(
          `${config.api.sims}/v1/calendar/unavailable?${common.urlBuilder(qs)}`
        );
      } else {
        response = await http.get(
          `${config.api.sims}/v1/calendar/unavailable?${common.urlBuilder(qs)}`
        );
      }

      this.isLoading = false;

      if (response && response.data && response.data.data) {
        const { contractors, data } = response.data;
        return {
          unavailableDate: common.unavailableDate(contractors, data),
          contractors,
        };
      } else {
        throw new Error('ไม่สามารถโหลดข้อมูลตั้งต้นได้ กรุณาติดต่อผู้ดูแลระบบ');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteJob(contractorId, requestBody = {}) {
    try {
      if (this.isLoading) return;
      this.isLoading = true;
      if (contractorId) {
        await http.delete(`${config.api.sims}/v1/jobs/${contractorId}`, requestBody);
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  async deleteContractorJob(jobId) {
    try {
      if (this.isLoading) return;
      this.isLoading = true;

      if (jobId) {
        await http.delete(`${config.api.sims}/v1/jobs/contractor/${jobId}`);
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  async saveAndNextState(nextStatus, options = {}) {
    if (this.isSubmitting) return;
    this.isSubmitting = true;
    try {
      const info = this.toJS().info;
      const id = info.id;
      if (!id) {
        throw new Error('ไม่สามารถบันทึกและเปลี่ยนสถานะได้');
      }

      const workingAt = info.contractorId
        ? datetime.SendToApiWithTime(
          info.workingAt ? info.workingAt : new Date()
        )
        : undefined;
      const assignedAt = info.contractorId
        ? datetime.SendToApiWithTime(new Date())
        : undefined;
      const data = {
        ...this.mappingObjectToDb(info),
        working_at: workingAt,
        assigned_at: assignedAt,
        ref_code: info.refCode || '',
        is_contractor: options.isContractor,
        next_status: nextStatus,
      };

      // force delete contractor_team
      delete data.contractor_team
      let response = await http.put(`${config.api.sims}/v1/jobs/${id}/nextstate`, data);
      if (response && (response.status === 400 || response.status === 500)) {
        const msg = response.data.message;
        if (msg === 'CONTRACTOR_NOT_MATCH') {
          throw new Error('ไม่พบข้อมูลช่าง กรุณาตรวจสอบข้อมูล');
        } else {
          if (msg) {
            throw new Error(msg);
          } else {
            throw new Error('พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ');
          }
        }
      }
      let url = window.location.href;
      if (response && response.data.data._id !== id) {
        url = url.replace(id, response.data.data._id);
        window.open(url, '_self');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isSubmitting = false;
    }
  }

  getJobBySearch = async (options) => {
    try {
      var url = `${config.api.sims}/qchangv2/v1/jobs/search`;

      var params = {
        next_cursor: options.nextCursor,
        store_id: (options.store && options.store._id) || options.store_id,
        channel: options.channel,
        q: options.query || options.q,
        contractor_ids: options.contractor_ids,
        created_date_from: options.created_date_from,
        created_date_to: options.created_date_to,
        has_no_contractor: options.has_no_contractor,
        limit: options.limit,
        sort: options.sort,
        start_date_from: options.start_date_from,
        start_date_to: options.start_date_to,
        statuses: options.statuses,
        type_of_jobs: options.type_of_jobs,
        type_of_works: options.type_of_works,
        sale_models: options.sale_models,
      };

      if (options.contractorId) {
        url = `${config.api.sims}/v1/contractors/${options.contractorId}/jobs`;
        params = {
          q: params.q,
        };
      }
      var queryString = [];
      for (var key in params) {
        if (params.hasOwnProperty(key) && params[key] !== undefined && params[key] !== null) {
          queryString.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
        }
      }
      queryString = queryString.join('&');
      var response = await http.get(url + '?' + queryString);

      if (response && response.data && response.data.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  getOrderTracking = async (refCode, havePromotion = false) => {
    try {
      let url = `${config.api.qchang}/v1/orders/refcode/${refCode}`;
      if (havePromotion) {
        url += `?promotion=${havePromotion}`;
      }
      const response = await http.get(url);
      if (response && response.data && response.data.data) {
        return response.data.data;
      } else {
        return {};
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  getOrderByJobId = async (jobId) => {
    try {
      let url = `${config.api.qchang}/v1/orders/job/${jobId}`;
      const response = await http.get(url);
      if (response && response.data && response.data.data) {
        const order = response.data.data;
        this.orderId = order._id;
        this.onsitePicture = this.initOnsitePicture();
        if(order.onsite_picture) {
          const image = order.onsite_picture.find((item) => item.type === "image");
          const video = order.onsite_picture.find((item) => item.type === "video");
          if (image) {
            this.onsitePicture[0].file = image.file;
          }
          if (video) {
            this.onsitePicture[1].file = video.file;
          }
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  getPromotionByJobId = async (jobId) => {
    try {
      const response = await http.get(`${config.api.qchang}/v1/orders/job/${jobId}`);

      if (response && response.data && response.data.data) {

        const order = response.data.data;
        if (order && order.promotion && order.promotion.id) {
          const promotionId = order.promotion.id;
          const benefit = await http.get(`${config.api.qchang}/v1/coupons/${promotionId}/benefitCoupon`);
          // console.log(benefit.data)
          const initBenefit = _.get(benefit, 'data.coupon_benefits', []).map((item) => ({
            id: item._id,
            code: item.code,
            name: item.name,
            description: item.benefit_details
          }));

          return initBenefit;
        }
      }

    } catch (error) {
      throw new Error(error);
    }
  }

  sendPurchaseOrder = async () => {
    const info = this.toJS().info;
    const { id, cost } = info;
    // const info = this.toJS().info;
    const workingAt = info.contractorId
      ? datetime.SendToApiWithTime(info.workingAt ? info.workingAt : new Date())
      : undefined;
    const assignedAt = info.contractorId
      ? datetime.SendToApiWithTime(new Date())
      : undefined;
    const data = {
      ...this.mappingObjectToDb(info),
      working_at: workingAt,
      assigned_at: assignedAt,
      refCode: info.refCode || '',
    };
    if (id && cost && cost.survey) {
      // force delete contractor_team
      delete data.contractor_team
      let response = await http.put(`${config.api.sims}/v1/jobs/${id}/po`, data);
      if (response.status !== 200) {
        throw new Error('ไม่สามารถส่ง PO ได้');
      }
      return { message: 'success' };
    }
  };

  async updateJobRead(job, parsed) {
    try {
      if (this.isLoading) return;
      this.isLoading = true;
      const readHistory = {
        contractorId: job.contractorId,
        source: parsed.source ? parsed.source : 'browser',
        status: job.status,
        date: datetime.SendToApiWithTime(new Date()),
      };
      await http.put(`${config.api.sims}/v1/jobs/${job.id}/readJob`, { readHistory });
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  async loadCustomerInfoByPhone() {
    const { phone } = toJS(this.info);
    if (!phone) {
      throw new Error(`กรุณาระบุเบอร์ติดต่อ ก่อนทำการโหลดข้อมูล`);
    }

    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const res = await http.get(
        `${config.api.sims}/v1/jobs/latest?phone=${phone}`
      );
      if (res && res.status === 200 && res.data) {
        const { data } = res;
        this.info.firstName = data.customer_firstname;
        this.info.lastName = data.customer_lastname;
        this.info.email = data.customer_email;
        this.info.address = data.customer_address;
        this.info.addressInfo.provinceCode = data.address_info.province.code;
        this.info.addressInfo.province = data.address_info.province.name;
        this.info.addressInfo.districtCode = data.address_info.district.code;
        this.info.addressInfo.district = data.address_info.district.name;
        this.info.addressInfo.subDistrictCode =
          data.address_info.sub_district.code;
        this.info.addressInfo.subDistrict = data.address_info.sub_district.name;
        this.info.addressInfo.postCode = data.address_info.post_code;
        this.info.addressInfo.landmark = data.address_info.landmark;
      } else {
        throw new Error(`ไม่พบข้อมูลลูกค้าเบอร์ติดต่อ ${phone}`);
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async cancelAndRecoverJob(jobCode, isRecover = false) {
    try {
      if (!jobCode) {
        throw new Error(`no job id`);
      }
      let options = {
        is_active: isRecover,
      };
      const response = await http.put(`${config.api.sims}/v1/jobs/isactive/${jobCode}`, options);
      if (response.status !== 200) {
        throw new Error('ไม่สามารถยกเลิกงานได้');
      }
      return { message: 'success' };
    } catch (error) {
      throw error;
    }
  }

  async continueOrBreakJob(jobId, flag) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let options = {
        customer_approved: flag,
      };
      const response = await http.put(`${config.api.sims}/v1/jobs/isapprove/${jobId}`, options);
      if (response.status !== 200) {
        throw new Error('ไม่สามารถเปลี่ยนสถานะการจ่ายเงินให้ช่างได้');
      }
      return { message: 'success' };
    } catch (error) {
      this.isLoading = false;
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getInactiveJobsById(id) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      if (!id) {
        throw new Error('ไม่สามารถโหลดข้อมูลได้');
      }
      let url = `${config.api.sims}/v1/jobs/inactive/${id}`;
      let response = await http.get(url);
      if (response && response.data && response.data.data) {
        const { data } = response.data;
        const infoData = this.convertToJobInfoObject(data);
        this.saveMultiInfo({
          ...infoData,
          isAssigned: !!data.contractor_id && data.is_paid,
          original: {
            ..._.cloneDeep(infoData),
          },
        });
        return data;
      } else {
        throw new Error('โหลดข้อมูลล้มเหลว');
      }
    } catch (error) {
      this.isLoading = false;
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async testSendSMS(telNo, message) {
    try {
      if (this.isLoading) return;
      this.isLoading = true;
      const param = { telNo, message };
      await http.post(`${config.api.sims}/v1/jobs/sms`, param);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  async saveGuarantee(jobId, requestBody = {}) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const res = await http.put(`${config.api.sims}/v1/jobs/${jobId}/guarantee`, requestBody);
      this.isLoading = false;
      if (res) {
        if (res.data && res.data.updateResult) {
          return { message: 'success' };
        }
        return { message: 'error' };
      }
      // if (res && res.status !== 200) {
      //   return { message: 'error' };
      // }
      // if (res && res.data) {
      // }
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  async saveOnsiteToOrder(orderId) {
    if (this.isLoading) return;
    this.isLoading = true;

    const { onsitePicture } = this.toJS();

    // Adjust data like Web 1.5 before send data into API [Ref with QCNS-2562]
    const adjustPictureData = onsitePicture.reduce((newArray, current) => {
      if (current.file.length > 0) {
        current.file.map((item) => {
          const resultFile = {
            name: item.name,
            url: item.url,
            date: item.date,
            path: item.path,
          }
          newArray.push({ type: current.type, file: resultFile })
        })
      }
      return newArray
    }, [])

    try {
      const res = await http.put(`${config.api.sims}/qchang/v1/orders/updateCustomerUploadFile/${orderId}`,
        {
          onsite_picture: adjustPictureData,
        }
      );
      this.isLoading = false;
      if (res) {
        if (res.status === 400) {
          throw new Error(res.data.message);
        }
        return { message: 'success' };
      }
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  async saveContractorQuotationFiles(uploadedData) {
    if (this.isLoading) return;
    this.isLoading = true;

    const { id, contractorQuotationFiles } = this.toJS().info;
    let requestBody = []

    for (let uploaded of uploadedData) {
      const filename = _.get(uploaded, 'res.data.name');
      if (filename) {
        requestBody.push({
          type: imageExtension.some(ext => filename.includes(ext)) ? 'image' : 'file',
          name: filename,
          path: uploaded.res.data.pathFile,
          date: uploaded.res.data.date
        });
      }
    }
    try {
      const res = await http.put(
        `${config.api.sims}/v1/jobs/updateContractorQuotationFiles/${id}`,
        {
          contractor_quotation_files: [...contractorQuotationFiles, ...requestBody],
        }
      );
      this.isLoading = false;
      if (res.status === 200) {
        this.setInfo('contractorQuotationFiles', _.get(res, 'data.data.contractor_quotation_files'));
        return;
      }
      throw new Error(res.data.message);
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  async uploadPartnerWarranty(files, name) {
    if (!files || !files.length) return;
    try {
      this.isLoading = true;
      const info = this.info;
      const id = info.id;
      const formData = new FormData();

      Array.from(files).forEach((item) => {
        formData.append('files', item);
      })

      formData.append('name', name);
      const res = await http.postMultipartFormData(`${config.api.sims}/v1/jobs/upload/${id}/warrantyPartner`, formData);

    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async saveContractorReceipt(jobId, submitedBy) {
    if (this.isLoading) return;
    this.isLoading = true;

    const info = {
      date: new Date(),
      submited_by: submitedBy,
    };
    try {
      const res = await http.put(
        `${config.api.sims}/v1/jobs/${jobId}/contractorreceipt`,
        info
      );
      this.isLoading = false;
      if (res) {
        if (res.status === 400) {
          throw new Error(res.data.message);
        }
        return { message: 'success' };
      }
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  async saveCreditSale(jobId, submitedBy) {
    if (this.isLoading) return;
    this.isLoading = true;

    const info = {
      date: new Date(),
      submited_by: submitedBy,
    };
    try {
      const res = await http.put(
        `${config.api.sims}/v1/jobs/${jobId}/creditsale`,
        info
      );
      this.isLoading = false;
      if (res) {
        if (res.status === 400) {
          throw new Error(res.data.message);
        }
        return res.data;
      }
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  async saveCommision() {
    if (this.isLoading) return;
    this.isLoading = true;

    const { info } = this.toJS();
    try {
      const res = await http.put(
        `${config.api.sims}/v1/jobs/${info.id}/commision`
      );
      this.isLoading = false;

      if (res && res.status !== 200) {
        // console.log('have eror');
        return res.data;
      }
      if (res && res.data) {
        return { message: 'success' };
      }
    } catch (error) {
      this.isLoading = false;
      throw new Error(error);
    }
  }

  async updateFlagAdminApprovePaid(id, flag) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.put(
        `${config.api.sims}/v1/jobs/adminapprovepaid/${id}/${flag}`
      );
      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data;
      }
      return [];
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async getSubsidizePromotion(jobId) {
    try {
      if (!jobId) {
        throw new Error('ไม่สามารถโหลดข้อมูลได้');
      }
      let url = `${config.api.sims}/v1/transfer/getSubsidizeByJob/${jobId}`;
      let response = await http.get(url);
      if (response && response.data && response.data.data) {
        const { data } = response.data;
        return data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getReviewByJobId(jobId) {
    try {
      let url = `${config.api.sims}/v1/reviews/${jobId}`;
      let response = await http.get(url);
      if (response && response.data && response.data.data) {
        const { data } = response.data;
        return data;
      }
    } catch (error) {
      throw error;
    }
  }

  async reverseJobStatus(reason) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const { info } = this.toJS();
      const user = AuthStore.getUserInfo();
      const res = await http.put(
        `${config.api.sims}/v1/jobs/reversestatus/${info.id}`,
        {
          reason,
          user: user.email,
        }
      );
      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data;
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteQuotationByJobId() {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const { info } = this.toJS();
      const res = await http.put(`${config.api.sims}/v1/jobs/${info.id}/delete/quotation`, {
        user: AuthStore.getUserEmail(),
      });
      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data;
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async createClaimJob(jobId, claimMethod, email) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.put(`${config.api.sims}/v1/jobs/${jobId}/${claimMethod}/claim`, {
        email,
      });
      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async getInstallationJobInfo(jobCode, isCreate) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.put(
        `${config.api.sims}/v1/jobs/surveyquotation/getinsjob/${jobCode}/${isCreate}`
      );
      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async getInstallationJobInactive(jobCode) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.get(`${config.api.sims}/v1/jobs/getinsjob/inactive/${jobCode}`);
      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async getSearchContractorList(query) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.post(`${config.api.sims}/v1/contractors/search`, {
        query,
      });

      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async getContractorDetail(jobInfo, contractor) {
    if (this.isLoading) return;
    this.isLoading = true;
    const option = {
      job_id: jobInfo.id,
      contractor_id: contractor._id.toString(),
      start_date: jobInfo.startDate.toDate(),
      end_date: jobInfo.endDate.toDate(),
    };
    try {
      const res = await http.post(`${config.api.sims}/v1/contractors/searchDetail`, { option });
      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async sendNotiToCustomer() {
    try {
      let infoQuotation = _.cloneDeep(toJS(this.info.quotation));
      infoQuotation[infoQuotation.length - 1].job_start_date = datetime.SendToApi(this.info.startDate)
      infoQuotation[infoQuotation.length - 1].job_end_date = datetime.SendToApi(this.info.endDate)
      const payload = {
        job_code: this.info.jobCode,
        created_by: 'q-chang',
        sub_quotation: infoQuotation
      }
      await http.post(`${config.api.sims}/v1/quotations/sendNotiToCustomer`, payload);
    } catch (error) {
      console.log('sendNotiToCustomer error ------------->>', error);
    }
  }

  validateSubTotal = async ({ jobId, quotationNo }) => {
    const { status, data } = await http.get(`${config.api.sims}/v1/jobs/${jobId}/validate-sub-total`, {
      params: {
        quotation_no: quotationNo,
      },
    });
    if (status === 200 && data.valid) {
      return true;
    }
    return false;
  };

  async assignContractorToJob(id, job, user) {
    try {
      if (!job) {
        throw new Error('ไม่พบข้อมูลงาน');
      }

      const { contractor_team } = job;

      const mainContractor = _.get(contractor_team, 'main_contractor._id', '');
      const subContractor = _.get(contractor_team, 'sub_contractor', []).map((sub) => sub._id.toString());

      const response = await http.put(`${config.api.sims}/v1/jobs/${id}/assignContractorTeam`, {
        contractor_team: {
          main_contractor: mainContractor,
          sub_contractor: subContractor,
        },
        user: user,
      });

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (error) {
      console.log('assignContractorToJob error', error);
      throw error;
    }
  };

  async getWarrantyByJob(jobId, code) {
    const jobDetail = await this.getJobsById(jobId)
    const { warranty } = jobDetail;
    const { saleModel, id } = this.info;
    let warrantyCode = _.get(warranty, 'code', false);
    // if (!warrantyCode) return;

    if (code) {
      warrantyCode = code
    }

    const params = `code=${warrantyCode}&job_id=${id}&sale_model=${saleModel}`
    const res = await http.get(`${config.api.sims}/v1/warranty/template?${params}`);

    if (res.status === 200 && res.data) {
      return res.data.data;
    } else {
      // console.log('getWarrantyByJob error', res);
      return {
        message: res.data.message,
        isError: true
      };
    }

  }

  async getWarrantyByCodeSaleModel(jobId, code, version) {
    try {

      // await this.getJobsById(jobId)
      const { saleModel } = this.info;
      if (!saleModel) throw new Error('Not found sale model')

      const params = `code=${code}&version=${version}&sale_model=${saleModel}`
      const res = await http.get(`${config.api.sims}/v1/warranty/code?${params}`);

      if (res.status === 200 && res.data) {
        return res.data.data;
      }
      return undefined;
    } catch (error) {
      throw error;
    }
  }

  async updateWarranty(jobId, requestBody = {}) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      requestBody.user = AuthStore.getUserInfo().email
      const res = await http.put(`${config.api.sims}/v1/jobs/${jobId}/warranty`, requestBody);

      if (res.status === 200 && res.data) {
        return res.data.data;
      }
      return undefined;

    } catch (error) {
      // throw error;
      console.log('error', error)
    } finally {
      this.isLoading = false;
    }
  }

  calDayFromShiftDate(finishDate, warrantyPeriod) {
    const { years, months, days } = warrantyPeriod;
    const start_date = moment(finishDate)
    let end_date = moment(finishDate)

    if (years) {
      end_date.add(parseInt(years), 'year');
    }

    if (months) {
      end_date.add(parseInt(months), 'month');
    }

    if (days) {
      end_date.add(parseInt(days), 'day');
    }

    const totalDay = end_date.diff(start_date, 'days');
    return totalDay
  }

  async createGuaranteeJob() {

    await this.getJobsById(this.toJS().info.id);

    const { id, saleModel, warranty, statusHistory, status } = this.toJS().info;
    const history = statusHistory || [];

    if (!_.get(warranty, 'code', false) || !_.get(warranty, 'version', false)) return;
    if (status !== 'installation_finished') return;

    const prevIsRejected = history && history.length > 4 && history[history.length - 3].value === 'installation_rejected';
    if (!prevIsRejected) return;

    const finishDate = new Date();
    try {
      this.isLoading = true;
      const response = await this.getWarrantyByCodeSaleModel(id, warranty.code, warranty.version);

      if (response) {
        const totalDay = this.calDayFromShiftDate(finishDate, response.warrantyPeriod);
        const { template, content } = response;

        let terms = [..._.get(template, 'warranty_terms.header', []), ..._.get(content, 'warranty_terms', []), ..._.get(template, 'warranty_terms.footer', [])];
        let exclusions = [..._.get(template, 'warranty_exclusions.header', []), ..._.get(content, 'warranty_exclusions', [])];

        terms = terms.map((i) => ({ value: i }));
        exclusions = exclusions.map((i) => ({ value: i }));

        const payload = {
          guarantee: terms,
          not_guarantee: exclusions,
          total_date: totalDay || 0,
          user: 'system',
          is_auto: true,
          is_from_template: true,
          warranty_period: response.warrantyPeriod
        }

        const res = await http.put(`${config.api.sims}/v1/jobs/${id}/guarantee`, payload);

      }
    } catch (error) {
      console.log(error.message);
    } finally {
      this.isLoading = false;
    }
  }

  async deletePartnerWarranty(fileName) {
    try {
      const { id } = this.toJS().info;
      this.isLoading = true;
      const res = await http.delete(`${config.api.sims}/v1/jobs/${id}/warranty`, { fileName });
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    } finally {
      this.isLoading = false;
    }
  }
}
export default new JobStore();
