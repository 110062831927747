import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';
import th from 'date-fns/locale/th';
import { control } from 'react-validation';
import DatePicker, { registerLocale } from 'react-datepicker';
import { SingleDatePicker } from 'react-dates';
import datetime from '../../../utils/datetime';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('th', th);

// Styled-components
const DatePickerWrapper = styled.div`
  width: 100%;
  .label {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 5px;
    display: inline-block;
    color: rgba(0, 0, 0, 0.87);
    padding: 0 !important;
  }
  .required-asterisk {
    color: red;
    margin-left: 2px;
  }
  &.en-datepicker {
    .SingleDatePickerInput--disabled,
    .DateInput--disabled {
      background-color: #fff5d940;
      font-family: 'DB Helvethaica X';
      input:disabled {
        cursor: not-allowed;
      }
    }
  }

  .SingleDatePicker {
    .SingleDatePickerInput {
      button {
        position: absolute !important;
        right: 0px !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        z-index: 1 !important;
      }
    }
  }

  ${(props) => props.error && `.DateInput { border: 1px solid red; }`}
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 5px;
  min-height: 18px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const Icon = styled.i`
  color: #aaa !important;
`;

class EnSingleDatePicker extends SingleDatePicker {
  getDateString(date) {
    return datetime.ConvertToBuddhistYear(date);
  }
}

class CustomDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateValue: props.value || moment(),
      focused: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.dateValue) {
      return { dateValue: nextProps.value };
    }
    return null;
  }

  value() {
    return this.state.dateValue;
  }

  onDateChange(date) {
    this.setState({ dateValue: date });
    if (this.props.onDateChange) {
      this.props.onDateChange(date);
    }
  }

  isDayBlocked(dateMoment) {
    return this.props.isDayBlocked ? this.props.isDayBlocked(dateMoment) : false;
  }

  renderDatePicker(props) {
    const {
      id,
      disabled,
      initialDate,
      isOutsideRange,
      openDirection,
      isPlaceholder,
      isBuddhistYear = true,
    } = props;

    const DatePickerComponent = isBuddhistYear ? EnSingleDatePicker : SingleDatePicker;
    return (
      <DatePickerComponent
        id={id}
        ref={(e) => (this.input = e)}
        renderMonth={(date) =>
          isBuddhistYear ? datetime.ConvertToBuddhistYear(date, 'MMMM ') : date
        }
        isDayBlocked={this.isDayBlocked.bind(this)}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        isOutsideRange={isOutsideRange}
        date={initialDate}
        onDateChange={this.onDateChange.bind(this)}
        focused={this.state.focused}
        onFocusChange={({ focused }) => this.setState({ focused })}
        disabled={disabled}
        placeholder={isPlaceholder}
        openDirection={openDirection}
        customInputIcon={
          <Icon className="fa fa-calendar" aria-hidden="true" style={{ color: '#265ED6' }} />
        }
      />
    );
  }

  render() {
    const { label, required, error } = this.props;

    return (
      <DatePickerWrapper className="en-datepicker" error={error}>
        {label && (
          <div className="label">
            {label}
            {required && <span className="required-asterisk">*</span>}
          </div>
        )}
        {this.renderDatePicker(this.props)}
        <ErrorMessage className="invalid-feedback" visible={required && error}>
          กรุณาเลือกวันที่
        </ErrorMessage>
      </DatePickerWrapper>
    );
  }
}

const EnhanceMonthPicker = ({
  onChangeStartDate,
  onChangeEndDate,
  initialDate,
  maxMonthSelected = 0,
}) => {
  const customHeader = ({
    date,
    increaseYear,
    decreaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
  }) => (
    <div className="custom-month-picker-header">
      <button
        className="btn-next-prev-calendar"
        onClick={decreaseYear}
        disabled={prevYearButtonDisabled}
      >
        <span className="fa fa-long-arrow-left" aria-hidden="true" />
      </button>
      {datetime.ConvertToBuddhistYear(moment(date), 'MMMM ')}
      <button
        className="btn-next-prev-calendar"
        onClick={increaseYear}
        disabled={nextYearButtonDisabled}
      >
        <span className="fa fa-long-arrow-right" aria-hidden="true" />
      </button>
    </div>
  );

  return (
    <div className="d-flex">
      <DatePicker
        renderCustomHeader={customHeader}
        selected={initialDate.start_date}
        onChange={onChangeStartDate}
        selectsStart
        startDate={initialDate.start_date}
        endDate={initialDate.end_date}
        showMonthYearPicker
        showFullMonthYearPicker
        locale="th"
        calendarClassName="custom-month-picker"
        className="custom-month-picker-input"
        value={
          initialDate.start_date
            ? moment(initialDate.start_date).add(543, 'years').format('MM/YYYY')
            : ''
        }
        placeholderText="Month"
        minDate={
          initialDate.end_date
            ? moment(initialDate.end_date)
                .subtract(maxMonthSelected - 1, 'months')
                .endOf('month')
                .toDate()
            : undefined
        }
        maxDate={initialDate.end_date}
        isClearable
      />
      <span className="fa fa-minus month-divider" aria-hidden="true" />
      <DatePicker
        renderCustomHeader={customHeader}
        selected={initialDate.end_date}
        onChange={onChangeEndDate}
        selectsEnd
        startDate={initialDate.start_date}
        endDate={initialDate.end_date}
        showMonthYearPicker
        showFullMonthYearPicker
        locale="th"
        calendarClassName="custom-month-picker"
        className="custom-month-picker-input"
        value={
          initialDate.end_date
            ? moment(initialDate.end_date).add(543, 'years').format('MM/YYYY')
            : ''
        }
        placeholderText="Month"
        maxDate={
          initialDate.start_date
            ? moment(initialDate.start_date)
                .add(maxMonthSelected - 1, 'months')
                .endOf('month')
                .toDate()
            : undefined
        }
        minDate={initialDate.start_date}
        isClearable
      />
    </div>
  );
};

export const CustomDatePickerValidation = control(({ error, isChanged, isUsed, ...props }) => {
  if (error && isChanged && isUsed) {
    props.className = `${props.className || ''} is-invalid-input`;
  }

  return (
    <div>
      <CustomDatePicker {...props} />
      {!_.isNil(error) && !_.isEmpty(error) && isChanged && isUsed ? error : null}
    </div>
  );
});

CustomDatePicker.MonthPicker = EnhanceMonthPicker;
export default CustomDatePicker;
