import React, { Component } from 'react';
import Toggle from 'react-toggle';

export class EnToggle extends Component {
  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e.target.checked);
    }
  }

  render() {
    let { checked, label, disabled, noPadding, onClick, style = {} } = this.props;
    let options = {
      onClick,
      checked,
      disabled,
    };

    let containerStyle = {
      display: 'flex',
      gap: '10px',
      margin: '5px 0 5px 0',
      ...style,
    };

    return (
      <div className={`en-toggle ${noPadding ? '' : 'p4-toggle'}`} style={containerStyle} >
        <Toggle {...options} onChange={this.handleChange} />
        <span>{label}</span>
      </div>
    );
  }
}

export default EnToggle;
