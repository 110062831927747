import React, { useEffect, useState, forwardRef } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 0.75rem;
  width: 100%;
`;

const CodeStyle = styled.span`
  color: #999;
`;

const NameStyle = styled.span`
  color: #555;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;

  .required-asterisk {
    color: red;
    margin-left: 2px;
  }
`;

const Box = styled.div`
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const CustomSelectStyle = styled(Select)`
  .Select-control {
    padding-bottom: 5px;
    border: 1px solid ${(props) => (props.error ? 'red' : '#ccc')} !important;

    .Select-multi-value-wrapper {
      .Select-placeholder {
        ${(props) => props.error && 'border-color: red;'}
      }
    }
  }

  .Select-menu-outer {
    ${(props) => props.error && 'border-color: red;'}
  }
`;

const ErrorMessage = styled.div`
  color: red;
  min-height: 26px; /* เพิ่มความสูงคงที่สำหรับ Error Message */
  font-size: 18px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const CustomOption = forwardRef((props, ref) => {
  const { code, name } = props.option;
  return (
    <Box
      ref={ref}
      onClick={() => {
        props.onSelect(props.option, props);
      }}
    >
      <CodeStyle>{code}</CodeStyle> - <NameStyle>{name}</NameStyle>
    </Box>
  );
});

// Custom select component
const BenefitSelect = ({
  label,
  required = false,
  disabled = false,
  options = [],
  placeholder,
  onChange,
  value,
  onInputChange,
  onClose,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearchInput, setDebouncedSearchInput] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (value && value.length && value.length !== selectedOptions.length) {
      const selected = options.filter((item) => value.includes(item.value));
      setSelectedOptions(selected);
    }
  }, [value, options]);

  // Handle input change with debouncing
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchInput !== debouncedSearchInput && searchInput.length > 2 && onInputChange) {
        setDebouncedSearchInput(searchInput);
        onInputChange(searchInput);
        setError(false);
      }

      if (searchInput && searchInput.length >= 1 && searchInput.length <= 2) {
        setError(true);
      }

      if (searchInput.length === 0) {
        setError(false);
        setSearchInput('');
        setDebouncedSearchInput('');
      }

    }, 1000); // 1 seconds delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchInput, onInputChange]);


  // Handle change from react-select
  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
    if (onChange) {
      onChange(selected.map((item) => item.value));
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    setSearchInput('');
    setDebouncedSearchInput('');
    // setError(false);
  }

  return (
    <Container>
      {label && (
        <Label htmlFor="customInput">
          {label}
          {required && <span className="required-asterisk">*</span>}
        </Label>
      )}
      <CustomSelectStyle
        disabled={disabled}
        multi={true}
        onChange={handleSelectChange}
        options={options}
        placeholder={placeholder || 'กรุณาเลือก'}
        value={selectedOptions}
        searchable
        required={required}
        onInputChange={(text) => setSearchInput(text)}
        optionComponent={CustomOption}
        closeOnSelect={false}
        error={error}
        onClose={handleClose}
      />

      <ErrorMessage visible={error}>{error && 'กรุณาระบุมากกว่า 3 ตัวอักษรขึ้นไป'}</ErrorMessage>
    </Container>
  );
};

export default BenefitSelect;
