import React from 'react';
import swal from 'sweetalert2';
import moment from 'moment';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import numeral from 'numeral';
import MobileDetect from 'mobile-detect';
import Notification from '../common/Notification';
import Loader from '../common/Loader';
import { FormValidation, required, taxid, branchcode } from '../form/FormValidation';
import EnTextFormGroup from '../form/EnTextFormGroup';
import FormGroup from '../form/FormGroup';
import EnTextAreaFormGroup from '../form/EnTextAreaFormGroup';
import EnDropDown from '../form/EnDropDown';
import EnButton from '../form/EnButton';
import EnDropZone from '../form/EnDropZone';
import AddressForm from '../address/AddressForm';
// import { autocomplete } from '../../utils/autocomplete';
import history from '../../utils/history';
import common from '../../utils/common';
import config from '../../config';
import authStore from '../../stores/AuthStore';
import queryString from 'query-string';
import ModalAuthWithReason from '../modal/ModalAuthWithReason';
import EnDatePicker from '../form/EnDatePicker';
// import EnTextArea from '../form/EnTextArea';
import { fieldsEnum } from '../address/Finder';
import http from '../../utils/http';
import Autocomplete from '../autocomplete';
import BenefitQuotation from './BenefitQuotation';
import BenefitItem from './BenefitItem';

export class QuotationForm extends React.Component {
  constructor(props) {
    super(props);
    this.arrRef = [];
    const md = new MobileDetect(window.navigator.userAgent);
    const parsed = queryString.parse(this.props.location.search);
    this.state = {
      showModal: false,
      addedFiles: [],
      jobId: this.props.match.params.id,
      returnJobId: this.props.match.params.retuenid,
      pdf: undefined,
      isMobileOrTablet: !!(md.mobile() || md.tablet()),
      periods: [],
      month: 1,
      isEdit: false,
      isCreate: !!(parsed && parsed.create && parsed.create === 'true') || false,
      isNewDraft: !!(parsed && parsed.isNewDraft && parsed.isNewDraft === 'true') || false,
      isEditDraft: !!(parsed && parsed.draftNo) || false,
      isFinal: !!(parsed && parsed.isFinal && parsed.isFinal === 'true') || false,
      draftNo: parsed && parsed.draftNo ? parsed.draftNo : '',
      quotationNo: parsed && parsed.quotationNo ? parsed.quotationNo : '',
      isSurveyDiscountDisabled: true,
      showModalSubmitReason: false,
      submitWithEmail: false,
      additionalProducts: [],
      paidAllPeriods: false,
      saleModel: 'MarketPlace',
      isShowBenefitModal: false,
      selectedBenefit: [],
      isDisableBenefit: false,
      isDisableEditBenefit: false
    };
    this.props.quotation.clearInfo();
  }

  async componentWillMount() {
    try {
      const { isCreate, isNewDraft, isEdit, isEditDraft, isFinal, quotationNo } = this.state;
      if (!isCreate) {
        await this.props.quotation.loadQuotationByJobId(this.state.jobId, {
          ...this.state,
        });
        await this.props.job.getJobsById(this.props.match.params.id);
      } else {
        await this.props.quotation.initialNewQuotationInfo(this.state.jobId);
      }
      const quotation = await this.props.quotation.toJS();
      const quotationStore = quotation.info
      const jobInfo = this.props.job.toJS().info;
      this.props.quotation.saveInfo('oldDepositDetails', quotationStore.depositDetails);
      if (quotationStore && quotationStore.periods && quotationStore.periods.length) {
        this.props.quotation.saveValue('oldPeriods', quotationStore.periods.length);
        this.props.quotation.savePeriods(quotationStore.periods.length);
        this.props.quotation.saveInfo('periods', quotationStore.periods);
      } else {
        this.props.quotation.saveInfo('periods', [{ amount: quotationStore.grandTotal, status: 'pending', ref: '' }]);
      }

      const data = await this.props.quotation.getOptionQuotation(jobInfo.jobCode)
      if (data && data.sub_quotation) {
        this.props.quotation.subQuotationsInfo = data.sub_quotation
      }

      const job = this.props.job.toJS().info;
      const allAdditionalProducts = await this.getAllAdditionalProducts();

      //get init benefits in coupons's order
      if(isNewDraft){
        const couponsBenefits = await this.props.job.getPromotionByJobId(this.state.jobId);
        if (couponsBenefits && couponsBenefits.length > 0) {
          this.setState({ selectedBenefit: couponsBenefits });
        }
      }

      if (isEditDraft) {
        let draftQuotation = _.get(data, 'draft_quotation', null);
        const subQuotation = _.get(data, 'sub_quotation', []);
        const parsed = queryString.parse(this.props.location.search);
        const isFromQuotationManage = !!parsed.fromQuotationManagement;
        if (draftQuotation) {
          const parsedParam = queryString.parse(this.props.location.search);
          draftQuotation = draftQuotation.filter((quotation) => quotation.draft_no == parsedParam.draftNo);
      
          if (draftQuotation && draftQuotation.length > 0) {
            const currentDraftQuotation = draftQuotation[0];
            if (_.get(currentDraftQuotation, 'benefits', false)) {
              this.preFillBenefit(currentDraftQuotation);
            }
          }
        }
      
        //disable field editBenefit and period work
        if (subQuotation.length > 0 && !isFromQuotationManage) {
          this.setState({ isDisableEditBenefit: true });
        }
      } else if (quotationNo) {
        const parsed = queryString.parse(this.props.location.search);
        const isFromQuotationManage = !!parsed.fromQuotationManagement;
        const mainQuotation = _.get(data, 'main_quotation', null);
        const subQuotation = _.get(data, 'sub_quotation', null);
      
        // งานสำรวจ ต่อติดตั้ง แบบไม่แบ่งงวด
        if (mainQuotation && !subQuotation) {
          const quotationIndex = mainQuotation.findIndex((quotation) => quotation.no === quotationNo);
          if (quotationIndex !== 0) {
            this.setState({ isDisableBenefit: true });
          } else {
            let currentQuotation = mainQuotation.find((quotation) => quotation.no === quotationNo);
            //when period work can't find quotation from url
            if (!currentQuotation) {
              currentQuotation = mainQuotation[0];
            }
      
            const currentBenefits = _.get(currentQuotation, 'benefits', []);
            if (currentBenefits.length > 0 || isFromQuotationManage) {
              this.preFillBenefit(currentQuotation);
            }
          }
        }
      
        // งานสำรวจ ต่อติดตั้ง แบบแบ่งงวด
        if (mainQuotation && subQuotation) {
          // fix main quotation only 1
          const currentQuotation = mainQuotation[0];
          const currentBenefits = _.get(currentQuotation, 'benefits', []);
          if (currentBenefits.length > 0 || isFromQuotationManage) {
            this.preFillBenefit(currentQuotation);
          }
        }

        // งานติดตั้ง
        if (!mainQuotation && !subQuotation) {
          this.setState({ isDisableBenefit: true });
        }
      }
      
      this.setState({
        paidAllPeriods: this.isPaidAllPeriods(jobInfo.quotation),
        additionalProducts: allAdditionalProducts,
        isEdit: this.state.quotationNo ? true : false,
        saleModel: _.get(job, 'saleModel', 'Marketplace'),
      });
    } catch (error) {
      console.log(error);
    }
  }

  preFillBenefit = async (currentQuotation) => {
    const benefits = _.get(currentQuotation, 'benefits', []);
    const detail = _.get(currentQuotation, 'benefits_detail', []);
    const initSelectedBenefit = benefits.map((item) => {

      const findDetail = detail.find((detailItem) => detailItem._id === item.id);
      return {
        id: item.id,
        name: _.get(findDetail, 'name', '-'),
        code: _.get(findDetail, 'code', '-'),
        description: item.details
      }
    });
    this.setState({ selectedBenefit: initSelectedBenefit });
  }

  // componentDidUpdate() {
  //   for (let refKey in this.arrRef) {
  //     if (!this.arrRef[refKey]) {
  //       continue;
  //     }
  //     if (refKey.includes('quotation__product-code')) {
  //       autocomplete(this.arrRef[refKey], this.productsCode, 3);
  //     } else if (refKey.includes('quotation__product-name')) {
  //       autocomplete(this.arrRef[refKey].input, this.productsName, 3);
  //     }
  //   }
  // }

  componentWillUnmount() {
    this.props.quotation.saveInfo('periods', []);
    this.props.quotation.savePeriods(1);
  }

  isPaidAllPeriods(jobQuotation) {
    if (!Array.isArray(jobQuotation)) return false;
    const quotationByNo = jobQuotation.find(q => q.no === this.state.quotationNo);
    if (_.get(quotationByNo, 'periods.length') > 0) {
      return _.last(quotationByNo.periods).status === 'paid';
    }
    return false;
  }

  async getAllAdditionalProducts() {
    const res = await http.get(`${config.api.sims}/v1/additionalProducts?page_size=0`);
    if (_.get(res, 'data.data')) {
      const data = res.data.data;
      return data;
    }
    return [];
  }

  hasDetailsError(details) {
    for (let i = 0; i < details.length; i++) {
      const detail = details[i];
      const detailNameError = required(detail.name);
      const detailUnitError = required(detail.unit);
      const detailQtyError = required(detail.qty);
      if (detailNameError || detailUnitError || detailQtyError) {
        return true;
      }
    }
    return false;
  }

  validateInstallment = () => {
    const info = this.props.quotation.toJS().info;
    const total = info.grandTotal ? +parseFloat(info.grandTotal).toFixed(2) : 0;
    let value = 0;
    let num = 0;
    (info.periods || []).map((i, index) => {
      if (i.amount) {
        num++;
        const val = +i.amount;
        value += val;
      }
    });
    value = +parseFloat(value).toFixed(2);
    if (total > 0 && total === value && (num === info.periods.length)) {
      return false;
    }
    return true;
  }

  validateForm(info) {
    const nameError = required(info.name);
    const taxIdError = taxid(info.taxId);
    const branchError = info.type === 'juristic' ? required(info.branch) : null;
    const { address } = info;
    const addrNoError = required(address.no);
    const provinceError = required(address.province.name);
    const districtError = required(address.district.name);
    const postcodeError = required(address.postcode);
    const { details } = info;
    const detailsError = this.hasDetailsError(details);
    const isContractorTotalMoreThanTotal = info.contractorInfo.subTotal > info.subTotal

    return !nameError &&
      !taxIdError &&
      !branchError &&
      !addrNoError &&
      !provinceError &&
      !districtError &&
      !postcodeError &&
      !detailsError &&
      !isContractorTotalMoreThanTotal;
  }

  saveModelEventLogs(event, referenceCode, remark, user) {
    const dataPermission = {}
    dataPermission.event = event
    dataPermission.function_name = event
    dataPermission.reference_code = referenceCode
    dataPermission.remark = remark
    dataPermission.user = user
    return dataPermission
  }

  async validateShowInstallment() {
    const isAdmin = authStore.getStore();
    const info = this.props.quotation.toJS().info;
    const job = this.props.job;
    if (this.state.isNewDraft || info.subTotal < 100000 || !job.info.installationFromSurvey) {
      // if (!_.isNil(isAdmin) && !_.isEmpty(isAdmin)) {
      //   history.push(`${config.publicUrl}/calendar/admin/jobs/${this.state.returnJobId || this.state.jobId}`);
      // } else {
      //   history.push(`${config.publicUrl}/superadmin/jobs/${this.state.returnJobId || this.state.jobId}`);
      // }
      history.goBack();
    } else {
      this.props.quotation.subQuotationsInfo = []
      await this.props.job.getJobsById(this.props.match.params.id);
      const job = this.props.job;
      const data = await this.props.quotation.getOptionQuotation(job.info.jobCode)
      if (data && data.main_quotation) {
        this.props.quotation.showTabInstallment = job.info.installationFromSurvey && (data.main_quotation[data.main_quotation.length - 1] && data.main_quotation[data.main_quotation.length - 1].sub_total >= 100000)
      } else {
        const info = job.info.quotation.toJS();
        this.props.quotation.showTabInstallment = job.info.installationFromSurvey && (info[info.length - 1] && info[info.length - 1].sub_total >= 100000)
      }
      history.goBack();
    }
    // if (!_.isNil(isAdmin) && !_.isEmpty(isAdmin)) {
    //   history.push(`${config.publicUrl}/calendar/admin/jobs/${this.state.returnJobId || this.state.jobId}`);
    // } else {
    //   history.push(`${config.publicUrl}/superadmin/jobs/${this.state.returnJobId || this.state.jobId}`);
    // }
  }

  async logQuotation(job) {
    const jobI = _.get(job, 'data._id') ? job.data : job;
    if (!_.get(jobI, 'installation_from_survey')) {
      return;
    }
    const historyInstallationRequested = jobI.status_history.find(history => history.value === 'installation_requested');
    const jobSurveyId = historyInstallationRequested.job_id;
    if (this.state.isNewDraft) {
      await this.props.quotation.logQuotation({
        users: this.props.auth.getUsernameOrEmail(),
        quotation_rev_no: 1,
        quotation_no: _.get(jobI, 'quotation.0.no'),
        job_id: jobSurveyId,
        job_code: this.props.job.toJS().info.jobCode,
        is_public: false,
        is_final: false,
        is_delete: false
      });
    }
    if (this.state.isEditDraft || this.state.isEdit) {
      const quotationInfo = this.props.quotation.toJS().quotationInfo;
      if (_.get(quotationInfo, 'draft_quotation.0.no')) {
        const currentDraftQuotation = quotationInfo.draft_quotation.find(dq => dq.no === _.get(jobI, 'quotation.0.no'));
        if (!currentDraftQuotation) { return; }
        await this.props.quotation.logQuotation({
          users: this.props.auth.getUsernameOrEmail(),
          quotation_rev_no: +currentDraftQuotation.rev_no + 1,
          quotation_no: _.get(jobI, 'quotation.0.no'),
          job_id: jobSurveyId,
          job_code: this.props.job.toJS().info.jobCode,
          is_public: currentDraftQuotation.is_public,
          is_final: currentDraftQuotation.is_final,
          is_delete: currentDraftQuotation.is_delete,
        });
      }
    }
  }

  async doSubmit(sendMail = false) {
    const info = this.props.quotation.toJS().info;
    const quotation = this.props.quotation.toJS().quotationInfo;
    const { sub_quotation: subQuotation = undefined } = quotation;
    const isFormValid = this.validateForm(info);
    if (!isFormValid) {
      swal.fire({
        icon: 'error',
        title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
        confirmButtonText: 'ตกลง',
      });
      return;
    }

    const sendMailWording = sendMail ? 'และส่งอีเมล' : '';

    let confirmDialogOptions = {
      title: `บันทึกข้อมูล${sendMailWording}`,
      text: this.state.isEdit ? `กรุณายืนยันการแก้ไขใบเสนอราคา${sendMailWording}` : `กรุณายืนยันการสร้างใบเสนอราคา${sendMailWording}`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const quotationStore = this.props.quotation.toJS();
          const periods = quotationStore.info.periods.map((p, index) => {
            return {
              amount: p.amount,
              status: p.status,
              ref: `${quotationStore.info.no}-${index + 1}`,
              no: `${index + 1}`,
            };
          });
          this.props.quotation.saveInfo('periods', periods);
          this.props.quotation.mergeDetailData();
          const isInstallment = this.state.isFinal && subQuotation && subQuotation.length > 0;

          let benefitsPayload
          if (this.state.selectedBenefit && this.state.selectedBenefit.length >= 0) {
            benefitsPayload = this.state.selectedBenefit.map(item => ({
              id: item.id,
              details: item.description
            }))
          }

          let data;
          if (!isInstallment && (this.state.isNewDraft || this.state.isEditDraft || this.state.draftNo)) {
            const payload = {
              jobCode: this.props.job.toJS().info.jobCode,
              jobId: this.state.jobId,
              user: this.props.auth.getUsernameOrEmail(),
              draftNo: this.state.draftNo,
              isEditDraft: this.state.isEditDraft,
              send_mail: sendMail
            }

            if(benefitsPayload){
              payload.benefits = benefitsPayload
            }

            data = await this.props.quotation.createAndUpdateQuotationDraft(payload);
          } else {
            data = await this.props.quotation.saveQuotation(
              this.state.jobId, sendMail, this.state.isCreate, this.props.auth.getUsernameOrEmail(), benefitsPayload
            );
          }

          this.logActivitySurveyDiscount(quotationStore);
          this.logActivitySubmitReason(quotationStore, data);
          await this.logQuotation(data);
          return data;
        } catch (error) {
          swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          return false;
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      await this.validateShowInstallment()
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const info = this.props.quotation.toJS().info;
    if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
      return;
    }
    if (info.grandTotal < info.contractorInfo.grandTotal) {
      this.setState({ submitWithEmail: false })
      this.onOpenSubmitWithReasonModal();
    } else {
      this.doSubmit();
    }
  }

  async onSubmitAndSendMail(e) {
    e.preventDefault();
    if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
      return;
    }
    const info = this.props.quotation.toJS().info;
    if (info.grandTotal < info.contractorInfo.grandTotal) {
      this.setState({ submitWithEmail: true })
      this.onOpenSubmitWithReasonModal();
    } else {
      this.doSubmit(true);
    }
  }

  async onCancel() {
    // const isAdmin = authStore.getStore();
    // if (!_.isNil(isAdmin) && !_.isEmpty(isAdmin)) {
    //   history.push(`${config.publicUrl}/calendar/admin/jobs/${this.state.returnJobId || this.state.jobId}`);
    // } else {
    //   history.push(`${config.publicUrl}/superadmin/jobs/${this.state.returnJobId || this.state.jobId}`);
    // }
    history.goBack();
  }

  async onPreview() {
    try {
      const pdf = await this.props.quotation.generatePdf(this.state.jobId);
      this.setState({
        pdf,
      });
    } catch (error) {
      console.log(error);
    }
  }

  onInfoChanged(key, e) {
    if (e.target) {
      this.props.quotation.saveInfo(key, e.target.value);
    }
  }

  onAddressNoChanged(e) {
    if (e.target) {
      this.props.quotation.saveAddressInfo('no', e.target.value);
    }
  }

  onAddressFormSelect(addressObject) {
    this.props.quotation.saveAddressObject(addressObject);
  }

  onAddressFormChange = ({ id, value }) => {
    if (id === 'sub-district' && typeof value === 'string' && value.length === 0) {
      this.props.quotation.saveAddressInfo('subDistrict', {
        name: null,
        code: null,
      });
    }
  };

  onTypeChanged(e) {
    if (e.target) {
      this.props.quotation.saveInfo('type', e.target.value);
    }
  }

  onColumnInputChanged(index, key, value) {
    if (key === 'name' || key === 'code') {
      let item = this.props.quotation.toJS().info.details[index];
      const productBeforeChange = this.state.additionalProducts.find(product => product.product_name === item.name && product.product_code === item.code);
      if (productBeforeChange) {
        // update name or code
        item[key] = value;
        // after update name or code, find product again
        const productAfterChange = this.state.additionalProducts.find(product => product.product_name === item.name && product.product_code === item.code);
        if (!productAfterChange) {
          const oldWhtFlag = _.get(item, 'wh_flag', false);
          // if product not found, then update item
          this.props.quotation.saveDetailItem(index, { 
            code: '',
            name: '',
            price: 0,
            qty: 1,
            unit: '',
            contractor_cost: 0,
            contractor_amount: 0,
            contractor_discount: 0,
            amount: 0,
            discount: 0,
            wh_flag: this.state.saleModel === 'Retail' ? true : oldWhtFlag,
            [key]: value
          });
          this.props.quotation.saveDetailAndCalculate(index, 'contractor_cost', 0);
        }
      }
    }

    this.props.quotation.saveDetail(index, key, value);
  }

  onSelected(index, object) {
    if (!object) return;

    const item = this.props.quotation.toJS().info.details[index];
    const oldWhtFlag = _.get(item, 'wh_flag', false);

    const convertedObject = {
      code: _.get(object, 'product_code', ''),
      name: _.get(object, 'product_name', ''),
      price: _.get(object, 'unit_price', 0),
      qty: _.get(object, 'qty', 1),
      unit: _.get(object, 'unit', ''),
      contractor_cost: _.get(object, 'contractor_cost', 0),
      contractor_amount: 0,
      contractor_discount: 0,
      amount: 0,
      discount: 0,
      wh_flag: this.state.saleModel === 'Retail' ? true : oldWhtFlag,
    }

    this.props.quotation.saveDetailItem(index, convertedObject);
    this.props.quotation.saveDetailAndCalculate(index, 'contractor_cost', convertedObject.contractor_cost);
    const quotationInfo = this.props.quotation.toJS().info;
    if (_.get(quotationInfo, 'periods.length') > 0) {
      const newPeriods = [...quotationInfo.periods];
      newPeriods[0] = { ...quotationInfo.periods[0], amount: quotationInfo.grandTotal };
      this.props.quotation.saveInfo('periods', newPeriods);
    }
  }

  // onColumnFocusChanged(index, key, e) {
  //   if (e) {
  //     let matchedProduct = [];
  //     setTimeout(() => {
  //       const infoDetails = this.props.quotation.toJS().info.details;
  //       if (key === 'code') {
  //         matchedProduct = this.state.additionalProducts.filter(additionalProduct => infoDetails[index].code === additionalProduct.product_code);
  //       } else if (key === 'name') {
  //         matchedProduct = this.state.additionalProducts.filter(additionalProduct => infoDetails[index].name === additionalProduct.product_name);
  //       }
  //       if (matchedProduct.length > 0) {
  //         this.props.quotation.saveDetail(index, 'code', matchedProduct[0].product_code);
  //         this.props.quotation.saveDetail(index, 'name', matchedProduct[0].product_name);
  //         this.props.quotation.saveDetail(index, 'unit', matchedProduct[0].unit);
  //         this.props.quotation.saveDetail(index, 'price', matchedProduct[0].unit_price);
  //         this.props.quotation.saveDetailAndCalculate(index, 'contractor_cost', matchedProduct[0].contractor_cost);
  //         const quotationInfo = this.props.quotation.toJS().info;
  //         if (_.get(quotationInfo, 'periods.length') > 0) {
  //           const newPeriods = [...quotationInfo.periods];
  //           newPeriods[0] = { ...quotationInfo.periods[0], amount: quotationInfo.grandTotal };
  //           this.props.quotation.saveInfo('periods', newPeriods);
  //         }
  //       }
  //     }, 400);
  //   }
  // }

  // resetProductRow(index) {
  //   this.props.quotation.saveDetailItem(index, {
  //     code: '',
  //     name: '',
  //     unit: '',
  //     price: '',
  //   });
  //   this.props.quotation.saveDetailAndCalculate(index, 'contractor_cost', '');
  // }

  onDepositColumnInputChanged(index, key, e) {
    if (e.target) {
      this.props.quotation.saveDepositDetail(index, key, e.target.value);
    }
  }

  onDepositInputChangedAndCalculate(index, key, e) {
    if (e.target) {
      this.props.quotation.saveDepositDetailAndCalculate(index, key, e.target.value.replace(/^0+/,"") || 0.00);
    }
  }

  onColumnInputNumberChanged(index, key, e) {
    if (e.target) {
      const value = common.isNumeric(e.target.value) ? parseFloat(e.target.value) : 0;
      this.props.quotation.saveDetail(index, key, value);
    }
  }

  defaultOnePeriod() {
    const quotationStore = this.props.quotation.toJS();
    if (quotationStore.selectPeriods === 1 && quotationStore.info.grandTotal > 0) {
      this.props.quotation.saveInfo('periods', [{
        amount: quotationStore.info.grandTotal,
        status: 'pending',
        ref: `${quotationStore.info.no}-1`,
      }]);
    }
  }

  onInputChangedAndCalculate(index, key, e) {
    if (e.target) {
      this.props.quotation.saveDetailAndCalculate(index, key, e.target.value.replace(/^0+/,"") || 0.00);
      this.defaultOnePeriod();
    }
  }

  onColumnInputNumberChangedandCalculate(index, key, e) {
    if (e.target) {
      this.props.quotation.saveDetailAndCalculate(index, key, e.target.value.replace(/^0+/,"") || 0.00);
    }
  }

  onDiscountCouponChanged(e) {
    if (e.target) {
      const value = common.isNumeric(e.target.value) ? parseFloat(e.target.value) : 0;
      this.props.quotation.saveDiscountCoupon(value);
      this.defaultOnePeriod();
    }
  }

  onInputNumberFocus(e) {
    if (e.target) {
      if(Number(e.target.value) === 0) e.target.value = ""
      e.target.select();
    }
  }

  onInputNumberBlur(e) {
    if (e.target) {
      if(e.target.value === "") e.target.value = 0
    }
  }

  onInputNumberClick(e) {
    if (e.target) {
      e.target.select();
    }
  }

  onAddRow() {
    this.props.quotation.addDetail();
  }

  onDeleteRow(index, e) {
    this.props.quotation.deleteDetail(index);
  }

  checkDisableRow(checkPaid, rowData, subQuotations, discount) {
    const { isNewDraft } = queryString.parse(this.props.location.search);
    if (!isNewDraft && rowData.name) {
      const rowDataSubQuotations = subQuotations.find((item) => {
        const filterItem = item.items.filter((itemInstallment) => itemInstallment.name === rowData.name)
        return filterItem.length > 0
      })
      const installmentStatus = ['paid', 'installation_accepted', 'paid_to_contractor']
      const isPaid = rowDataSubQuotations ? installmentStatus.includes(rowDataSubQuotations.installment_status) : false
      const isCheckPaid = checkPaid && subQuotations.length <= 0 ? checkPaid : false
      if (discount) {
        return isCheckPaid || this.state.isSurveyDiscountDisabled || isPaid
      }
      return isCheckPaid || isPaid
    }
    return false
  }

  displayTableRow(details, checkPaid, subQuotations) {
    const { disabled, disabledQuotationJobInstallment } = this.props;
    const allAdditionalProducts = this.state.additionalProducts;
    return (details || []).map((d, index) => {
      const isDisabledRow = this.checkDisableRow(checkPaid, d, subQuotations);
      const isProductSelect = allAdditionalProducts.find((item) => item.product_name === d.name && item.product_code === d.code)
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>
            <Autocomplete 
              type="input"
              field="product_code"
              options={allAdditionalProducts}
              value={d.code}
              onChange={(value) => this.onColumnInputChanged(index, 'code', value)}
              onSelect={(object) => this.onSelected(index, object)}
              disabled={disabled || isDisabledRow || disabledQuotationJobInstallment}
            />
          </td>
          <td>
            <Autocomplete
              type="textarea"
              field="product_name"
              options={allAdditionalProducts}
              value={d.name}
              onChange={(value) => this.onColumnInputChanged(index, 'name', value)}
              onSelect={(object) => this.onSelected(index, object)}
              disabled={disabled || isDisabledRow || disabledQuotationJobInstallment}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`unit-${index}`}
              name={`unit-${index}`}
              className="text-center"
              size="4"
              value={d.unit}
              onChange={(event) => this.onColumnInputChanged(index, 'unit', event.target.value)}
              disabled={disabled || isDisabledRow || isProductSelect || disabledQuotationJobInstallment}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`qty-${index}`}
              name={`qty-${index}`}
              className="text-right"
              size="4"
              value={d.qty}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'qty')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
              disabled={disabled || isDisabledRow || disabledQuotationJobInstallment}
              onBlur={this.onInputNumberBlur.bind(this)}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`price-${index}`}
              name={`price-${index}`}
              className="text-right"
              size="8"
              value={d.price}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'price')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
              disabled={disabled || isDisabledRow || disabledQuotationJobInstallment}
              onBlur={this.onInputNumberBlur.bind(this)}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`discount-${index}`}
              name={`discount-${index}`}
              className="text-right"
              size="8"
              value={d.discount}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'discount')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
              disabled={disabled || isDisabledRow || disabledQuotationJobInstallment}
              onBlur={this.onInputNumberBlur.bind(this)}
            />
          </td>
          <td className="text-center">
            <b>{numeral(d.amount || 0).format('0,0.00')}</b>
          </td>
          <td className="text-center" style={{ backgroundColor: '#ffffcc' }} >
            <input
              type="text"
              id={`contractor-cost-${index}`}
              name={`contractor-cost-${index}`}
              className="text-right"
              disabled={disabled || disabledQuotationJobInstallment}
              size="8"
              value={d.contractor_cost}
              onChange={this.onColumnInputNumberChangedandCalculate.bind(this, index, 'contractor_cost')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
              onBlur={this.onInputNumberBlur.bind(this)}
            />
          </td>
          <td className="text-center" style={{ backgroundColor: '#ffffcc' }} >
            <input
              type="text"
              id={`contractor-discount-${index}`}
              name={`contractor-discount-${index}`}
              className="text-right"
              disabled={disabled || disabledQuotationJobInstallment}
              size="8"
              value={d.contractor_discount}
              onChange={this.onColumnInputNumberChangedandCalculate.bind(this, index, 'contractor_discount')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
              onBlur={this.onInputNumberBlur.bind(this)}
            />
          </td>
          <td style={{ backgroundColor: '#ffffcc' }} className="text-center">
            <b>{numeral(d.contractor_amount || 0).format('0,0.00')}</b>
          </td>
          <td className="text-center" style={{ backgroundColor: '#ffffcc' }}>
            <input
              type="checkbox"
              id={`wht-${index}`}
              name={`wht-${index}`}
              size="4"
              checked={d.wh_flag}
              value={d.wh_flag}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'wh_flag')}
              onClick={this.onInputNumberClick.bind(this)}
              disabled={disabled || this.checkDisableRow(checkPaid, d, subQuotations) || disabledQuotationJobInstallment}
            />
          </td>
          <td style={{ backgroundColor: '#ffffcc' }} className="text-center">
            {
              (details.length > 1 && !checkPaid) && (
                <EnButton
                  className="btn-primary btn-xs"
                  disabled={disabled || disabledQuotationJobInstallment}
                  style={{ padding: '5px 10px', marginTop: '0' }}
                  onClick={this.onDeleteRow.bind(this, index)}>
                  <i className="fa fa-trash" aria-hidden="true" />
                </EnButton>
              )
            }
          </td>

        </tr>
      );
    });
  }

  displayDepositRow(details, checkPaid, subQuotations) {
    const { disabled, disabledQuotationJobInstallment } = this.props;
    return (details || []).map((d, index) => {
      return (
        <tr key={index}>
          <td />
          <td>
            <input
              type="text"
              id={`code-${index}`}
              name={`code-${index}`}
              value={d.code}
              size="10"
              onChange={this.onDepositColumnInputChanged.bind(this, index, 'code')}
              disabled={disabled || this.checkDisableRow(checkPaid, d, subQuotations, true) || disabledQuotationJobInstallment}
            />
          </td>
          <td>
            <input
              type="text"
              id={`name-${index}`}
              name={`name-${index}`}
              value={d.name}
              size="30"
              onChange={this.onDepositColumnInputChanged.bind(this, index, 'name')}
              disabled={disabled || this.checkDisableRow(checkPaid, d, subQuotations, true) || disabledQuotationJobInstallment}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`unit-${index}`}
              name={`unit-${index}`}
              className="text-center"
              size="4"
              value={d.unit}
              onChange={this.onDepositColumnInputChanged.bind(this, index, 'unit')}
              disabled={disabled || this.checkDisableRow(checkPaid, d, subQuotations, true) || disabledQuotationJobInstallment}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`qty-${index}`}
              name={`qty-${index}`}
              className="text-right"
              size="4"
              value={d.qty}
              onChange={this.onDepositInputChangedAndCalculate.bind(this, index, 'qty')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
              disabled={disabled || this.checkDisableRow(checkPaid, d, subQuotations, true) || disabledQuotationJobInstallment}
              onBlur={this.onInputNumberBlur.bind(this)}
            />
          </td>
          <td className="text-center">
            <input type="text"
              id={`price-${index}`}
              name={`price-${index}`}
              className="text-right"
              size="8"
              value={d.price}
              onChange={this.onDepositInputChangedAndCalculate.bind(this, index, 'price')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
              disabled={disabled || this.checkDisableRow(checkPaid, d, subQuotations, true) || disabledQuotationJobInstallment}
              onBlur={this.onInputNumberBlur.bind(this)}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`discount-${index}`}
              name={`discount-${index}`}
              className="text-right"
              size="8"
              value={d.discount}
              onChange={this.onDepositInputChangedAndCalculate.bind(this, index, 'discount')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
              disabled={disabled || this.checkDisableRow(checkPaid, d, subQuotations, true) || disabledQuotationJobInstallment}
              onBlur={this.onInputNumberBlur.bind(this)}
            />
          </td>
          <td className="text-center">
            <b>{numeral(d.amount || 0).format('0,0.00')}</b>
          </td>
          <td className="text-center" style={{ backgroundColor: '#ffffcc' }} >
            <input
              type="text"
              id={`contractor-cost-${index}`}
              name={`contractor-cost-${index}`}
              className="text-right"
              size="8"
              value={d.contractor_cost}
              onChange={this.onDepositInputChangedAndCalculate.bind(this, index, 'contractor_cost')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
              disabled={disabled || this.checkDisableRow(checkPaid, d, subQuotations, true) || disabledQuotationJobInstallment}
              onBlur={this.onInputNumberBlur.bind(this)}
           />
          </td>
          <td className="text-center" style={{ backgroundColor: '#ffffcc' }} >
            <input
              type="text"
              id={`contractor-discount-${index}`}
              name={`contractor-discount-${index}`}
              className="text-right"
              size="8"
              value={d.contractor_discount}
              onChange={this.onDepositInputChangedAndCalculate.bind(this, index, 'contractor_discount')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
              disabled={disabled || this.checkDisableRow(checkPaid, d, subQuotations, true) || disabledQuotationJobInstallment}
              onBlur={this.onInputNumberBlur.bind(this)}
            />
          </td>
          <td style={{ backgroundColor: '#ffffcc' }} className="text-center">
            <b>{numeral(d.contractor_amount || 0).format('0,0.00')}</b>
          </td>
          <td className="text-center" style={{ backgroundColor: '#ffffcc' }}>
            <input
              type="checkbox"
              id={`wht-${index}`}
              name={`wht-${index}`}
              size="4"
              checked={d.wh_flag}
              value={d.wh_flag}
              onChange={this.onDepositInputChangedAndCalculate.bind(this, index, 'wh_flag')}
              onClick={this.onInputNumberClick.bind(this)}
              disabled={disabled || this.checkDisableRow(checkPaid, d, subQuotations, true) || disabledQuotationJobInstallment}
            />
          </td>
        </tr>
      );
    });
  }


  onBenefitDetailChange = (index, newDetail) => {
    this.setState((prevState) => {
      const newBenefit = [...prevState.selectedBenefit];
      newBenefit[index] = { 
        ...newBenefit[index], 
        description: newDetail
      };
      return { selectedBenefit: newBenefit };
    });
  }

  onBenefitDelete = (index) => {
    this.setState((prevState) => {
      const updatedBenefits = prevState.selectedBenefit.filter((_, i) => i !== index);
      return { selectedBenefit: updatedBenefits };
    });
  }

  displayBenefitsQuotationsRows() {
    const { selectedBenefit, isDisableEditBenefit } = this.state;

    return (
      <>
        {
          (selectedBenefit || []).map((item, index) => (
            <BenefitItem 
              key={item.id} 
              benefit={item} 
              index={index}
              onDelete={this.onBenefitDelete}
              onChange={this.onBenefitDetailChange}
              disabled={isDisableEditBenefit}
            />
          ))
        }
      </>
    )
  }

  genInstallmentInput = (e) => {
    let periods = [];
    for (let i = 1; i <= e; i++) {
      periods.push({ amount: '', status: 'pending', ref: '' });
    }
    this.props.quotation.saveInfo('periods', periods);
  }

  onInstallmentChange = (index, e) => {
    e.preventDefault();
    const quotation = this.props.quotation.toJS();
    if (e.target) {
      let data = quotation.info.periods;
      data[index] = { amount: e.target.value, status: 'pending', ref: '' };
      this.props.quotation.saveInfo('periods', data);
    }
  }

  onRequireWHChanged(e) {
    this.props.quotation.saveInfo('requireWh', e);
  }

  changeType = (e) => {
    const value = e.target.value;
    const quotationStore = this.props.quotation.toJS();
    let periods = [];
    if (value && value === 1) {
      periods = [{ amount: quotationStore.info.grandTotal, status: 'pending', ref: '' }];
    } else if (quotationStore.oldPeriods === value) {
      periods = quotationStore.oldValue;
    } else {
      for (let i = 0; i < value; i++) {
        const periodsItems = (quotationStore.info && quotationStore.info && quotationStore.info.periods[i] ? quotationStore.info.periods[i] : { amount: '', status: 'pending', ref: '' });
        periods.push(periodsItems);
      }
    }
    this.props.quotation.saveInfo('periods', periods);
    this.props.quotation.savePeriods(value);
    this.setState({ select_period: value });
  }

  onAddedFile(e) {
    if (this.dropZone) {
      this.setState({
        addedFiles: this.dropZone.getFiles(),
      });
    }
  }

  onUploadError(message) {
    swal.fire({
      icon: 'error',
      title: `${message}`,
      text: 'กรุณาลองใหม่อีกครั้ง',
    });
  }

  async onUploadComplete(files) {
    const { info } = this.props.quotation.toJS();
    const quotationDetailFiles = _.get(info, 'quotationDetailFiles', []);

    if (files && files.length) {
      const data = _.concat(files.map(file => {
        return {
          name: file.res.data.name,
          url: file.res.data.path,
          date: file.res.data.date,
          active: true,
        }
      }), quotationDetailFiles);
      this.props.quotation.saveInfo('quotationDetailFiles', data);
    }
    this.setState({ showModal: false });
  }

  onDeleteQuotationDetailFile(file) {
    const { info } = this.props.quotation.toJS();
    const quotationDetailFiles = _.get(info, 'quotationDetailFiles', []);
    quotationDetailFiles.map((item, index) => {
      if (item.url === file.url) item.active = false;
      return item;
    });

    this.setState({
      addedFiles: [],
    });
    this.props.quotation.saveInfo('quotationDetailFiles', quotationDetailFiles);
  }

  getDropZone(ref, callback) {
    const { info } = this.props.quotation.toJS();
    const url = `${config.api.sims}/v1/jobs/upload/quotation/detailFile/${this.state.jobId}`;
    const token = authStore.getToken();
    const quotationDetailFiles = info.quotationDetailFiles;
    const quotationDetailFilesLength = Array.isArray(quotationDetailFiles) ?
      quotationDetailFiles.filter(quotationDetailFile => quotationDetailFile.active).length : 0;
    const maxFiles = (10 - quotationDetailFilesLength) > 0 ? 10 - quotationDetailFilesLength : -1;

    return (
      <EnDropZone
        ref={ref}
        url={url}
        token={token}
        acceptedFiles={'image/*,.pdf'}
        onAllComplete={callback}
        maxFileSize={3}
        maxFiles={maxFiles}
        onAddedFile={this.onAddedFile.bind(this)}
        onError={this.onUploadError.bind(this)}
      />
    );
  };

  async showModalAddFile() {
    this.setState({ showModal: true });
  }

  logActivitySurveyDiscount(quotationStore) {
    if (this.state.isSurveyDiscountDisabled) {
      return;
    }
    const exceptiveKeys = ['amount', 'contractor_amount'];
    const { userName } = this.props.permission.toJS();
    const oldDepositDetails = quotationStore.info.oldDepositDetails[0];
    const depositDetails = quotationStore.info.depositDetails[0];
    const activityLogs = [];
    for (let key in depositDetails) {
      if (
        depositDetails.hasOwnProperty(key) &&
        oldDepositDetails.hasOwnProperty(key) &&
        !exceptiveKeys.includes(key) &&
        depositDetails[key] !== oldDepositDetails[key]
      ) {
        activityLogs.push(
          this.saveModelEventLogs(
            `edited_survey_discount`,
            quotationStore.refCode,
            `[${quotationStore.refCode}] is edited at “[${key}] from ${oldDepositDetails[key]} to ${depositDetails[key]}” by ${userName}`
          )
        );
      }
    }
    this.props.permission.setInfoLogActivity(activityLogs);
    this.props.permission.saveUamActivityLogs();
  }

  // Used only this section ref QCNS-2345 <remove passcode from project>
  async onClickSurveyDiscount() {
    let status = this.props.permission.getUserFromEmail();
    if (!status) {
      return
    }
    this.setState({ isSurveyDiscountDisabled: false });
  }

  async onOpenSubmitWithReasonModal() {
    this.props.permission.setShowReasonModal(true);
  }


  async onSubmitPasscodeWithReason() {
    let status = this.props.permission.getUserFromEmail();
    let reason = this.props.permission.reason;
    if (!status || !reason) {
      return
    }
    if (reason.length < 5) {
      swal.fire({
        icon: 'error',
        title: 'กรุณากรอกเหตุผลอย่างน้อย 5 ตัวอักษร',
        text: 'กรุณาลองใหม่อีกครั้ง',
      });
      return
    }
    this.props.permission.setShowReasonModal(false);

    if (this.state.submitWithEmail) {
      this.setState({ submitWithEmail: false })
      this.doSubmit(true);
    } else {
      this.doSubmit();
    }
  }

  async logActivitySubmitReason(quotationStore, quotationData) {
    if (quotationStore.info.grandTotal < quotationStore.info.contractorInfo.grandTotal) {
      const { isCreate } = this.state;
      const { userName, reason } = this.props.permission.toJS();
      const quotationNo = (quotationData.data && quotationData.data.quotation && quotationData.data.quotation.length > 0 && quotationData.data.quotation[0].no) || ''
      const activityLogs = [];
      activityLogs.push(
        this.saveModelEventLogs(
          isCreate ? `create_quotation_withcondition` : `edit_quotation_withcondition`,
          quotationNo,
          `[${moment().format('HH:mm DD/MM/YYYY')}] Quotation (${quotationNo}) is ${isCreate ? 'created' : 'updated'} by (${userName}) with reason (${reason})`,
          userName
        )
      );
      this.props.permission.setInfoLogActivity(activityLogs);
      this.props.permission.saveUamActivityLogs();
      this.props.permission.setReason("");
    }
  }

  checkIsValidDateRange(fromDate, toDate) {
    if (fromDate && toDate) {
      return moment(fromDate).valueOf() <= moment(toDate).valueOf();
    }
    return true;
  }

  handleDateChange(key, date) {
    let errorMessage = ''
    const expiredDays = 30;
    const quotationStore = this.props.quotation.toJS();
    if (key === 'issueDate') {
      if (!this.checkIsValidDateRange(moment(date).add(expiredDays, 'days'), quotationStore.info.expiredDate)) {
        errorMessage = 'กรุณาตรวจสอบวันที่ต้องน้อยกว่าวันหมดอายุ ' + expiredDays + ' วัน';
      }
    }
    if (key === 'expiredDate') {
      if (!this.checkIsValidDateRange(moment(quotationStore.info.issueDate).add(expiredDays, 'days'), date)) {
        errorMessage = 'กรุณาตรวจสอบวันที่ต้องมากกว่าวันออกเอกสาร ' + expiredDays + ' วัน';
      }
    }
    if (errorMessage === '') {
      this.props.quotation.saveInfo(key, date.toDate());
    } else {
      swal.fire({
        icon: 'error',
        title: errorMessage,
        confirmButtonText: 'ตกลง',
      });
    }
  }

  render() {
    const { isShowBenefitModal, selectedBenefit } = this.state;
    const { disabled, disabledQuotationJobInstallment } = this.props;
    const quotationStore = this.props.quotation.toJS();
    const info = this.props.quotation.toJS().info;
    const subQuotations = quotationStore.subQuotationsInfo || []
    const contractorInfo = quotationStore.info.contractorInfo;
    const addressData = this.props.quotation.toAddressData(info.address);
    if (_.get(quotationStore, 'quotationInfo.draft_quotation.length') > 0) {
      const parsedParam = queryString.parse(this.props.location.search);
      const currentDraftQuotation = quotationStore.quotationInfo.draft_quotation.filter((quotation) => quotation.draft_no == parsedParam.draftNo);
      if (currentDraftQuotation.length > 0) {
        quotationStore.info = currentDraftQuotation[0];
      }
    }

    let periods = quotationStore.defaultMonth.map((item, idx) => {
      return <option key={`typeOfJobs-${idx}`} value={item.value}>{item.label}</option>;
    });
    const checkPaid = info.isPaid;
    const isInstallment = _.get(subQuotations, 'length') > 0;
    const isPaidFromPeriods = info.periods && info.periods.length > 0 && info.periods.every(period => period.status === 'paid');

    return (
      <div>
        <ModalAuthWithReason onSubmit={() => this.onSubmitPasscodeWithReason()} showReasonOnly={true} />
        <div className="row">
          <Loader show={quotationStore.isLoading || quotationStore.isSubmitting} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="card-content">
            <FormValidation ref={(el) => {
              this.frm = el;
            }}>
              <div className="row">
                <div className="col-md-10 col-md-offset-2">
                  <label>
                    <input
                      disabled={disabledQuotationJobInstallment}
                      type="radio"
                      value="individual"
                      checked={info.type === 'individual'}
                      onClick={this.onTypeChanged.bind(this)}
                      readOnly />&nbsp;&nbsp;บุคคลธรรมดา
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label>
                    <input
                      disabled={disabledQuotationJobInstallment}
                      type="radio"
                      value="juristic"
                      checked={info.type === 'juristic'}
                      onClick={this.onTypeChanged.bind(this)}
                      readOnly />&nbsp;&nbsp;นิติบุคคล/บริษัท
                  </label>
                </div>
              </div>
              <div className="row">
                <EnTextFormGroup
                  id="name"
                  containerClassName="col-md-6"
                  type="text"
                  label={info.type === 'individual' ? 'ชื่อ-นามสกุล' : 'ชื่อบริษัท'}
                  value={info.name}
                  onChange={this.onInfoChanged.bind(this, 'name')}
                  validations={[required]}
                  disabled={disabledQuotationJobInstallment}
                />
                <EnTextFormGroup
                  id="address"
                  containerClassName="col-md-6"
                  type="text"
                  label="ที่อยู่ (บ้านเลขที่ ซอย ถนน)"
                  value={info.address.no}
                  onChange={this.onAddressNoChanged.bind(this)}
                  validations={[required]}
                  disabled={disabledQuotationJobInstallment}
                />
              </div>
              <div className="row">
                <AddressForm
                  addressData={addressData}
                  notRequiredFields={[fieldsEnum.DISTRICT]}
                  validations={[required]}
                  onAddressSelected={(addressObject) => this.onAddressFormSelect(addressObject)}
                  onChange={this.onAddressFormChange}
                  disabled={disabledQuotationJobInstallment}
                />
              </div>
              <div className="row">
                <EnTextFormGroup
                  id="taxId"
                  containerClassName="col-md-6"
                  type="text"
                  label={info.type === 'individual' ? 'หมายเลขบัตรประชาชน' : 'หมายเลขประจำตัวผู้เสียภาษีอากร'}
                  value={info.taxId}
                  onChange={this.onInfoChanged.bind(this, 'taxId')}
                  maxLength={13}
                  notRequired
                  validations={[taxid]}
                  disabled={disabledQuotationJobInstallment}
                />
                {
                  (info.type === 'juristic') &&
                  <EnTextFormGroup
                    id="branch"
                    containerClassName="col-md-6"
                    type="text"
                    label={'หมายเลขสำนักงานใหญ่ / สาขา *'}
                    value={info.branch}
                    placeholder="กรณีสำนักงานใหญ่ให้ระบุเป็น 00000 หรือ 00001"
                    onChange={this.onInfoChanged.bind(this, 'branch')}
                    maxLength={5}
                    validations={[required, branchcode]}
                    disabled={disabledQuotationJobInstallment}
                  />
                }
                {
                  (info.type === 'individual') &&
                  <EnTextFormGroup
                    id="phone"
                    containerClassName="col-md-6"
                    type="text"
                    label="เบอร์ติดต่อ"
                    value={info.phone}
                    onChange={this.onInfoChanged.bind(this, 'phone')}
                    disabled={disabledQuotationJobInstallment}
                  />
                }
              </div>
              <div className="col-md-12">
                <div className="form-group"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'baseline',
                    gap: '10px'
                  }}
                >
                  <label className="control-label" htmlFor="issueDate">วันที่ออกเอกสาร:</label>
                  <div className="col-md-2">
                    <EnDatePicker
                      id="issueDate"
                      initialDate={
                        info.issueDate ?
                          moment(info.issueDate) : undefined
                      }
                      onDateChange={this.handleDateChange.bind(this, 'issueDate')}
                      isOutsideRange={() => false}
                      disabled={true}
                    />
                  </div>

                  <label className="control-label" htmlFor="expiredDate">ใช้ได้ถึง:</label>
                  <div className="col-md-2">
                    <EnDatePicker
                      id="expiredDate"
                      initialDate={
                        info.expiredDate ?
                          moment(info.expiredDate) : undefined
                      }
                      onDateChange={this.handleDateChange.bind(this, 'expiredDate')}
                      isOutsideRange={(day) => day.isBefore(moment(info.issueDate).add(30, 'days'))}
                      disabled={disabledQuotationJobInstallment}
                    />
                  </div>
                </div>
              </div>
              {
                (info.type === 'juristic') &&
                <div className="row">
                  <EnTextFormGroup
                    id="phone"
                    containerClassName="col-md-6"
                    type="text"
                    label="เบอร์ติดต่อ"
                    value={info.phone}
                    onChange={this.onInfoChanged.bind(this, 'phone')}
                    disabled={disabledQuotationJobInstallment}
                  />
                </div>
              }

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-hover col-md-12">
                      <thead>
                        <tr>
                          <th width="5%" className="text-center">#</th>
                          <th width="10%" className="text-center">รหัสสินค้า</th>
                          <th width="10%" className="text-center">รายการสินค้า</th>
                          <th width="10%" className="text-center">หน่วย</th>
                          <th width="5%" className="text-center">จำนวน</th>
                          <th width="5%" className="text-center">ราคาต่อหน่วย</th>
                          <th width="10%" className="text-center">ส่วนลด</th>
                          <th width="10%" className="text-center">รวม</th>
                          <th width="10%" style={{ backgroundColor: '#ffa64d' }} className="text-center">ต้นทุนช่างต่อหน่วย</th>
                          <th width="10%" style={{ backgroundColor: '#ffa64d' }} className="text-center">ส่วนลดช่าง</th>
                          <th width="10%" style={{ backgroundColor: '#ffa64d' }} className="text-center">รวม</th>
                          {/* <th width="10%" style={{ backgroundColor: '#ffa64d' }} className="text-center">หักภาษี ณ ที่จ่าย</th> */}
                          <th width="10%" style={{ backgroundColor: '#ffa64d' }} className="text-center">WHT</th>
                          <th width="10%" style={{ backgroundColor: '#ffa64d' }} />
                        </tr>
                      </thead>
                      <tbody>
                        {this.displayTableRow(info.details, checkPaid, subQuotations)}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="9">
                            {
                              (!checkPaid || subQuotations.length > 0) && (
                                <EnButton
                                  className="btn-primary"
                                  disabled={disabled || disabledQuotationJobInstallment}
                                  style={{
                                    color: '#337AB7',
                                    background: 'white',
                                    border: '2px solid #337AB7',
                                    padding: '5px 15px',
                                    borderRadius: ' 10px'
                                  }}
                                  onClick={this.onAddRow.bind(this)}>
                                  เพิ่มรายการสินค้า <i className="fa fa-plus" aria-hidden="true" style={{ marginLeft: '5px' }} />
                                </EnButton>
                              )
                            }
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" style={{ 'verticalAlign': 'middle' }}>
                            <span>ส่วนลดค่าสำรวจ</span>
                          </td>
                          <td className='col-md-6'>
                            <EnButton
                              className="btn-primary"
                              style={{ padding: '5px 18px' }}
                              onClick={this.onClickSurveyDiscount.bind(this)}
                              disabled={disabled || !this.state.isSurveyDiscountDisabled || disabledQuotationJobInstallment}
                            >
                              แก้ส่วนลดค่าสำรวจ
                            </EnButton>
                          </td>
                        </tr>
                        {this.displayDepositRow(info.depositDetails, checkPaid, subQuotations)}

                        {
                          (!this.state.isCreate && this.state.quotationNo !== 'undefined' && !this.state.isDisableBenefit) && (
                            <tr>
                              <td colSpan="12">
                                <span colSpan="10">
                                  <h4>สิทธิประโยชน์</h4>
                                  <table width="100%">
                                    <thead>
                                      <tr>
                                        <th width="2%" className="text-center">#</th>
                                        <th width="5%" className="text-center">รหัสสิทธิประโยชน์</th>
                                        <th width="10%" className="text-center">ชื่อสิทธิประโยชน์</th>
                                        <th width="20%" className="text-center">เนื้อหาสิทธิประโยชน์</th>
                                        <th width="5%" className="text-center"><p> </p></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.displayBenefitsQuotationsRows()}
                                    </tbody>
                                  </table>

                                  <BenefitQuotation
                                    show={isShowBenefitModal}
                                    onClose={() => {this.setState({isShowBenefitModal: false})}}
                                    onSelected={(benefits) => {this.setState({selectedBenefit: benefits})}}
                                    selectedBenefit={selectedBenefit}
                                  />
                                  {/* <div>
                                    {this.displayBenefitsQuotationsRows()}
                                  </div> */}
                                  <EnButton
                                    className="btn-primary"
                                    disabled={this.state.isDisableEditBenefit || isPaidFromPeriods}
                                    style={{
                                      color: '#337AB7',
                                      background: 'white',
                                      border: '2px solid #337AB7',
                                      padding: '5px 15px',
                                      borderRadius: ' 10px'
                                    }}
                                    onClick={() => {this.setState({isShowBenefitModal: true})}}
                                  >
                                    เพิ่มสิทธิประโยชน์ <i className="fa fa-plus" aria-hidden="true" style={{ marginLeft: '5px' }} />
                                  </EnButton>
                                </span>
                              </td>
                            </tr>
                          )
                        }
                        
                        <tr>
                          <td rowSpan="8" colSpan="3">
                            <EnTextAreaFormGroup
                              id="remark"
                              label="หมายเหตุ"
                              rows="5"
                              value={info.remark}
                              onChange={this.onInfoChanged.bind(this, 'remark')}
                              disabled={disabledQuotationJobInstallment}
                            />
                            <EnButton
                              disabled={disabledQuotationJobInstallment}
                              className="btn-primary"
                              style={{ padding: '5px 10px' }}
                              onClick={this.showModalAddFile.bind(this)}>
                              <i className="fa fa-upload" aria-hidden="true" />แนบไฟล์

                            </EnButton>
                            {
                              info.quotationDetailFiles.map((quotationDetailFile, index) => quotationDetailFile.active && (
                                <div key={index}>
                                  <a href={quotationDetailFile.url} target="_blank">
                                    <i class={common.getFileIcon(quotationDetailFile.name)}></i>
                                    {` ${quotationDetailFile.name}`}
                                  </a>
                                  <small style={{ color: '#808B96' }}>{moment(quotationDetailFile.date).format('DD/MM/YYYY HH:mm:ss')}</small>
                                  <a
                                    onClick={this.onDeleteQuotationDetailFile.bind(this, quotationDetailFile)}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <i className="fa fa-trash" />
                                  </a>
                                </div>
                              ))
                            }
                          </td>
                          <th className="text-center" style={{ backgroundColor: '#F2F3F4' }} colSpan="5">
                            ส่วนของลูกค้า
                          </th>

                          <th style={{ backgroundColor: '#ffa64d' }} className="text-center" colSpan="4">
                            ส่วนของช่าง
                          </th>
                        </tr>
                        <tr>
                          <th colSpan="2">
                            ยอดรวมทั้งหมด
                          </th>
                          <td colSpan="3" className="text-right">
                            {numeral(info.subTotal).format('0,0.00')}
                          </td>
                          <th style={{ backgroundColor: '#ffffcc' }} colSpan="2">
                            ยอดรวมทั้งหมด
                          </th>
                          <td colSpan="2" style={{ backgroundColor: '#ffffcc' }} className="text-right">
                            {numeral(contractorInfo.subTotal).format('0,0.00')}
                          </td>
                        </tr>
                        <tr>
                          <th colSpan="2">
                            ส่วนลด
                          </th>
                          <td colSpan="3" className="text-right">
                            {numeral(info.discountTotal).format('0,0.00')}
                          </td>
                          <th colSpan="2" style={{ backgroundColor: '#ffffcc' }} >
                            ส่วนลด
                          </th>
                          <td colSpan="2" style={{ backgroundColor: '#ffffcc' }} className="text-right">
                            {numeral(contractorInfo.discountTotal).format('0,0.00')}
                          </td>
                          {/* <th colSpan="6">
                            รวมส่วนลดช่าง
                          </th>
                          <td colSpan="2" className="text-right">
                            {numeral(info.discountTotal).format('0,0.00')}
                          </td> */}
                        </tr>
                        <tr>
                          <th colSpan="2">
                            จำนวนเงินรวมหลังหักส่วนลด
                          </th>
                          <td colSpan="3" className="text-right">
                            {numeral(info.netTotal).format('0,0.00')}
                          </td>
                          <th colSpan="2" style={{ backgroundColor: '#ffffcc' }} >
                            ยอดเงินก่อนภาษี
                          </th>
                          <td colSpan="2" className="text-right" style={{ backgroundColor: '#ffffcc' }} >
                            {numeral(contractorInfo.netExcludeVat).format('0,0.00')}
                          </td>
                        </tr>
                        <tr>
                          <th colSpan="2">
                            จำนวนเงินก่อนภาษี
                          </th>
                          <td colSpan="3" className="text-right">
                            {numeral(info.netExcludeVat).format('0,0.00')}
                          </td>
                          <th colSpan="2" style={{ backgroundColor: '#ffffcc' }} >
                            ภาษีมูลค่าเพิ่ม {info.vatRate}%
                          </th>
                          <td colSpan="2" className="text-right" style={{ backgroundColor: '#ffffcc' }} >
                            {numeral(contractorInfo.vat).format('0,0.00')}
                          </td>
                        </tr>
                        <tr>
                          <th colSpan="2">
                            ภาษีมูลค่าเพิ่ม {info.vatRate}%
                          </th>
                          <td colSpan="3" className="text-right">
                            {numeral(info.vat).format('0,0.00')}
                          </td>
                          <th colSpan="2" style={{ backgroundColor: '#ffffcc' }} >
                            หักภาษี ณ ที่จ่าย
                          </th>
                          <td colSpan="2" className="text-right" style={{ backgroundColor: '#ffffcc' }} >
                            {numeral(contractorInfo.whTax).format('0,0.00')}
                          </td>
                        </tr>
                        <tr>
                          <th colSpan="2">
                            ยอดสุทธิ
                          </th>
                          <td colSpan="3" className="text-right">
                            <b>{numeral(info.grandTotal).format('0,0.00')}</b>
                          </td>
                          <th colSpan="2" style={{ backgroundColor: '#ffffcc' }} >
                            ยอดสุทธิ
                          </th>
                          <td colSpan="2" className="text-right" style={{ backgroundColor: '#ffffcc' }} >
                            <b>{numeral(contractorInfo.grandTotal).format('0,0.00')}</b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="col-md-12">
                    <h3>การแบ่งชำระเงิน</h3>
                    <FormGroup label="กรุณาเลือกงวด:" containerClassName="col-md-12">
                      <EnDropDown
                        value={quotationStore.selectPeriods || 1}
                        onChange={this.changeType.bind(this)}
                        disabled={(!isInstallment && this.state.paidAllPeriods && disabled) || isInstallment || disabledQuotationJobInstallment}
                      >
                        {periods}
                      </EnDropDown>
                    </FormGroup>
                    {
                      (info.periods || []).map((p, index) => {
                        const isPaid = p.status === 'paid';
                        return (<div key={index}>
                          <EnTextFormGroup
                            ref={(el) => {
                              this.installmentInput = el;
                            }}
                            containerClassName="col-md-6"
                            type="number"
                            value={p.amount ? p.amount : ''}
                            onChange={this.onInstallmentChange.bind(this, index)}
                            validations={[required]}
                            label={`งวดที่ ${index + 1} ${isPaid ? '(ชำระเงินแล้ว)' : ''}`}
                            disabled={isPaid || _.get(subQuotations, 'length') > 0 || disabledQuotationJobInstallment}
                          />
                        </div>);
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <EnButton
                    className="btn-warning pull-right"
                    disabled={this.validateInstallment() || !this.validateForm(info) || disabledQuotationJobInstallment || this.state.disabledSendMail || isPaidFromPeriods}
                    onClick={this.onSubmitAndSendMail.bind(this)}
                  >
                    <i className="fa fa-paper-plane-o btn-icon" aria-hidden="true" />บันทึกและส่งเมล
                  </EnButton>
                  <EnButton
                    className="btn-info pull-right"
                    disabled={this.validateInstallment() || !this.validateForm(info) || disabledQuotationJobInstallment || isPaidFromPeriods}
                    onClick={this.onSubmit.bind(this)}
                  >
                    <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                  </EnButton>
                  <EnButton
                    className="btn-danger pull-right"
                    onClick={this.onCancel.bind(this)}
                  >
                    <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                  </EnButton>
                </div>
              </div>
            </FormValidation>
          </div>
        </div>
        <Modal
          show={this.state.showModal}
          onHide={e => { this.setState({ showModal: false }); }}
        >
          <Modal.Header closeButton={false} style={{ backgroundColor: '#f3873a', paddingTop: 12, paddingBottom: 12 }}>
            <Modal.Title id="ModalHeader" style={{ color: 'white' }}>
              อัพโหลดไฟล์
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {this.state.fileName && (
                <div style={{ marginLeft: 10 }}><i className="fa fa-file-excel-o"></i> {this.state.fileName}</div>
              )}
              <FormGroup containerClassName="col-md-12" label="อัพโหลดไฟล์" >
                {this.getDropZone(e => { this.dropzone = e; }, (e) => { this.onUploadComplete(e); })}
              </FormGroup>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" title="ยกเลิก" data-tip="ยกเลิก" onClick={e => { this.setState({ showModal: false }); }} >
              <span className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
            </button>
            <button className="btn btn-info" title="บันทึก" data-tip="บันทึก" onClick={e => { this.onSave() }}>
              <span className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default inject('quotation', 'auth', 'job', 'permission')(observer(QuotationForm));
