import humps from 'humps';
import { get, isArray } from 'lodash';

import BaseStore from '../BaseStore';
import config from '../../config';
import { http } from '../../utils/http';
import moment from 'moment';
import qs from 'query-string';
import momentTz from 'moment-timezone';
export class Coupon extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      couponType: [{
        label: 'ส่วนลดค่าติดตั้ง',
        value: 'I',
      }, {
        label: 'ส่วนลดค่าสำรวจ',
        value: 'S',
      }],
      idNewCouponCopy: '',
      info: this.initInfo(),
      query: {},
      pagination: {},
      benefitOption: [],
    });
    this.setDefaultQuery();
  }

  setDefaultQuery() {
    this.query = {
      page_size: 10,
      page: 1,
      page_range: 5,
      channel: '',
      product: '',
      province: '',
      code: '',
      status: '',
      effectiveDate: undefined,
    };
  }

  setQueryValue(key, value) {
    this.query[key] = value;
  }

  setQuery(value) {
    this.query = value;
  }

  initialSelectBranch() {
    this.info.isSelectBranch = false;
  }
  initInfo() {
    return {
      name: '',
      code: '',
      value: 0,
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      items: [],
      status: 'active',
      type: 'I',
      useLimit: 0,
      isLimit: false,
      requestPayment: false,
      provinceId: [],
      stores: [],
      isDelete: false,
      isSelectBranch: false,
      isPrivateCode: true,
      isMember: false,
      isLimitedUseMember: false,
      limitedUsePerMember: 1,
      requestNotification: true,
      productChannel: null,
      channel: [],
      model: 'MarketPlace',
      contactChannel: '',
      creditTerm: 1,
      adBanner: null,
      heroBanner: null,
      heroBannerSquare: null,
      promotionBottomBanner: null,
      promotionThumbnail: null,
      isBannerPromotion: false,
      information: {
        seqNo: 1,
        title: '',
        description: '',
        content: '',
        isOnlyPromotionDetail: false,
        termsBrief: '',
        termsUse: '',
        couponHeader: '',
        content: '',
      },
      requestStorePayment: false,
      storePaymentNotiEmails: [],
      customerUuid: null,
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  clearBenefitOption(){
    this.benefitOption = [];
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  saveInformation(key, value) {
    this.info.information[key] = value;
  }

  deleteInformation() {
    this.info.information = {
      seqNo: 1,
      title: '',
      content: '',
    };
  }

  deleteInfo(key) {
    delete this.info[key];
  }

  async getStoreByID(payload) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.post(`${config.api.sims}/v1/bizaccount/stores/searchByChannel`, payload);
      if (response.status !== 200) {
        if (response.data.message) {
          throw new Error(`${response.data.message}`);
        } else {
          throw new Error('ไม่สำเร็จ');
        }
      } else {
        return response.data.data
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getProductsDup(options) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.getUpdateInfo(options);
      const response = await http.post(`${config.api.qchang}/v1/coupons/productsDup`, info);
      if (response.status !== 200) {
        if (response.data.message) {
          throw new Error(`${response.data.message}`);
        } else {
          throw new Error('ไม่สำเร็จ');
        }
      } else {
        return response.data.data
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  // clearGroupInfo() {
  //   this.group.info = this.initGroupInfo();
  // }

  // saveGroupInfo(key, value) {
  //   const { group } = this.toJS();
  //   set(group, `info.${key}`, value);
  //   this.group = group;
  // }

  async searchCoupon() {
    if (this.isLoading) return;
    const { query } = this.toJS();
    try {
      this.isLoading = true;
      const res = await http.post(`${config.api.sims}/qchang/v1/coupons/search?${qs.stringify(query)}`);
      if (res && res.data && res.data.data) {
        this.items = res.data.data;
        this.pagination = res.data.pagination;
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async getList() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.qchang}/v1/coupons`);
      if (response.status === 200 && response.data) {
        this.items = response.data.data;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getListResult(field, id, history = false) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      let result = [];
      let path = '/v1/coupons'
      if (field) {
        path = path + `?field=${field}&id=${id}&history=${history}`
      }
      const response = await http.get(`${config.api.qchang}${path}`);
      if (response.status === 200 && response.data) {
        result = response.data.data;
      }
      return result;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getCouponById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.qchang}/v1/coupons/${id}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      const data = humps.camelizeKeys(response.data.data);
      data.contactChannel = '';
      data.creditTerm = 1;
      if (!data.information) {
        data.information = {
          seqNo: '',
          title: '',
          content: '',
          description: '',
          isOnlyPromotionDetail: false,
        }
      }
      if (!data.requestStorePayment) data.requestStorePayment = false;
      if (!data.storePaymentNotiEmails) data.storePaymentNotiEmails = [];
      this.info = {
        ...data,
        isMember: data.isMember || false,
      };
      return data
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  getUpdateInfo(options) {
    const info = this.toJS().info;
    const { selectProductIds, provinceItems, branchItems, isOnlyPromotionDetail } = options;
    if (Array.isArray(selectProductIds) && selectProductIds.length) {
      info.items = selectProductIds;
    }
    if (provinceItems && provinceItems.length) {
      info.provinceId = provinceItems.filter(Boolean);
    }
    if (branchItems && branchItems.length) {
      info.stores = branchItems.filter(Boolean);
    } else {
      delete info.stores
    }
    info.startDate = momentTz(info.startDate).utcOffset(7).startOf('day').toDate()
    info.endDate = momentTz(info.endDate).utcOffset(7).endOf('day').toDate()
    info.information.isOnlyPromotionDetail = isOnlyPromotionDetail && isOnlyPromotionDetail

    if (info.benefits) {
      delete info.benefits
    }

    return info;
  }

  async createCoupon(options) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.getUpdateInfo(options);
      if(options.benefits){
        info.benefit_id = options.benefits
      }
      const response = await http.post(`${config.api.qchang}/v1/coupons`, info);
      if (response.status !== 200) {
        if (response.data.message) {
          throw new Error(`${response.data.message}`);
        } else {
          throw new Error('การบันทึกล้มเหลว');
        }
      } else {
        this.idNewCouponCopy = response.data.data._id;
        return response.data.data;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateCoupon(id, options) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const info = this.getUpdateInfo(options);
      // delete 'promotionType' before it is decamelized to 'promotion_type'
      const requestBody = { ...info };

      if(options.benefits){
        requestBody.benefit_id = options.benefits
      }

      delete requestBody['promotionType'];
      const response = await http.put(`${config.api.qchang}/v1/coupons/${id}`, requestBody, {
        promotionType: info.promotionType,
        promotionIndex: info.promotionIndex,
      });
      if (response.status !== 200) {
        if (response.data.message) {
          throw new Error(`${response.data.message}`);
        } else {
          throw new Error('การบันทึกล้มเหลว');
        }
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async toggleStatus(index, status) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.put(`${config.api.qchang}/v1/coupons/${this.items[index]._id}/status`, { status });
      if (response.status === 200 && response.data) {
        this.items[index] = response.data.data;
      } else {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteCoupon(id) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.delete(`${config.api.qchang}/v1/coupons/${id}`);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async uploadBannerImage(file) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await http.postMultipartFormData(`${config.api.qchang}/v1/coupons/upload`, formData);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateBannerInfo(id, info) {
    if (this.isLoading) return;
    this.isLoading = true;
    let url = `${config.api.qchang}/v1/coupons/${id}/banner`;
    let body = {
      banner: info.adBanner,
      img_banner: {
        hero_banner: info.heroBanner,
        hero_banner_square: info.heroBannerSquare,
        promotion_bottom_banner: info.promotionBottomBanner,
        promotion_thumbnail: info.promotionThumbnail,
      },
      information: info.information,
      is_banner_promotion: get(info, 'isBannerPromotion', false),
    };

    try {
      let response = await http.put(url, body);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async checkDuplicateCode(codes, couponType) {
    try {
      const convertCords = isArray(codes) ? codes : [codes];
      const response = await http.post(`${config.api.qchang}/v1/coupons/checkDuplicateCode`, { codes: convertCords, type: couponType });
      return response.data.isDuplicate;
    } catch (error) {
      throw error;
    }
  }

  async searchBenefit(search){
    try {
      const response = await http.get(`${config.api.qchang}/v1/coupons/benefit?search=${search}`);
      
      if(response && response.data && response.data.benefit_list){
        this.benefitOption = response.data.benefit_list;
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export default new Coupon();
