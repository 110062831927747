import _ from 'lodash';
import axios from 'axios';
import BaseStore from './BaseStore';
import config from '../config';

export class BenefitStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      coupons: [],
      benefits: [],
      benefit: {
        name: '',
        code: '',
        startDate: '',
        endDate: '',
        benefitDetails: '',
        couponId: [],
        isActive: false,
        isDeleted: false,
      },
      tempBenefit: {
        name: '',
        code: '',
        startDate: '',
        endDate: '',
        benefitDetails: '',
        couponId: [],
        isActive: false,
        isDeleted: false,
      },
      query: {
        code: '',
        name: '',
        start_date: '',
        end_date: '',
      },
      pagination: {
        page: 1,
        page_range: 5,
        page_size: 20,
      },
      months: [
        { value: 1, label: 'มกราคา', short: 'ม.ค.' },
        { value: 2, label: 'กุมภาพันธ์', short: 'ก.พ.' },
        { value: 3, label: 'มีนาคม', short: 'มี.ค.' },
        { value: 4, label: 'เมษายน', short: 'เม.ย.' },
        { value: 5, label: 'พฤษภาคม', short: 'พ.ค.' },
        { value: 6, label: 'มิถุนายน', short: 'มิ.ย.' },
        { value: 7, label: 'กรกฎาคม', short: 'ก.ค.' },
        { value: 8, label: 'สิงหาคม', short: 'ส.ค.' },
        { value: 9, label: 'กันยายน', short: 'ก.ย.' },
        { value: 10, label: 'ตุลาคม', short: 'ต.ค.' },
        { value: 11, label: 'พฤศจิกายน', short: 'พ.ย.' },
        { value: 12, label: 'ธันวาคม', short: 'ธ.ค.' },
      ],
    });
  }

  // Convert object keys from snake_case to camelCase
  convertKeysToCamelCase(obj) {
    if (Array.isArray(obj)) {
      return obj.map((item) => this.convertKeysToCamelCase(item));
    }

    if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        // Keep _id as is
        if (key === '_id') {
          acc[key] = obj[key];
        } else {
          const camelCaseKey = _.camelCase(key);
          acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
        }
        return acc;
      }, {});
    }

    return obj; // Return the value directly if it's not an object
  }

  // Convert object keys from camelCase to snake_case
  convertKeysToSnakeCase(obj) {
    if (Array.isArray(obj)) {
      return obj.map((item) => this.convertKeysToSnakeCase(item));
    }

    if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        // Keep _id as is
        if (key === '_id') {
          acc[key] = obj[key];
        } else {
          const snakeCaseKey = this.convertCamelToSnake(key);
          acc[snakeCaseKey] = this.convertKeysToSnakeCase(obj[key]);
        }
        return acc;
      }, {});
    }

    return obj; // Return the value directly if it's not an object
  }

  // Function to convert camelCase to snake_case
  convertCamelToSnake(str) {
    return str.replace(/([A-Z])/g, '_$1').toLowerCase();
  }

  // Event
  onChangeQuery(key, value) {
    this.query[key] = value;
  }

  onChangePage(page) {
    this.pagination.page = page;
    this.getBenefitList();
  }

  onChangeBenefit(key, value) {
    this.benefit[key] = value;
  }

  clearQuery() {
    this.query = {
      code: '',
      name: '',
      start_date: '',
      end_date: '',
    };
  }

  clearState() {
    this.benefit = {
      name: '',
      code: '',
      startDate: '',
      endDate: '',
      benefitDetails: '',
      couponId: [],
      isActive: false,
      isDeleted: false,
    };

    this.tempBenefit = {
      name: '',
      code: '',
      startDate: '',
      endDate: '',
      benefitDetails: '',
      couponId: [],
      isActive: false,
      isDeleted: false,
    };

    this.coupons = [];
  }

  clearCouponList() {
    this.coupons = [];
  }

  defaultBenefit(defaultBenefit) {
    if (defaultBenefit) {
      this.benefit = defaultBenefit;
    }

    return {
      name: '',
      code: '',
      startDate: '',
      endDate: '',
      benefitDetails: '',
      couponId: [],
      isActive: false,
      isDeleted: false,
    };
  }

  // API
  async getBenefitList() {
    try {
      this.isLoading = true;
      const response = await axios.get(`${config.api.sims}/v1/benefit/paginate`, {
        params: {
          ...this.query,
          ...this.pagination,
        },
      });
      if (response && response.data && response.data.data) {
        const data = this.convertKeysToCamelCase(response.data.data);

        this.benefits = data;
        this.pagination = response.data.pagination;
        return data;
      }
      return [];
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getBenefitById(id) {
    try {
      this.isLoading = true;
      const response = await axios.get(`${config.api.sims}/v1/benefit/${id}`);
      if (response && response.data && response.data.data) {
        const data = this.convertKeysToCamelCase(response.data.data);

        if (data.coupons && data.coupons.length) {
          this.coupons = data.coupons;
          delete data.coupons;
        }

        this.benefit = data;
        this.tempBenefit = data;
        return data;
      }
      return null;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async validateBenefit(code, name) {
    try {
      const response = await axios.post(`${config.api.sims}/v1/benefit/validate`, { code, name });
      if (response && response.data && response.data.data) {
        return response.data.data;
      }

      if (response && response.data && response.data.error) {
        return response.data.error;
      }

      return null;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return { error: error.response.data.error };
      } else {
        throw error;
      }
    }
  }

  async createBenefit(data) {
    try {
      this.isLoading = true;
      const convertPayload = this.convertKeysToSnakeCase(data);
      const response = await axios.post(`${config.api.sims}/v1/benefit`, convertPayload);
      if (response && response.data && response.data.data) {
        return response.data.data;
      }

      if (response && response.data && response.data.error) {
        return response.data.error;
      }

      return null;
    } catch (error) {
      // Check if the error response exists and contains data
      // if (error.response && error.response.data && error.response.data.error) {
      //   return { error: error.response.data.error }; // Return the error message if available
      // } else {
      //   return { message: error.message }; // Return a generic error message if no data available
      // }

        throw error;
      } finally {
        this.isLoading = false;
    }
  }

  async updateBenefit(id, data) {
    try {
      this.isLoading = true;
      const convertPayload = this.convertKeysToSnakeCase(data);
      const response = await axios.put(`${config.api.sims}/v1/benefit/${id}`, convertPayload);
      if (response && response.data && response.data.data) {
        return response.data.data;
      }
      return null;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateBenefitStatus(id, data) {
    try {
      const response = await axios.put(`${config.api.sims}/v1/benefit/${id}`, data);
      if (response && response.data && response.data.data) {
        const data = this.convertKeysToCamelCase(response.data.data);
        this.benefits = this.benefits.map((benefit) => {
          if (benefit._id === data._id) return data;
          return benefit;
        });
        return data;
      }
    } catch (error) {
      throw error;
    }
  }

  async deleteBenefit(id, user) {
    try {
      const response = await axios.delete(`${config.api.sims}/v1/benefit/${id}`, { data: { user } });
      if (response && response.data && response.data.deleted) {
        this.benefits = this.benefits.filter((benefit) => benefit._id !== id);
        return response.data.deleted;
      }
      return false;
    } catch (error) {
      console.log('error: ----> ', error);
      throw error;
    }
  }

  async getCouponList(search) {
    try {
      this.isLoading = true;
      const response = await axios.get(`${config.api.sims}/v1/benefit/coupon`, {
        params: {
          search,
        },
      });
      if (response && response.data && response.data.data) {
        const data = this.convertKeysToCamelCase(response.data.data);
        this.coupons = data;
        return data;
      }
      return [];
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new BenefitStore();
